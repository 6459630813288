import { RESIDENTIAL_PROPERTY_TYPE } from "constants/dbEnums";
import { BHK_TAGS } from "constants/dbEnums";
import { FACING_TAGS } from "constants/dbEnums";
import { AGE_OF_PROPERTY_TAGS } from "constants/dbEnums";
import { LEASE_YEARS_TAGS } from "constants/dbEnums";
import { BALCONIES_TAGS } from "constants/dbEnums";
import { DISCOUNT_TYPE } from "constants/dbEnums";
import { FLOORING_TAGS } from "constants/dbEnums";
import { MONTHS } from "constants/dbEnums";
import { NOTICE_PERIOD } from "constants/dbEnums";
import { AREA_UNIT_TAGS } from "constants/dbEnums";
import { PREFERENCES_TAGS } from "constants/dbEnums";
import { PROPERTY_COLLECTIONS } from "constants/dbEnums";
import { AMENITIES_TAGS } from "constants/dbEnums";
import { LOCK_IN_PERIOD_TAGS } from "constants/dbEnums";
import { INDOOR_AMENITIES_TAGS } from "constants/dbEnums";
import { POSSESSION_PERIOD_TAGS } from "constants/dbEnums";
import { ADDITIONAL_ROOMS_TAGS } from "constants/dbEnums";
import { OWNERSHIP } from "constants/dbEnums";
import { BATHROOMS_TAGS } from "constants/dbEnums";
import { SECURITY_DEPOSIT_TYPE_TAGS } from "constants/dbEnums";
import { CONSTRUCTION_STATUS_TAGS } from "constants/dbEnums";
import { TENANT_TYPE_TAGS } from "constants/dbEnums";
import { FURNISH_TYPE_TAGS } from "constants/dbEnums";
import { AD_TYPE_TAGS } from "constants/dbEnums";
import { USER_ROLE } from "constants/dbEnums";

const Joi = require("joi");

const residentialSchema = {
  create: Joi.object({
    owner_id: Joi.string().required(),
    type: Joi.string().required().valid(...Object.values(PROPERTY_COLLECTIONS)),
    owner_role: Joi.string().required().valid(...Object.values(USER_ROLE)),
    ad_type: Joi.string().valid(...Object.values(AD_TYPE_TAGS)).required(),
    property_type: Joi.string().valid(...Object.values(RESIDENTIAL_PROPERTY_TYPE)).required(),
    furnish_type: Joi.when("ad_type", {
      is: Joi.valid("sell"),
      then: Joi.when("property_type", {
        is: Joi.valid("others"),
        then: Joi.string(),
        otherwise: Joi.string()
          .valid(...Object.values(FURNISH_TYPE_TAGS))
          .required(),
      }),
      otherwise: Joi.string()
        .valid(...Object.values(FURNISH_TYPE_TAGS))
        .required(),
    }),
    bhk: Joi.when("property_type", {
      is: Joi.valid(RESIDENTIAL_PROPERTY_TYPE.ONE_RK_STUDIO, RESIDENTIAL_PROPERTY_TYPE.OTHERS),
      then: Joi.string(),
      otherwise: Joi.string()
        .valid(...Object.values(BHK_TAGS))
        .required(),
    }),
    description: Joi.string().required().trim().required(),
    total_floors: Joi.number().min(0).max(90).integer().required(),
    your_floor: Joi.when("property_type", {
      is: Joi.valid(RESIDENTIAL_PROPERTY_TYPE.FLAT_APARTMENT, RESIDENTIAL_PROPERTY_TYPE.FLOOR, RESIDENTIAL_PROPERTY_TYPE.ONE_RK_STUDIO, RESIDENTIAL_PROPERTY_TYPE.OTHERS),
      then: Joi.number().integer()
        .required()
        .max(Joi.ref("total_floors")).min(0)
        .message('"your_floor" must be less than or equal to "total_floors"'),
      otherwise: Joi.forbidden(),
    }),
    preferred_tenant: Joi.when("ad_type", {
      is: Joi.valid("rent"),
      then: Joi.array()
        .items(
          Joi.string()
            .valid(...Object.values(TENANT_TYPE_TAGS))
            .trim().required()
        )
        .required(),
      otherwise: Joi.forbidden(),
    }),
    facing: Joi.when("ad_type", {
      is: Joi.valid("sell"),
      then: Joi.when("property_type", {
        is: Joi.valid("others"),
        then: Joi.string(),
        otherwise: Joi.string()
          .valid(...Object.values(FACING_TAGS))
          .required(),
      }),
      otherwise: Joi.string()
        .valid(...Object.values(FACING_TAGS), ""),
    }),
    construction_status: Joi.when("ad_type", {
      is: Joi.valid("sell"),
      then: Joi.string()
        .valid(...Object.values(CONSTRUCTION_STATUS_TAGS))
        .required(),
      otherwise: Joi.string()
        .valid(...Object.values(CONSTRUCTION_STATUS_TAGS), ""),
    }),
    age_of_property: Joi.when("ad_type", {
      is: Joi.valid("sell"),
      then: Joi.when("construction_status", {
        is: Joi.valid("under_construction"),
        then: Joi.string(),
        otherwise: Joi.string()
          .valid(...Object.values(AGE_OF_PROPERTY_TAGS))
          .required(),
      }),
      otherwise: Joi.string()
        .valid(...Object.values(AGE_OF_PROPERTY_TAGS))
        .required(),
    }),
    security_deposit: Joi.when("is_on_lease", {
      is: true,
      then: Joi.forbidden(),
      otherwise: Joi.object({
        type: Joi.string().valid(...Object.values(SECURITY_DEPOSIT_TYPE_TAGS), ""),
        amount: Joi.when('type', {
          is: ['fixed', 'multiple_of_rents'],
          then: Joi.number().min(0).required(),
          otherwise: Joi.number().valid(0),
        })
      }),
    }),
    available_from: Joi.date().min((new Date().setUTCHours(0, 0, 0, 0))).required(),
    maintenance_charges: Joi.when("ad_type", {
      is: Joi.valid("rent"),
      then: Joi.number().optional(),
      otherwise: Joi.forbidden(),
    }),
    other_charges: Joi.number().optional(),
    is_on_lease: Joi.when("ad_type", {
      is: Joi.valid("rent"),
      then: Joi.boolean().required(),
      otherwise: Joi.forbidden(),
    }),
    lease_years: Joi.when("ad_type", {
      is: Joi.valid("rent"),
      then: Joi.when("is_on_lease", {
        is: true,
        then: Joi.string()
          .valid(...Object.values(LEASE_YEARS_TAGS))
          .required(),
        otherwise: Joi.string().valid(...Object.values(LEASE_YEARS_TAGS), ""),
      }),
      otherwise: Joi.forbidden(),
    }),
    bathrooms: Joi.string()
      .valid(...Object.values(BATHROOMS_TAGS), ""),
    balconies: Joi.string()
      .valid(...Object.values(BALCONIES_TAGS), ""),
    covered_parking: Joi.number().integer().max(10).min(0),
    open_parking: Joi.number().integer().max(10).min(0),
    booking_amount: Joi.number().integer(),
    ownership: Joi.when("ad_type", {
      is: "sell",
      then: Joi.when("property_type", {
        is: Joi.valid("others"),
        then: Joi.string().allow(""),
        otherwise: Joi.string()
          .valid(...Object.values(OWNERSHIP))
          .required(),
      }),
      otherwise: Joi.forbidden(),
    }),
    is_hot_deal: Joi.when("ad_type", {
      is: "sell",
      then: Joi.boolean(),
      otherwise: Joi.forbidden()
    }),
    discount: Joi.when("is_hot_deal", {
      is: Joi.valid(true).required(),
      then: Joi.object({
        type: Joi.string().valid(...Object.values(DISCOUNT_TYPE)).required(),
        amount: Joi.when("type", {
          is: Joi.valid("percentage"),
          then: Joi.number().min(1).max(90).required(),
          otherwise: Joi.number().min(1000).max(90000000).required()
        }),
      }).required(),
      otherwise: Joi.forbidden(),
    }),
    discount_validity: Joi.when("is_hot_deal", {
      is: Joi.valid(true).required(),
      then: Joi.date().required(),
      otherwise: Joi.forbidden(),
    }),
    financing_available: Joi.when("is_hot_deal", {
      is: Joi.valid(true).required(),
      then: Joi.boolean().required(),
      otherwise: Joi.forbidden(),
    }),
    additional_rooms: Joi.array()
      .items(
        Joi.string()
          .valid(...Object.values(ADDITIONAL_ROOMS_TAGS))
          .trim()
      ),
    flooring_type: Joi.string()
      .valid(...Object.values(FLOORING_TAGS), ""),
    allow_brokers: Joi.boolean(),
    possession_period: Joi.when("ad_type", {
      is: Joi.valid("sell"),
      then: Joi.when("construction_status", {
        is: "under_construction",
        then: Joi.object({
          period: Joi.string().valid(...Object.values(POSSESSION_PERIOD_TAGS)),
          month: Joi.string().valid(...Object.values(MONTHS), ""),
        }).required(),
        otherwise: Joi.forbidden(),
      }),
      otherwise: Joi.forbidden(),
    }),
    tax_and_govt_charges_excluded: Joi.when("ad_type", {
      is: Joi.valid(AD_TYPE_TAGS.SELL),
      then: Joi.boolean(),
      otherwise: Joi.forbidden()
    }),
    annual_dues_payable: Joi.when("ad_type", {
      is: Joi.valid("sell"),
      then: Joi.number().optional(),
      otherwise: Joi.forbidden(),
    }),
    price_per_sq_unit: Joi.number(),
    membership_charges: Joi.number(),
    furnish_type_items: Joi.when('furnish_type', {
      is: 'fully_furnished',
      then: Joi.object().pattern(Joi.string().valid(...Object.values(INDOOR_AMENITIES_TAGS)), Joi.number().min(1)).min(5).required(),
      otherwise: Joi.when('furnish_type', {
        is: 'semi_furnished',
        then: Joi.object().pattern(Joi.string().valid(...Object.values(INDOOR_AMENITIES_TAGS)), Joi.number().min(1)).min(3).required(),
        otherwise: Joi.forbidden(),
      }),
    }),
    is_electricity_and_water_charge: Joi.when("ad_type", {
      is: Joi.valid(AD_TYPE_TAGS.RENT),
      then: Joi.boolean(),
      otherwise: Joi.forbidden()
    }),
    notice_period: Joi.when("is_on_lease", {
      is: true,
      then: Joi.forbidden(),
      otherwise: Joi.number().valid(...Object.values(NOTICE_PERIOD), 0),
    }),
    lock_in_period: Joi.when("is_on_lease", {
      is: true,
      then: Joi.forbidden(),
      otherwise: Joi.object({
        type: Joi.string().valid(...Object.values(LOCK_IN_PERIOD_TAGS), ""),
        period: Joi.when('type', {
          is: 'custom',
          then: Joi.number().integer().max(30).min(0).required(),
          otherwise: Joi.number().valid(0),
        })
      }),
    }),
    photos: Joi.array().items(Joi.string().trim()),
    area: Joi.object({
      size: Joi.number().min(1).max(9999999).required(),
      unit: Joi.string()
        .required()
        .valid(...Object.values(AREA_UNIT_TAGS))
        .default(""),
    }).required(),
    carpet_area: Joi.object({
      size: Joi.number(),
      unit: Joi.string()
        .valid(...Object.values(AREA_UNIT_TAGS)),
    }),
    approx_price: Joi.number().min(1000).max(90000000).required(),
    negotiable: Joi.boolean(),
    amenities: Joi.array().items(
      Joi.string()
        .valid(...Object.values(AMENITIES_TAGS))
        .trim()
    ),
    preferences: Joi.array().items(
      Joi.string()
        .valid(...Object.values(PREFERENCES_TAGS))
        .trim()
    ),
    location: Joi.object({
      type: Joi.string(),
      label: Joi.string(),
      place_type: Joi.string(),
      coordinates: Joi.array().items(Joi.number()).required(),
      city: Joi.string().required().trim(),
      state: Joi.string(),
      state_code: Joi.string(),
      country_code: Joi.string(),
      country: Joi.string(),
      locality: Joi.string().required(),
      sub_locality: Joi.string(),
      street: Joi.string().allow("").trim(),
      building: Joi.string(),
      postal_code: Joi.string(),
    }).required()
    .custom((value, helpers) => {
      if (!value.locality || value.locality.trim() === "") {
        return helpers.error("any.custom", { key: "locality" });
      }
      return value;
    }),
  }).unknown(),
  update: Joi.object({
    owner_id: Joi.string().required(),
    type: Joi.string().required().valid(...Object.values(PROPERTY_COLLECTIONS)),
    owner_role: Joi.string().required().valid(...Object.values(USER_ROLE)),
    ad_type: Joi.string().valid(...Object.values(AD_TYPE_TAGS)).required(),
    property_type: Joi.string().valid(...Object.values(RESIDENTIAL_PROPERTY_TYPE)).required(),
    furnish_type: Joi.when("ad_type", {
      is: Joi.valid("sell"),
      then: Joi.when("property_type", {
        is: Joi.valid("others"),
        then: Joi.string(),
        otherwise: Joi.string()
          .valid(...Object.values(FURNISH_TYPE_TAGS))
          .required(),
      }),
      otherwise: Joi.string()
        .valid(...Object.values(FURNISH_TYPE_TAGS))
        .required(),
    }),
    bhk: Joi.when("property_type", {
      is: Joi.valid(RESIDENTIAL_PROPERTY_TYPE.ONE_RK_STUDIO, RESIDENTIAL_PROPERTY_TYPE.OTHERS),
      then: Joi.string(),
      otherwise: Joi.string()
        .valid(...Object.values(BHK_TAGS))
        .required(),
    }),
    description: Joi.string().required().trim().required(),
    total_floors: Joi.number().min(0).max(90).integer().required(),
    your_floor: Joi.when("property_type", {
      is: Joi.valid(RESIDENTIAL_PROPERTY_TYPE.FLAT_APARTMENT, RESIDENTIAL_PROPERTY_TYPE.FLOOR, RESIDENTIAL_PROPERTY_TYPE.ONE_RK_STUDIO, RESIDENTIAL_PROPERTY_TYPE.OTHERS),
      then: Joi.number().integer()
        .required()
        .max(Joi.ref("total_floors")).min(0)
        .message('"your_floor" must be less than or equal to "total_floors"'),
      otherwise: Joi.forbidden(),
    }),
    preferred_tenant: Joi.when("ad_type", {
      is: Joi.valid("rent"),
      then: Joi.array()
        .items(
          Joi.string()
            .valid(...Object.values(TENANT_TYPE_TAGS))
            .trim().required()
        )
        .required(),
      otherwise: Joi.forbidden(),
    }),
    facing: Joi.when("ad_type", {
      is: Joi.valid("sell"),
      then: Joi.when("property_type", {
        is: Joi.valid("others"),
        then: Joi.string(),
        otherwise: Joi.string()
          .valid(...Object.values(FACING_TAGS))
          .required(),
      }),
      otherwise: Joi.string()
        .valid(...Object.values(FACING_TAGS), ""),
    }),
    construction_status: Joi.when("ad_type", {
      is: Joi.valid("sell"),
      then: Joi.string()
        .valid(...Object.values(CONSTRUCTION_STATUS_TAGS))
        .required(),
      otherwise: Joi.string()
        .valid(...Object.values(CONSTRUCTION_STATUS_TAGS), ""),
    }),
    age_of_property: Joi.when("ad_type", {
      is: Joi.valid("sell"),
      then: Joi.when("construction_status", {
        is: Joi.valid("under_construction"),
        then: Joi.string(),
        otherwise: Joi.string()
          .valid(...Object.values(AGE_OF_PROPERTY_TAGS))
          .required(),
      }),
      otherwise: Joi.string()
        .valid(...Object.values(AGE_OF_PROPERTY_TAGS))
        .required(),
    }),
    security_deposit: Joi.when("is_on_lease", {
      is: true,
      then: Joi.forbidden(),
      otherwise: Joi.object({
        type: Joi.string().valid(...Object.values(SECURITY_DEPOSIT_TYPE_TAGS), ""),
        amount: Joi.when('type', {
          is: ['fixed', 'multiple_of_rents'],
          then: Joi.number().min(0).required(),
          otherwise: Joi.number().valid(0),
        })
      }),
    }),
    available_from: Joi.date().required(),
    maintenance_charges: Joi.when("ad_type", {
      is: Joi.valid("rent"),
      then: Joi.number().optional(),
      otherwise: Joi.forbidden(),
    }),
    other_charges: Joi.number().optional(),
    is_on_lease: Joi.when("ad_type", {
      is: Joi.valid("rent"),
      then: Joi.boolean().required(),
      otherwise: Joi.forbidden(),
    }),
    lease_years: Joi.when("ad_type", {
      is: Joi.valid("rent"),
      then: Joi.when("is_on_lease", {
        is: true,
        then: Joi.string()
          .valid(...Object.values(LEASE_YEARS_TAGS))
          .required(),
        otherwise: Joi.string().valid(...Object.values(LEASE_YEARS_TAGS), ""),
      }),
      otherwise: Joi.forbidden(),
    }),
    bathrooms: Joi.string()
      .valid(...Object.values(BATHROOMS_TAGS), ""),
    balconies: Joi.string()
      .valid(...Object.values(BALCONIES_TAGS), ""),
    covered_parking: Joi.number().integer().max(10).min(0),
    open_parking: Joi.number().integer().max(10).min(0),
    booking_amount: Joi.number().integer(),
    ownership: Joi.when("ad_type", {
      is: "sell",
      then: Joi.when("property_type", {
        is: Joi.valid("others"),
        then: Joi.string().allow(""),
        otherwise: Joi.string()
          .valid(...Object.values(OWNERSHIP))
          .required(),
      }),
      otherwise: Joi.forbidden(),
    }),
    is_hot_deal: Joi.when("ad_type", {
      is: "sell",
      then: Joi.boolean(),
      otherwise: Joi.forbidden()
    }),
    discount: Joi.when("is_hot_deal", {
      is: Joi.valid(true).required(),
      then: Joi.object({
        type: Joi.string().valid(...Object.values(DISCOUNT_TYPE)).required(),
        amount: Joi.when("type", {
          is: Joi.valid("percentage"),
          then: Joi.number().min(1).max(90).required(),
          otherwise: Joi.number().min(1000).max(90000000).required()
        }),
      }).required(),
      otherwise: Joi.forbidden(),
    }),
    discount_validity: Joi.when("is_hot_deal", {
      is: Joi.valid(true).required(),
      then: Joi.date().required(),
      otherwise: Joi.forbidden(),
    }),
    financing_available: Joi.when("is_hot_deal", {
      is: Joi.valid(true).required(),
      then: Joi.boolean().required(),
      otherwise: Joi.forbidden(),
    }),
    additional_rooms: Joi.array()
      .items(
        Joi.string()
          .valid(...Object.values(ADDITIONAL_ROOMS_TAGS))
          .trim()
      ),
    flooring_type: Joi.string()
      .valid(...Object.values(FLOORING_TAGS), ""),
    allow_brokers: Joi.boolean(),
    possession_period: Joi.when("ad_type", {
      is: Joi.valid("sell"),
      then: Joi.when("construction_status", {
        is: "under_construction",
        then: Joi.object({
          period: Joi.string().valid(...Object.values(POSSESSION_PERIOD_TAGS)),
          month: Joi.string().valid(...Object.values(MONTHS), ""),
        }).required(),
        otherwise: Joi.forbidden(),
      }),
      otherwise: Joi.forbidden(),
    }),
    tax_and_govt_charges_excluded: Joi.when("ad_type", {
      is: Joi.valid(AD_TYPE_TAGS.SELL),
      then: Joi.boolean(),
      otherwise: Joi.forbidden()
    }),
    annual_dues_payable: Joi.when("ad_type", {
      is: Joi.valid("sell"),
      then: Joi.number().optional(),
      otherwise: Joi.forbidden(),
    }),
    price_per_sq_unit: Joi.number(),
    membership_charges: Joi.number(),
    furnish_type_items: Joi.when('furnish_type', {
      is: 'fully_furnished',
      then: Joi.object().pattern(Joi.string().valid(...Object.values(INDOOR_AMENITIES_TAGS)), Joi.number().min(1)).min(5).required(),
      otherwise: Joi.when('furnish_type', {
        is: 'semi_furnished',
        then: Joi.object().pattern(Joi.string().valid(...Object.values(INDOOR_AMENITIES_TAGS)), Joi.number().min(1)).min(3).required(),
        otherwise: Joi.forbidden(),
      }),
    }),
    is_electricity_and_water_charge: Joi.when("ad_type", {
      is: Joi.valid(AD_TYPE_TAGS.RENT),
      then: Joi.boolean(),
      otherwise: Joi.forbidden()
    }),
    notice_period: Joi.when("is_on_lease", {
      is: true,
      then: Joi.forbidden(),
      otherwise: Joi.number().valid(...Object.values(NOTICE_PERIOD), 0),
    }),
    lock_in_period: Joi.when("is_on_lease", {
      is: true,
      then: Joi.forbidden(),
      otherwise: Joi.object({
        type: Joi.string().valid(...Object.values(LOCK_IN_PERIOD_TAGS), ""),
        period: Joi.when('type', {
          is: 'custom',
          then: Joi.number().integer().max(30).min(0).required(),
          otherwise: Joi.number().valid(0),
        })
      }),
    }),
    photos: Joi.array().items(Joi.string().trim()),
    area: Joi.object({
      size: Joi.number().min(1).max(9999999).required(),
      unit: Joi.string()
        .required()
        .valid(...Object.values(AREA_UNIT_TAGS))
        .default(""),
    }).required(),
    carpet_area: Joi.object({
      size: Joi.number(),
      unit: Joi.string()
        .valid(...Object.values(AREA_UNIT_TAGS)),
    }),
    approx_price: Joi.number().min(1000).max(90000000).required(),
    negotiable: Joi.boolean(),
    amenities: Joi.array().items(
      Joi.string()
        .valid(...Object.values(AMENITIES_TAGS))
        .trim()
    ),
    preferences: Joi.array().items(
      Joi.string()
        .valid(...Object.values(PREFERENCES_TAGS))
        .trim()
    ),
    location: Joi.object({
      type: Joi.string(),
      label: Joi.string(),
      place_type: Joi.string(),
      coordinates: Joi.array().items(Joi.number()).required(),
      city: Joi.string().required().trim(),
      state: Joi.string(),
      state_code: Joi.string(),
      country_code: Joi.string(),
      country: Joi.string(),
      locality: Joi.string().required(),
      sub_locality: Joi.string(),
      street: Joi.string().allow("").trim(),
      building: Joi.string(),
      postal_code: Joi.string(),
    }).required()
    .custom((value, helpers) => {
      if (!value.locality || value.locality.trim() === "") {
        return helpers.error("any.custom", { key: "locality" });
      }
      return value;
    }),
  }).unknown()
}

export default residentialSchema;

export const residentialKeysArrya = ["ad_type", "construction_status", "age_of_property", "possession_period", "flooring_type", "ownership", "is_on_lease", "is_hot_deal", "available_from", "allow_brokers", "lock_in_period", "lease_years", "maintenance_charges", "annual_dues_payable", "discount_validity", "financing_available", "total_floors", "your_floor"];

export const residentialKeys = {
  ad_type: "ad_type_and_construction_status",
  construction_status: "ad_type_and_construction_status",
  age_of_property: "age_of_property_and_possession_period",
  possession_period: "age_of_property_and_possession_period",
  flooring_type: "flooring_type_and_ownership",
  ownership: "flooring_type_and_ownership",
  is_on_lease: "is_on_lease_and_is_hot_deal",
  is_hot_deal: "is_on_lease_and_is_hot_deal",
  available_from: "available_from_and_allow_brokers",
  allow_brokers: "available_from_and_allow_brokers",
  lease_years: "lock_in_period_and_lease_years",
  lock_in_period: "lock_in_period_and_lease_years",
  maintenance_charges: "maintenance_charges_and_annual_dues_payable",
  annual_dues_payable: "maintenance_charges_and_annual_dues_payable",
  discount_validity: "discount_validity_and_financing_available",
  financing_available: "discount_validity_and_financing_available",
  total_floors: "total_floors_and_your_floor",
  your_floor: "total_floors_and_your_floor"
};

import React from "react";
import { Box, SimpleGrid } from "@chakra-ui/react";
import fieldConfig from "./FieldConfig.js";

const CommonForm = ({ formType, formData, onChange, errors }) => {
  const fields = fieldConfig[formType] || {};

  return (
    <SimpleGrid columns={{ base: 1, md: 2 }} spacing={4}>
      {Object.entries(fields).map(([name, renderField]) => (
        <Box key={name}>
          {renderField({
            name,
            value: formData[name] || "",
            onChange: (name, value) => onChange(name, value),
            formData: formData,
            error: errors[name]
          })}
        </Box>
      ))}
    </SimpleGrid>
  );
};

export default CommonForm;

import React from 'react';
import { Flex, Box, Text, Icon } from '@chakra-ui/react';
import { WarningIcon } from '@chakra-ui/icons';

const ErrorDisplay = ({ error }) => {  
  return (
    <Flex height="100vh" alignItems="center" justifyContent="center" bg="gray.200">
      <Box 
        textAlign="center" 
        paddingY={4} 
        paddingX={8}
        maxW="md" 
        borderWidth={1} 
        borderRadius="lg" 
        boxShadow="lg" 
        bg="white"
      >
        <Icon as={WarningIcon} w={10} h={10} color="red.500" mb={1} />
        <Text fontSize="2xl" fontWeight="bold" mb={2} color="red.500">
          Oops!
        </Text>
        <Text fontSize="lg" mb={4} color="gray.700">
          Something went wrong
        </Text>
        <Text fontSize="md" color="gray.600">
          {error.response?.data?.message}
        </Text>
      </Box>
    </Flex>
  );
};

export default ErrorDisplay;

import { StarIcon } from "@chakra-ui/icons";
import { Flex, Link, Text } from "@chakra-ui/react";
import { formatTime } from "constants/commonFunctions";
import { formatDate } from "constants/commonFunctions";
import { formatSearchResult } from "constants/commonFunctions";
import { USER_ROLES } from "constants/dbEnums";
import { truncateString } from "utils/common";
import { roleBadgeColor } from "utils/common";
import { generateSlugUrlForUserActivity } from "utils/generateSlugUrl";

const crateLink = (filterObject) => {
    if (!filterObject) return;
    const parsedFilter = JSON.parse(filterObject);
    let { location, ad_type, type, property_type } = parsedFilter || {};
    if (ad_type) ad_type = ad_type[0];
    const { locality, city } = location[0];
    return generateSlugUrlForUserActivity(
        type,
        property_type,
        ad_type,
        locality,
        city
    );
}

const SearchesColumns = [
    {
        label: "Date",
        accessor: "created_at",
        render: (row) =>
            `${formatDate(row?.created_at)}, ${formatTime(row?.created_at)}`,
    },
    {
        label: "User",
        render: (data, onMakeImportant) => (
            <Flex justifyContent={"space-between"} width={"170px"}>
                <Link href={`/#/admin/users/?search_text=${data?.user_data?.phone}`}>
                    {data?.user_data?.phone ? truncateString(`${data?.user_data?.first_name} ${data?.user_data?.last_name}`) : data?.user_session_id}
                </Link>
                <Flex alignItems={"center"}>
                    {
                        data?.user_data?.role && <Text fontWeight={"medium"} color={`${roleBadgeColor(data?.user_data?.role)}`} py={1}>{USER_ROLES[data?.user_data?.role]}</Text>
                    }
                    {
                        data?.user_data?.phone && <StarIcon onClick={() => onMakeImportant(data?.user_data?.important, data?.user_data?._id)} w={4} h={4} cursor={"pointer"} color={data?.user_data?.important ? "orange" : "gray"} marginLeft={1} />
                    }
                </Flex>
            </Flex>
        )
    },
    {
        label: "Phone", render: (data) => (
            <Link href={`/#/admin/users/?search_text=${data?.user_data?.phone}`}>
                {data?.user_data?.phone || data?.user_session_id}
            </Link>
        )
    },
    {
        label: "Searched Property",
        accessor: "filter_object",
        render: (row) => (
            <Link target="_blank" href={`${crateLink(row?.filter_object)}`}>
                {`${formatSearchResult(row?.filter_object)}`}
            </Link>
        ),
    }
];

export default SearchesColumns;
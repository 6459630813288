import React from 'react';
import ReactDOM from 'react-dom';
import 'assets/css/App.css';
import { HashRouter, Switch, Redirect } from 'react-router-dom';
import AuthLayout from 'layouts/auth';
import AdminLayout from 'layouts/admin';
import RtlLayout from 'layouts/rtl';
import { ChakraProvider } from '@chakra-ui/react';
import theme from 'theme/theme';
import { ThemeEditorProvider } from '@hypertheme-editor/chakra-ui';
import PrivateRoute from 'routes/PrivateRoute';
import { AuthProvider } from 'contexts/AuthContext';
import AuthRoute from 'routes/AuthRoute';

import { Toaster } from 'react-hot-toast';
import { ModalProvider } from 'contexts/ModalContext';

ReactDOM.render(
  <ChakraProvider theme={theme}>
    <React.StrictMode>
      <ThemeEditorProvider>
        <HashRouter>
          <AuthProvider>
            <ModalProvider>
              <Switch>
                <AuthRoute path="/auth" component={AuthLayout} />
                <PrivateRoute path="/admin" component={AdminLayout} />
                <PrivateRoute path="/rtl" component={RtlLayout} />
                <Redirect from="/" to="/admin/default" />
              </Switch>
            </ModalProvider>
          </AuthProvider>
          <Toaster />
        </HashRouter>
      </ThemeEditorProvider>
    </React.StrictMode>
  </ChakraProvider>,
  document.getElementById('root')
);

import React from "react";
import {
  Box,
  Checkbox,
  CheckboxGroup,
  FormControl,
  FormLabel,
  Stack,
} from "@chakra-ui/react";
import ErrorMessage from "./ErrorMessage";

const CheckboxComponent = ({ options, name, selectedValues, onChange, label, checkboxSize = "md", textSize = "md", isRequired, error, disabled = false }) => {
  
  const safeSelectedValues = Array.isArray(selectedValues) ? selectedValues : [selectedValues];
  const handleChange = (e) => {
    onChange(e);
  };
  
  return (
    <FormControl>
        <FormLabel htmlFor={name}>{label}{isRequired && <Box as="span" color="red.500" ml={1}>*</Box>}</FormLabel>
        <CheckboxGroup value={safeSelectedValues}>
          <Stack direction="row">
            {options?.map((option) => (
              <Checkbox key={option.value} value={option.value} size={checkboxSize} textSize={textSize} onChange={handleChange} name={name} isDisabled={disabled} >
                {option.label}
              </Checkbox>
            ))}
          </Stack>
        </CheckboxGroup>
        <ErrorMessage error={error}/>
    </FormControl>
  );
};

export default CheckboxComponent;

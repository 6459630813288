export const adType = {
  Sale: "sell",
  Rent: "rent",
};

export const transactionType = {
  Residential: "residential_property",
  Commercial: "commercial_property",
  Plot: "plot",
  PG: "pg",
  Share_Flat: "share_flat",
};

export const COLLECTION_TYPE = {
  Builder: "builders",
  Company: "company_account",
  Agent: "agents"
}

export const AGENT_TYPES = {
  COMPANY_AGENT: "Agent with Company",  
  INDIVIDUAL_AGENT: "Independent Agent" 
};

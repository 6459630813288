import { Avatar, Box, Button, Flex, Grid, Text } from '@chakra-ui/react';
import ErrorDisplay from 'components/errorDisplay/ErrorDisplay';
import { AD_LEAD_API } from 'constants/endpoints';
import useApi from 'hooks/useApi';
import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { formatDateToMonth } from 'utils/common';
import ListingScoreCard from './ListingScoreCard';
import { LEAD_ACTION } from 'utils/common';

import { OverlayLoaderComponent } from 'components/loader/Loader';
import { FILTER_LEAD_ACTION } from 'utils/common';
import {useLocation} from 'react-router-dom'
import { PROPERTIES_LISTING_API } from 'constants/endpoints';
import { Select } from '@chakra-ui/react'

const SELECTED_TYPE = {
  All: 'ALL',
  Inquiries: 'INQUIRIES',
  Visitors: 'VISITORS',
};

const PropertyStat = () => {
  const { search } = useLocation();

  const queryObject = Object.fromEntries(new URLSearchParams(search));

  const { apiCall, loading = true, error } = useApi();
  const [adStatDetail, setAdStatDetail] = useState();
  const [propertiesData, setPropertiesData] = useState([]);
  const { userId } = useParams();
  const [selectedSlug, setSelectedSlug] = useState(
    queryObject?.adId || propertiesData[0]?.slug_url
  );

  const [selectedType, setSelectedType] = useState(SELECTED_TYPE.All);

  const handleChange = (e) => {
    setSelectedSlug(e.target.value);
  };



  console.log('selectedSlug', selectedSlug)

  useEffect(() => {
    const fetchPropertiesData = async () => {
      const body = {
        search_text: userId,
      };

      try {
        const res = await apiCall.get(`${PROPERTIES_LISTING_API}`, body);

        setPropertiesData(res?.data?.data);

        if (queryObject?.adId) {
          const ad = res?.data?.data.find((p) => p._id === queryObject?.adId);
          if (ad) {
            setSelectedSlug(ad?.slug_url);
          } else {
            setSelectedSlug(res?.data?.data[0]?.slug_url);
          }
        } else {
          setSelectedSlug(res?.data?.data[0]?.slug_url);
        }
      } catch (error) {
        console.log("Error while fetching properties: ", error.message);
      }
    };

    fetchPropertiesData();
  }, [userId, queryObject?.adId]);



 

  useEffect(() => {
    const adId = propertiesData?.find((p) => p.slug_url === selectedSlug)?._id;




    if (adId) {
      const fetchStat = async () => {
        try {
          const res = await apiCall.post(`${AD_LEAD_API}`, {
            adIds: [adId],
          });
          setAdStatDetail(res?.data);
        } catch (error) {
          console.log("Error while fetching properties: ", error);
        }
      };

      fetchStat();
    }
  }, [selectedSlug, propertiesData]);

  const filteredStatDetail = useMemo(() => {
    if (selectedType === SELECTED_TYPE.All) {
      return adStatDetail ? adStatDetail?.leads : [];
    } else if (selectedType === SELECTED_TYPE.Visitors) {
      return adStatDetail?.leads?.filter(
        (ad) =>
          ad.lead_action === FILTER_LEAD_ACTION.bookmark ||
          ad.lead_action === FILTER_LEAD_ACTION.page_view
      );
    } else if (selectedType === SELECTED_TYPE.Inquiries) {
      return adStatDetail?.leads?.filter(
        (ad) =>
          ad.lead_action === FILTER_LEAD_ACTION.view_number ||
          ad.lead_action === FILTER_LEAD_ACTION.call ||
          ad.lead_action === FILTER_LEAD_ACTION.requested_callback ||
          ad.lead_action === FILTER_LEAD_ACTION.requested_photo
      );
    }
    return [];
  }, [selectedType, adStatDetail]);

  if (error) {
    return <ErrorDisplay error={error} />;
  }

    const getTotalListingScore=(data)=>{
      if(!data)return 0;
     return  Object.keys(data).length !== 0 ? Object.values( data).reduce((acc, value) => acc + value, 0): 0
    }

  return (
    <Box my={2}>
      <OverlayLoaderComponent isOpen={loading} />

      <Flex mb={2} justifyContent="center">
        <Button
          colorScheme={selectedType === SELECTED_TYPE.All ? 'teal' : 'gray'}
          variant={selectedType === SELECTED_TYPE.All ? 'solid' : 'outline'}
          rounded="md"
          size="sm"
          mr={1}
          onClick={() => setSelectedType(SELECTED_TYPE.All)}
        >
          All Leads({adStatDetail?.count?.all})
        </Button>
        <Button
          colorScheme={
            selectedType === SELECTED_TYPE.Inquiries ? 'teal' : 'gray'
          }
          variant={
            selectedType === SELECTED_TYPE.Inquiries ? 'solid' : 'outline'
          }
          rounded="md"
          size="sm"
          mr={1}
          onClick={() => setSelectedType(SELECTED_TYPE.Inquiries)}
        >
          Inquiries({adStatDetail?.count?.inquiries})
        </Button>
        <Button
          colorScheme={
            selectedType === SELECTED_TYPE.Visitors ? 'teal' : 'gray'
          }
          variant={
            selectedType === SELECTED_TYPE.Visitors ? 'solid' : 'outline'
          }
          rounded="md"
          size="sm"
          onClick={() => setSelectedType(SELECTED_TYPE.Visitors)}
        >
          Visitors({adStatDetail?.count?.visitors})
        </Button>
      </Flex>

   
      <Box>
        <Text>Phone: {propertiesData[0]?.owner?.phone}</Text>
        <Text>Email: {propertiesData[0]?.owner?.email}</Text>
      </Box>
      

      <Grid templateColumns="1fr 3fr" gap={4}>
        <Box>

          
          <Select onChange={handleChange} value={selectedSlug}>
            {propertiesData?.map((property) => {
         
             return  <option key={property._id}>{property?.slug_url}</option>
            }
            )}
          </Select>


<div>



  </div>

          <ListingScoreCard
            score={getTotalListingScore(
              adStatDetail?.leads[0]?.propertyData?.listing_score
            )}
          />
        </Box>
        <Box>
          {filteredStatDetail?.map((item, index) => {
            return (
              <Box
                key={index}
                p={2}
                borderWidth="1px"
                borderRadius="md"
                mb={2}
                shadow="sm"
              >
                <Flex alignItems="center" mb={2}>
                  <Avatar
                    src={`https://img-dev.hexahome.in/${item?.profile_pic}`}
                    alt={item?.name}
                    mr={2}
                    size="md"
                  />
                  <Box>
                    <Text fontWeight="bold" fontSize="md">
                      {item?.name}
                    </Text>
                    <Text fontSize="xs" color="gray.500">
                      {formatDateToMonth(item?.created_at)}
                    </Text>
                  </Box>
                </Flex>
                <Box mb={2}>
                  <Text fontSize="sm">
                    <span className="text-dark-300 text-sm font-normal">
                      Has {LEAD_ACTION[item?.lead_action]} your
                    </span>{' '}
                    <span
                      className="font-bold text-sm text-dark-400"
                      style={{ fontWeight: 'bold' }}
                    >
                      {item?.propertyData?.pg_name}
                    </span>{' '}
                    <span className="font-semibold text-sm text-dark-300">
                      at {item?.propertyData?.location?.locality},{' '}
                      {item?.propertyData?.location?.city} (Property ID:{' '}
                      {item?.propertyData?.sid}).
                    </span>
                  </Text>
                </Box>
              </Box>
            );
          })}
        </Box>
      </Grid>
    </Box>
  );
};

export default PropertyStat;

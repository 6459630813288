import {
  Box,
  FormControl,
  Input,
  Text,
  Icon,
} from "@chakra-ui/react";
import { PlusSquareIcon } from "@chakra-ui/icons";
import { showToast } from "components/toast/Toast";

const MultipleImageUpload = ({
  selectedImages,
  setSelectedImages,
  multiple = true,
  onChange,
  name,
  accept = "image/*",
  maxLenght = 10
}) => {
  const handleImageChange = (event) => {
    if (event.target.files) {
      const newImages = Array.from(event.target.files);
      if (selectedImages.length + newImages.length <= maxLenght) {
        setSelectedImages([...selectedImages, ...newImages]);
        onChange({
          target: {
            name,
            value: [...selectedImages, ...newImages]
          }
        });
      } else {
        showToast({
          message: `You can only upload up to ${maxLenght} images.`,
          success: false,
          customStyle: {
            background: "#F24052",
            color: "#fff",
            padding: "10px",
          },
        });
      }
    }
  };

  return (
    <FormControl>
      <Box
        as="label"
        border="1px dashed"
        borderColor="gray.300"
        borderRadius="md"
        padding={2}
        cursor="pointer"
        display="flex"
        alignItems="center"
        justifyContent="center"
        position="relative"
        _hover={{ borderColor: "gray.400" }}
      >
        <Input
          type="file"
          accept={accept}
          multiple={multiple}
          onChange={handleImageChange}
          position="absolute"
          top="0"
          left="0"
          opacity="0"
          cursor="pointer"
        />
        <Icon as={PlusSquareIcon} w={8} h={5} color="gray.400" />
        <Text ml={2} color="gray.500">
          Supports JPG, JPEG & PNG
        </Text>
      </Box>
    </FormControl>
  );
};

export default MultipleImageUpload;

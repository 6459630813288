import React, { useEffect, useState } from "react";
import {
  Box,
  FormControl,
  FormLabel,
  Select,
} from "@chakra-ui/react";
import ErrorMessage from "./ErrorMessage";

const SelectInput = ({
  name,
  onChange,
  label,
  options,
  isRequired,
  selectedValue = "",
  error,
  disabled
}) => {

  const handleChange = (e) => {
    const { value } = e.target;
    if (Array?.isArray(options)) {
      const selectedOption = options?.find((option) => option.id === value);
      const customEvent = {
        target:{
          name,
          value: selectedOption
        }
      }
      onChange(customEvent);
    } else {
      onChange(e);
    }
  };

  return (
    <FormControl>
      <FormLabel htmlFor={name}>
        {label}
        {isRequired && (
          <Box as="span" color="red.500" ml={1}>
            *
          </Box>
        )}
      </FormLabel>
      <Select
        id={name}
        name={name}
        onChange={handleChange}
        value={selectedValue}
        placeholder="Select an option"
        disabled={disabled}
      >
        {Array.isArray(options)
          ? options.map((option, index) => (
              <option key={index} value={option?.id}>
                {option.name}
              </option>
            ))
          : Object.entries(options).map(([key, value]) => (
              <option key={key} value={key}>
                {value}
              </option>
            ))}
      </Select>
      <ErrorMessage error={error} />
    </FormControl>
  );
};

export default SelectInput;

import { PROPERTY_COLLECTIONS, PROPERTY_TABLE_TAG, TRANSACTION_TYPE } from "./dbEnums";

const formatDate = (dateString) => {
  const date = new Date(dateString);
  const year = String(date.getUTCFullYear()).slice(-2); // Get last 2 digits of the year
  const monthNames = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  const month = monthNames[date.getUTCMonth()]; // Get month abbreviation
  const day = String(date.getUTCDate()).padStart(2, '0'); // Ensure 2 digits

  return `${day}-${month}-${year}`;
};

export function formatTime(createdAt) {
  const istOffset = 5.5 * 60 * 60 * 1000; // IST offset in milliseconds
  const istDate = new Date(new Date(createdAt).getTime() + istOffset);

  let hours = istDate.getUTCHours();
  const minutes = istDate.getUTCMinutes();
  const ampm = hours >= 12 ? 'PM' : 'AM';

  hours = hours % 12 || 12; // Convert to 12-hour format
  const minutesStr = minutes.toString().padStart(2, '0'); // Pad minutes with leading zero if needed

  return `${hours}:${minutesStr} ${ampm}`;
}

const formatLocation = (address) => {
  let locationParts = [];

  if (address.sub_locality) {
      locationParts.push(address.sub_locality);
  }
  if (address.locality) {
      locationParts.push(address.locality);
  }
  if (address.city) {
      locationParts.push(address.city);
  }

  return locationParts.join(', ');
};

const formatPropertyTypes = (propertyTypes) => {
  let propertyType = [];
  propertyTypes?.forEach((property) => propertyType.push(property?.property_type || property))
  return propertyType.join(",")
}

const formatSearchResult = (filterObject) => {
  const parsedObj = JSON.parse(filterObject);
  const { type, location, property_type, ad_type } = parsedObj || {};
  const formatedLocation = location ? formatLocation(location[0]) : "-";
  if (type === PROPERTY_COLLECTIONS.PG) {
    return `${type} in ${formatedLocation}`
  } else if (type === PROPERTY_COLLECTIONS.PLOT) {
    return `${property_type ? property_type[0] : ''} ${type} in ${formatedLocation}`
  } else if (type === PROPERTY_COLLECTIONS.SHARE_FLAT) {
    return `${TRANSACTION_TYPE[type]} in ${formatedLocation}`
  } else if (type === PROPERTY_COLLECTIONS.RESIDENTIAL_PROPERTY || type === PROPERTY_COLLECTIONS.COMMERCIAL_PROPERTY) {
    return `${PROPERTY_TABLE_TAG[formatPropertyTypes(property_type)] || `${TRANSACTION_TYPE[type]} Property`} for ${ad_type[0]} in ${formatedLocation}`
  }
  return type;
}

export { formatDate, formatLocation, formatSearchResult };

import agentSchema from "./agent.validation";
import builderSchema from "./builder.validation";
import companySchema, { companyKeys, companyKeysArrya } from "./company.validation";

const formValidation = {
    company: {
        create: companySchema.create,
        update: companySchema.update
    },
    builder: {
        create: builderSchema.create,
        update: builderSchema.update
    },
    agent: {
        create: agentSchema.create,
        update: agentSchema.update
    }
};

export default formValidation;

export const customFormkeysArray = {
    company: companyKeysArrya
};

export const customFormKeys = {
    company: companyKeys
};
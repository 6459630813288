import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  HStack,
  Heading,
  Spinner
} from '@chakra-ui/react';
import CommonForms from 'components/addForm/CommonForm';
import { ALL_PROPERTY_TYPE } from 'constants/dbEnums';
import SelectInput from 'components/addForm/SelectInput';
import { initialState } from 'components/addForm/formInitialState';
import useApi from 'hooks/useApi';
import { ArrowBackIcon } from '@chakra-ui/icons';
import { useHistory } from 'react-router-dom';
import { createForm, getFormData, removeKeyValueFromPayload } from './apiService';
import { filterEmptyValues, filterPgData, filterUnusedData, handlePhotoUpload, updateFormDataWithUploads } from './formHandlers';
import SearchInput from 'components/addForm/searchInput';
import toast from 'react-hot-toast';
import { ALL_PROPERTY_TYPE_TAGS } from 'constants/dbEnums';
import formValidation, { customFormkeysArray, customFormKeys } from 'components/addForm/validation';
import { PROPERTY_TYPE_TAGS } from 'constants/dbEnums';
import { PROPERTY_COLLECTIONS } from 'constants/dbEnums';
import { RESIDENTIAL_PROPERTY_TYPE } from 'constants/dbEnums';
import { showToast } from 'components/toast/Toast';
import { COMMERCIAL_PROPERTY_TYPE } from 'constants/dbEnums';
import { useQueryParams } from 'utils/common';

const App = () => {
  const [propertyType, setPropertyType] = useState('PG');
  const initialFormData = {
    owner_id: "",
    owner_role: "",
    ...(propertyType !== "projects" && {
      type: ALL_PROPERTY_TYPE_TAGS[propertyType],
      property_type: PROPERTY_TYPE_TAGS[propertyType],
    }),
    ...(initialState[ALL_PROPERTY_TYPE_TAGS[propertyType]] || {}),
  };    
  const [formData, setFormData] = useState(initialFormData);
  const [errors, setErrors] = useState({});
  const { apiCall } = useApi();
  const history = useHistory();
  const [isSubmittingForm, setIsSubmittingForm] = useState(false);
  const { propertyId, collectionType, propertyTypeFromQuery, ownerId, companyName } = useQueryParams();
  console.log('formdata', formData);

  const handlePropertyChange = (e) => {
    const { name, value } = e.target;
    setPropertyType(value);
    setFormData((prevData) => ({
      ...prevData,
      [name]: ALL_PROPERTY_TYPE_TAGS[value],
    }));
  };

  const handleFormChange = (e) => {
    const { name, value, company_name, owner_role } = e.target;
    setFormData(prevData => {
      const updatedData = { ...prevData };
      updatedData[name] = value;
      if (name === "owner_id") {
        if (owner_role) {
          updatedData["owner_role"] = owner_role;
        }
        if (company_name) {
          updatedData["company_name"] = company_name;
        }
      }
      return updatedData;
    });
  };

  useEffect(() => {
    const updatedFormData = { ...initialFormData };
    if (ownerId) {
      updatedFormData.owner_id = ownerId;
    }
    setFormData(updatedFormData);
  }, [propertyType]);

  const validateForm = ({updatedFormData}) => {
    const updatedPayload = removeKeyValueFromPayload(updatedFormData);
    const { furnish_type, furnish_type_items, ...rest } = updatedPayload;
    const payload = {
      ...rest,
      furnish_type,
      ...(furnish_type !== "unfurnished" && {
        furnish_type_items: furnish_type_items,
      }),
    };
    const formType = ALL_PROPERTY_TYPE_TAGS[propertyType];
    let schema;
    if(formData._id){
      schema = formValidation[formType].update;
    } else {
      schema = formValidation[formType].create;
    }
    if (!schema) {
      console.error(`No validation schema found for form type: ${formType}`);
      toast.error(`No validation schema found for form type: ${formType}`);
      setIsSubmittingForm(false);
      return { newErrors: {}, payload };
    }
    const { error } = schema.validate(payload, { abortEarly: false });
    const newErrors = {};
    let part;
    let message;
    let localityErrors = [];

    if (error) {
      error.details.forEach((detail) => {
        const path = detail.path;
        let current = newErrors;
        const key = detail?.context?.key;
        const keysArray = customFormkeysArray[formType];
        const newKey = customFormKeys[formType];
        message = detail.message.replace(detail.context.label, key);
        if (keysArray?.includes(key)) {
          if (!current[newKey[key]]) {
            current[newKey[key]] = {};
          }
          current[newKey[key]][key] = message;
        }
        if (path.includes('location') || path.includes('address')) {
          if (path.includes('locality')) {
            localityErrors.push("Locality is required");
          } else if (path.length === 1) {
            current["locality"] = "Locality is not allowed to be empty";
          } else {
            localityErrors.push(message);
          }
        }
        for (let i = 0; i < path.length; i++) {
          part = path[i];
          if (i === path.length - 1) {
            current[part] = message;
          } else {
            if (!current[part]) {
              current[part] = {};
            }
            current = current[part];
          }
        }
        if (localityErrors.length > 0) {
          newErrors["locality"] = localityErrors.join(", ");
        }
      });
    }
    return { newErrors, payload };
  };

  const handleFormSubmit = async () => {
    try {
      setIsSubmittingForm(true);
      let s3Imgs = [];
      let updatedFormData = {};
      if(formData.photos?.length>0){
        const uploadProjectPhotos = propertyType === ALL_PROPERTY_TYPE_TAGS.projects && formData?.photos?.length>0;
        const uploadedPhotos = await handlePhotoUpload({ apiCall, selectedImages: formData.photos, collectionType: propertyType, formData, uploadProjectPhotos});
        const existingPhotos = formData?.photos?.filter(photo => typeof photo === 'string' && photo.trim() !== '');
        s3Imgs = [...existingPhotos, ...uploadedPhotos];
      }
      if (propertyType === ALL_PROPERTY_TYPE_TAGS.projects) {
        updatedFormData = await updateFormDataWithUploads({ apiCall, propertyType, formData, s3Imgs });
        if(formData?.floor_plan_and_pricing?.length>0){
          updatedFormData.floor_plan_and_pricing = filterEmptyValues(updatedFormData?.floor_plan_and_pricing);          
        }
      } else {
        filterUnusedData(formData, propertyType);
        updatedFormData = {
          ...formData,
          photos: s3Imgs,
          ...(formData.type === "PG" && {
            pricing: filterPgData(formData.pricing, formData.room_sharing_type),
          }),
        };
      }  
      const { newErrors, payload } = validateForm({updatedFormData});
      if (Object.keys(newErrors)?.length > 0) {
        setErrors(newErrors);
        console.error('form submit error', newErrors);
        toast.error("Please fill in all the required fields.");
        setIsSubmittingForm(false);
        return;
      }
      const response = await createForm({ apiCall, formData: payload, propertyType });
      if(response) {
        setIsSubmittingForm(false);
        setFormData(initialFormData);
      }
      setErrors({});
    } catch (error) {
      console.log("Form submit/update error", error);
      setIsSubmittingForm(false);
      showToast({
        message: error,
        success: false,
        customStyle: {
          background: "#F24052",
          color: "#fff",
          padding: "10px",
        },
      });
    }
  };

  const getFormDataFn = async () => {
    try {
      let formType;
      if(collectionType === PROPERTY_COLLECTIONS.PLOT){
        formType = `${propertyTypeFromQuery}_${collectionType}`;
      } else if(collectionType === PROPERTY_COLLECTIONS.RESIDENTIAL_PROPERTY || collectionType === PROPERTY_COLLECTIONS.COMMERCIAL_PROPERTY){
        if(propertyTypeFromQuery === RESIDENTIAL_PROPERTY_TYPE.OTHERS || propertyTypeFromQuery === COMMERCIAL_PROPERTY_TYPE.OTHER){
          const [baseType] = collectionType.split('_');
          formType = `${baseType}_${propertyTypeFromQuery}`;
        } else {
          formType = propertyTypeFromQuery
        }
      } else {
        formType = collectionType;
      }
      const response = await getFormData({ apiCall, propertyId, collectionType });
      setPropertyType(formType);
      if(formType === ALL_PROPERTY_TYPE_TAGS.projects){
        setFormData({...response, owner_id: response?.builder_id, company_name: companyName});
      } else {
        setFormData({...response, type: collectionType, propertyType: formType});
      }
    } catch (error) {
      console.log("Error fetching property data", error);
    }
  };

  useEffect(() => {
    if (propertyId && collectionType) {
      getFormDataFn();
    }
  }, [propertyId, collectionType]);

  useEffect(() => {
    if (propertyTypeFromQuery === ALL_PROPERTY_TYPE_TAGS.projects) {
      setPropertyType(propertyTypeFromQuery);
    }
  }, [propertyTypeFromQuery]);

  return (
    <Box
      p={4}
      px={{ base: 4, md: 12 }}
      maxW={{ base: "100%", md: "1200px" }}
      mx="auto"
    >
      <Box onClick={() => history.goBack()} style={{cursor: 'pointer'}}>
        <ArrowBackIcon />
      </Box>
      <Box textAlign="center" mb={6}>
        <Heading>Create Ads</Heading>
      </Box>
      <HStack spacing={4} mb={6}>
        <SelectInput
          label="Property Type"
          options={ALL_PROPERTY_TYPE}
          onChange={handlePropertyChange}
          isRequired={true}
          name="type"
          selectedValue={propertyType}
          error={errors.type}
          disabled={formData?._id || ownerId}
        />
        <SearchInput
          label={propertyType === ALL_PROPERTY_TYPE_TAGS.projects ? "Builder Name" : "User Phone Number or ID"}
          name="owner_id"
          value={(collectionType || ownerId) ? formData?.owner_id : '' }
          onChange={handleFormChange}
          placeholder="Search by Phone Number"
          type="text"
          isRequired={true}
          error={errors.owner_id}
          propertyType={ownerId ? '' : propertyType}
          disabled={formData?._id || ownerId}
          setErrors={setErrors}
        />
      </HStack>
      <CommonForms
        formType={propertyType}
        formData={formData}
        onChange={handleFormChange}
        errors={errors}
      />
      <HStack mt={4} justifyContent="center" alignItems="center" spacing={4}>
      {isSubmittingForm && <Spinner size='lg' />}
        <Button
          colorScheme="teal"
          size="lg"
          px={8}
          py={6}
          fontSize="lg"
          bg="purple.500"
          borderRadius="5px"
          borderColor="#637381"
          background={'linear-gradient(180deg, #22ACEE 0%, #0177B4 100%)'}
          color="#ffffff"
          onClick={handleFormSubmit}
          disabled={isSubmittingForm}
        >
          Submit
        </Button>
      </HStack>
    </Box>
  );
};

export default App;

import React, { useCallback, useEffect, useMemo, useState } from 'react';

import {
  Badge,
  Box,
  Button,
  Flex,
  HStack,
  InputGroup,
  Text,
} from '@chakra-ui/react';

import useApi from 'hooks/useApi';
import { buildersColumns } from 'views/admin/builders/variables';

import Pagination from 'components/pagination/Pagination';

import ErrorDisplay from 'components/errorDisplay/ErrorDisplay';

import { formatBuildersTableData } from 'utils/common';

import { OverlayLoaderComponent } from 'components/loader/Loader';

import { BUILDERS_LISTING_API } from 'constants/endpoints';
import NoDataFound from 'components/noDataFound/NoDataFound';

import { FiChevronsDown, FiFilter } from 'react-icons/fi';
import ManageColumns from 'components/manageColumns/ManageColumns';

import { SearchBar } from 'components/navbar/searchBar/SearchBar';
import { getFilterFromLocalStorage } from 'utils/common';
import { ENTITY_COLUMNS_TYPES } from 'utils/common';
import { setFilterToLocalStorage } from 'utils/common';
import CustomTable from 'components/table/CustomTable';
import SidebarFilter from 'components/sidebarFilter/SidebarFilter';
import { ENTITY_FILTER_TYPES } from 'utils/common';
import { PROPERTY_STATUS } from 'constants/filterDropdownData';
import { formatDateToDDMMYYYY } from 'utils/common';
import { showToast } from 'components/toast/Toast';
import { UPDATE_USER_AND_PROPERTY_API } from 'constants/endpoints';

import Menu from 'components/menu/MainMenu';
import { USER_STATUS } from 'constants/filterDropdownData';
import { areAllStatusesSame } from 'utils/common';
import MultiSelectMenu from 'components/menu/MultiSelectMenu';
import { useHistory } from 'react-router-dom';

const PAGE_SIZE = 50;



export default function Builders() {
  const { apiCall, error, loading } = useApi();
  const history = useHistory();
  const [isOpenFilterSidebar, setIsOpenFilterSidebar] = useState(false);

  const [builderData, setBuilderData] = useState([]);

  const [searchText, setSearchText] = useState('');

  const [selectedAction, setSelectedAction] = useState('');

  const lsFiltersBuildersPageSize = getFilterFromLocalStorage(
    ENTITY_FILTER_TYPES.BUILDERS_PAGE_SIZE
  );

  const [pageSize, setPageSize] = useState(
    lsFiltersBuildersPageSize ? lsFiltersBuildersPageSize : PAGE_SIZE
  );

  const lsAdsDateRange = getFilterFromLocalStorage(
    ENTITY_FILTER_TYPES.BUILDERS_DATE_RANGE
  );

  const [startDate, setStartDate] = useState(
    lsAdsDateRange ? lsAdsDateRange[0] : ''
  );
  const [endDate, setEndDate] = useState(
    lsAdsDateRange ? lsAdsDateRange[1] : ''
  );

  const lsFilters = getFilterFromLocalStorage(
    ENTITY_FILTER_TYPES.BUILDERS_FILTER
  );

  const [filters, setFilters] = useState(
    lsFilters
      ? lsFilters
      : {
          status: '',
        }
  );

  const { status = "" } = filters || {};

  const [filteredData, setFilteredData] = useState(
    lsFilters ? Object.keys(lsFilters).filter((item) => filters[item]) : []
  );
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(10);
  const [filterRemoveFlagCount, setFilterRemoveFlagCount] = useState(0);
  const [totalCount, setTotalCount] = useState(0);

  const [selectedRowsData, setSelectedRowsData] = useState([]);

  const [manageColumnSelectḀ̥̥̥̥̥̥̥̥llCheckbox, setManageColumnSelectAllCheckbox] =
    useState(false);

  const columns = useMemo(() => buildersColumns, [buildersColumns]);
  const data = useMemo(
    () => formatBuildersTableData(builderData),
    [builderData]
  );

  const fetchData = useCallback(async () => {
    try {
      const data = await apiCall.get(`${BUILDERS_LISTING_API}`, {
        status,
        search_text: searchText,
        page: currentPage,
        limit: pageSize,
        start_date: formatDateToDDMMYYYY(startDate),
        end_date: formatDateToDDMMYYYY(endDate),
      });

      const formatData = data?.data?.data;

      setTotalPages(Math.ceil(data?.data?.count / pageSize));
      setTotalCount(data?.data?.count);
      setBuilderData(formatData);
    } catch (error) {
      console.log(error);
    }
  }, [searchText, currentPage, status, startDate, endDate, pageSize]);

  const lsLocalStorageManageColumns = getFilterFromLocalStorage(
    ENTITY_COLUMNS_TYPES.BUILDERS_COLUMNS
  );

  const [columnVisibility, setColumnVisibility] = useState(
    lsLocalStorageManageColumns
      ? lsLocalStorageManageColumns
      : columns.reduce((acc, column) => {
          acc[column.accessor] = true;
          return acc;
        }, {})
  );

  useEffect(() => {
    setFilterToLocalStorage(
      ENTITY_COLUMNS_TYPES.BUILDERS_COLUMNS,
      columnVisibility
    );
  }, [columnVisibility]);

  const handleColumnChange = (accessor) => {
    setColumnVisibility((prev) => ({
      ...prev,
      [accessor]: !prev[accessor],
    }));
  };

  useEffect(() => {
    fetchData();
  }, [filterRemoveFlagCount, currentPage, pageSize]);

  const toggleSidebar = () => {
    setIsOpenFilterSidebar(!isOpenFilterSidebar);
  };

  const visibleColumns = columns.filter(
    (column) => columnVisibility[column.accessor]
  );
  const handleFetchSearch = useCallback(
    (text) => {
      setCurrentPage(1);
      fetchData(text);
    },
    [fetchData]
  );

  const handleInputFormSubmit = (e) => {
    e.preventDefault();
    handleFetchSearch(searchText);
  };

  const handleApplyFilter = () => {
    const applyFilterArray = filters
      ? Object.keys(filters).filter((key) => filters[key])
      : [];

    setFilteredData(applyFilterArray);
    setFilterToLocalStorage(ENTITY_FILTER_TYPES.BUILDERS_FILTER, filters);
    setCurrentPage(1);

    setSelectedRowsData([]);
    fetchData();
  };

  const handleFilterChange = useCallback((key, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [key]: value,
    }));
    setCurrentPage(1);
  }, []);

  const handleRemoveAllFilter = () => {
    setFilteredData([]);

    setFilters({
      status: '',
    });

    setEndDate('');
    setStartDate('');
  };

  const handleRowSelect = useCallback(
    (row) => {
      const findData = selectedRowsData.find((item) => item._id === row._id);

      setSelectedRowsData((prev) => {
        if (findData) {
          return prev.filter((item) => item._id !== findData._id);
        } else {
          return [...prev, row];
        }
      });
    },
    [setSelectedRowsData, selectedRowsData]
  );

  const dropdownFilters = [
    {
      name: 'status',
      placeholder: 'Select Status',
      value: filters.status,
      data: PROPERTY_STATUS,
    },
  ];

  const allFiltersProps = {
    dropdowns: dropdownFilters,
  };

  const selectedRowsSlug = selectedRowsData.map((item) => item.slug_url);

  const selectedData = useMemo(
    () =>
      selectedRowsSlug.map((slug) => data.find((row) => row.slug_url === slug)),
    [selectedRowsSlug, data]
  );

  const handleUpdatePropertyStatus = async ({
    updatedPropertyType,
    slug,
    id: selectedId
  }) => {
    try {
      const data = builderData.find((item) => item?._id === selectedId);
      const {_id, company, user} = data || {};
      if (updatedPropertyType === "/admin/form") {
        history.push(
          `/admin/builder?id=${company._id}&type=company_account&builder_id=${_id}&company_name=${company?.name}&slug_url=${user?.slug_url}`
        );
        return;
      }

      const { _id: id } = data;

      await apiCall.patch(UPDATE_USER_AND_PROPERTY_API, {
        updateData: [
          {
            type: 'builders',

            _id: id,

            status: updatedPropertyType,
          },
        ],
      });

      setFilterRemoveFlagCount((prev) => prev + 1);

      showToast({
        message: 'Property status updated successfully',
        customStyle: {
          background: 'green',
          color: '#fff',
          padding: '10px',
        },
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleMultipleUpdateStatus = async ({ updatedStatus }) => {
    const statusArray = selectedRowsData?.map((item) => item?.status);

    console.log('STATUS ARRAY', statusArray);
    if (!areAllStatusesSame(statusArray)) {
      showToast({
        message: 'All selected properties must have the same status',
        customStyle: {
          background: '#ff4d4f',
          color: '#fff',
          padding: '10px',
        },
      });
      return;
    }

    const filterBySlugUrl = (smallArray, fullArray) => {
      return fullArray.filter((fullItem) =>
        smallArray.some((smallItem) => smallItem.slug_url === fullItem.slug_url)
      );
    };

    const filterdToBeUpdatedData = filterBySlugUrl(
      selectedRowsData,
      builderData
    );

    const updatedData = filterdToBeUpdatedData.map((item) => {
      return {
        type: 'builders',
        _id: item._id,

        status: updatedStatus,
      };
    });

    try {
      await apiCall.patch(UPDATE_USER_AND_PROPERTY_API, {
        updateData: updatedData,
      });

      setFilterRemoveFlagCount((prev) => prev + 1);

      showToast({
        message: 'Property status updated successfully',
        customStyle: {
          background: 'green',
          color: '#fff',
          padding: '10px',
        },
      });

      setSelectedRowsData([]);
    } catch (error) {}
  };

  if (error) {
    return <ErrorDisplay error={error} />;
  }

  return (
    <Box height="100vh" bg="#F8FAFB">
      <Flex
        justify="space-between"
        align="center"
        p="2"
        // bg={useColorModeValue("gray.50", "gray.700")}
        boxShadow="sm"
        borderRadius="sm"
        pos="fixed"
        style={{ width: "calc(100vw - 96px)" }}
        zIndex={1000}
      >
        <HStack spacing="4" p={2}>
          <Box position="relative" display="inline-block">
            <Button
              paddingX={6}
              leftIcon={<FiFilter />}
              rightIcon={<FiChevronsDown />} // Changed the icon to ChevronDown
              colorScheme="teal"
              variant="outline"
              borderRadius="md"
              size="sm"
              onClick={() => {
                toggleSidebar();
                setFilters(lsFilters ? lsFilters : { status: "" });
              }}
            >
              Filter
            </Button>
            {filteredData.length > 0 && (
              <Badge
                position="absolute"
                top="-3"
                right="0"
                colorScheme="teal"
                borderRadius="full"
                px={2}
                fontSize="0.8em"
              >
                {filteredData.length}
              </Badge>
            )}
          </Box>

          <ManageColumns
            columns={columns}
            handleColumnChange={handleColumnChange}
            columnVisibility={columnVisibility}
            setManageColumnSelectAllCheckbox={setManageColumnSelectAllCheckbox}
            manageColumnSelectḀ̥̥̥̥̥̥̥̥llCheckbox={manageColumnSelectḀ̥̥̥̥̥̥̥̥llCheckbox}
          />

          <form onSubmit={handleInputFormSubmit}>
            <InputGroup size="sm">
              <SearchBar
                background="#F9F9FB"
                color="#637381"
                setSearchText={(e) => setSearchText(e.target.value)}
                searchText={searchText}
                borderRadius="5px"
                borderWidth="0.5px"
                borderColor="#637381"
              />
            </InputGroup>
          </form>
        </HStack>
        <Button
          borderRadius="5px"
          borderColor="#637381"
          background={"linear-gradient(180deg, #22ACEE 0%, #0177B4 100%)"}
          color="#ffffff"
          fontSize="sm"
          colorScheme="teal"
          onClick={() => {
            history.push("/admin/builder");
          }}
        >
          Create Builder
        </Button>
        <Text
          borderRadius="5px"
          borderWidth="0.5px"
          borderColor="#637381"
          background="#F9F9FB"
          color="#637381"
          p={1}
        >
          Total Builders: {totalCount}
        </Text>

        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={(value) => setCurrentPage(value)}
          totalCount={totalCount}
          setCurrentPage={setCurrentPage}
          pageSize={pageSize}
          setPageSize={setPageSize}
          setPageSizeToLocalStorage={(value) =>
            setFilterToLocalStorage(ENTITY_FILTER_TYPES.USERS_PAGE_SIZE, value)
          }
        />
      </Flex>

      <SidebarFilter
        filters={allFiltersProps}
        handleFilterChange={handleFilterChange}
        handleApplyFilter={handleApplyFilter}
        isOpen={isOpenFilterSidebar}
        onClose={toggleSidebar}
        appliedFilters={filters}
        setFilters={setFilters}
        lsFilters={lsFilters ? lsFilters : { status: "" }}
        handleRemoveAllFilter={handleRemoveAllFilter}
        //calender filter
        isCalenderFilter={true}
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
      />

      {/* NEW CODE END */}

      {builderData.length !== 0 ? (
        <>
          <CustomTable
            columns={visibleColumns}
            data={data}
            onSelectedAction={setSelectedAction}
            selectedRowsData={selectedRowsData}
            selectedRowsSlug={selectedRowsSlug}
            selectedData={selectedData}
            handleRowSelect={handleRowSelect}
            setSelectedRowsData={setSelectedRowsData}
            //single menu props
            menuComponent={
              <Menu
                data={Object.values(USER_STATUS)}
                onUpdate={() => {}}
                // currentValue={row.original.property_status}
                // onSelectedAction={() => onSelectedAction(row.original)}
                onUpdatePropertyStatus={handleUpdatePropertyStatus}
                selectedAction={selectedAction}
              />
            }
            multiSelectMenuComponent={
              selectedRowsSlug?.length > 0 &&
              areAllStatusesSame(
                selectedData.map((item) => item?.property_status)
              ) && (
                <MultiSelectMenu
                  multiSelectCurrentValue={selectedData[0]?.property_status}
                  onMultipleUpdateStatus={handleMultipleUpdateStatus}
                  selectedAction={selectedAction}
                  selectedRowsData={selectedRowsData}
                  bgColor="#B2F5EA"
                />
              )
            }
          />
        </>
      ) : !loading ? (
        <NoDataFound />
      ) : null}

      <OverlayLoaderComponent isOpen={loading} />
    </Box>
  );
}

import { QuestionIcon } from "@chakra-ui/icons";
import { IconButton, Tooltip } from "@chakra-ui/react";
import React, { useState } from "react";

const CustomTooltip = ({ dataLabel, children, color }) => {
  const [showTooltip, setShowTooltip] = useState(false);

  const handleEnterTooltip = () => {
    setShowTooltip(true);
  };

  const handleLeaveTooltip = () => {
    setShowTooltip(false);
  };
  return (
    <Tooltip hasArrow label={dataLabel} isOpen={showTooltip} placement="right">
      <IconButton
        aria-label="Toggle Tooltip"
        // icon={<QuestionIcon />}
        icon={children ? children : <QuestionIcon />}
        // onClick={handleToggleTooltip}
        variant="ghost"
        size="sm"
        borderRadius="full"
        // color="gray.500"
        color={color}
        onMouseEnter={handleEnterTooltip}
        onMouseLeave={handleLeaveTooltip}
      />
    </Tooltip>
  );
};

export default CustomTooltip;

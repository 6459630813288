import React, { useCallback, useState } from 'react';
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import { HSeparator } from 'components/separator/Separator';
import DefaultAuth from 'layouts/auth/Default';
import illustration from 'assets/img/auth/auth.png';
import { FcGoogle } from 'react-icons/fc';
import { MdOutlineRemoveRedEye } from 'react-icons/md';
import { RiEyeCloseLine } from 'react-icons/ri';
import { useAuth } from 'contexts/AuthContext';
import { isValidPhoneNumber } from 'utils/common';
import { GoogleLogin, GoogleOAuthProvider } from '@react-oauth/google';

console.log('ENV', process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID);

function SignIn() {
  const textColor = useColorModeValue('navy.700', 'white');
  const textColorSecondary = 'gray.400';
  const textColorBrand = useColorModeValue('brand.500', 'white');
  const brandStars = useColorModeValue('brand.500', 'brand.400');
  const googleBg = useColorModeValue('secondaryGray.300', 'whiteAlpha.200');
  const googleText = useColorModeValue('navy.700', 'white');
  const googleHover = useColorModeValue(
    { bg: 'gray.200' },
    { bg: 'whiteAlpha.300' }
  );
  const googleActive = useColorModeValue(
    { bg: 'secondaryGray.300' },
    { bg: 'whiteAlpha.200' }
  );
  const [show, setShow] = useState(false);
  const handleClick = () => setShow(!show);

  const { generateOtp, verifyOtp, isOtpGenerated, loading } = useAuth();

  const [otp, setOtp] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');

  const handleGenerateOtp = (e) => {
    e.preventDefault();
    if (!phoneNumber) return;
    generateOtp({ value: phoneNumber, type: 'phone' });
  };

  const handleVerifyOtp = (e) => {
    e.preventDefault();
    if (!otp) return;
    verifyOtp({ value: phoneNumber, type: 'phone', otp: otp });
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (isOtpGenerated) {
      handleVerifyOtp(event);
    } else {
      handleGenerateOtp(event);
    }
  };

  const handleVerifyMail = useCallback(
    async (signInMethod, credential) => {
      try {
        const response = await verifyOtp({
          isGoogleLogin: true,
          credential,
        });

        console.log(response);
      } catch (error) {
        console.log(error);
      }
    },
    [verifyOtp]
  );

  const handleGoogleSuccess = useCallback(
    (credentialResponse) => {
      handleVerifyMail('google', credentialResponse?.credential);

      console.log(credentialResponse);
    },
    [handleVerifyMail]
  );

  const handleGoogleFail = () => {
    console.log('Login Failed');
  };
  return (
    <DefaultAuth illustrationBackground={illustration} image={illustration}>
      <Flex
        maxW={{ base: '100%', md: 'max-content' }}
        w="100%"
        mx={{ base: 'auto', lg: '0px' }}
        me="auto"
        h="100%"
        alignItems="start"
        justifyContent="center"
        mb={{ base: '30px', md: '60px' }}
        px={{ base: '25px', md: '0px' }}
        mt={{ base: '40px', md: '14vh' }}
        flexDirection="column"
      >
        <Box me="auto">
          <Heading color={textColor} fontSize="36px" mb="10px">
            Sign In
          </Heading>
          <Text
            mb="36px"
            ms="4px"
            color={textColorSecondary}
            fontWeight="400"
            fontSize="md"
          >
            Enter your phone number to sign in!
          </Text>
        </Box>
        <Flex
          zIndex="2"
          direction="column"
          w={{ base: '100%', md: '420px' }}
          maxW="100%"
          background="transparent"
          borderRadius="15px"
          mx={{ base: 'auto', lg: 'unset' }}
          me="auto"
          mb={{ base: '20px', md: 'auto' }}
        >
          <GoogleOAuthProvider
            clientId={process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID}
          >
            <div className="flex flex-col  w-full xs:max-w-[440px]">
              <div className="flex flex-col ">
                <div className="flex flex-col gap-5 sm:gap-4">
                  <GoogleLogin
                    size="large"
                    text="continue_with"
                    theme="outline"
                    logo_alignment="center"
                    shape="rectangular"
                    onSuccess={handleGoogleSuccess}
                    onError={handleGoogleFail}
                  />
                </div>
              </div>
            </div>
          </GoogleOAuthProvider>

          <Flex align="center" mb="25px">
            <HSeparator />
            <Text color="gray.400" mx="14px">
              or
            </Text>
            <HSeparator />
          </Flex>
          <form onSubmit={handleSubmit}>
            <FormControl>
              <FormLabel
                display="flex"
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                mb="8px"
              >
                Phone<Text color={brandStars}>*</Text>
              </FormLabel>
              <Input
                isRequired={true}
                variant="auth"
                fontSize="sm"
                ms={{ base: '0px', md: '0px' }}
                type="tel"
                placeholder="+123456789"
                mb="24px"
                fontWeight="500"
                size="lg"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
              />

              {isOtpGenerated && (
                <>
                  <FormLabel
                    ms="4px"
                    fontSize="sm"
                    fontWeight="500"
                    color={textColor}
                    display="flex"
                  >
                    Otp<Text color={brandStars}>*</Text>
                  </FormLabel>
                  <InputGroup size="md">
                    <Input
                      isRequired={true}
                      fontSize="sm"
                      placeholder="Enter OTP"
                      mb="24px"
                      size="lg"
                      type="number"
                      variant="auth"
                      value={otp}
                      onChange={(e) => setOtp(e.target.value)}
                      autoFocus={true}
                    />
                    <InputRightElement
                      display="flex"
                      alignItems="center"
                      mt="4px"
                    >
                      <Icon
                        color={textColorSecondary}
                        _hover={{ cursor: 'pointer' }}
                        as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                        onClick={handleClick}
                      />
                    </InputRightElement>
                  </InputGroup>
                </>
              )}

              <Button
                isLoading={loading}
                fontSize="sm"
                variant="brand"
                fontWeight="500"
                w="100%"
                h="50"
                mb="24px"
                type="submit"
              >
                {isOtpGenerated ? 'Verify OTP' : 'Generate OTP'}
              </Button>
            </FormControl>
          </form>
        </Flex>
      </Flex>
    </DefaultAuth>
  );
}

export default SignIn;

// Chakra imports
import { Box } from "@chakra-ui/react";
import Sidebar from "components/sidebar/Sidebar.js";
import { ADMIN_ROLE } from "constants/dbEnums";
import { SidebarContext } from "contexts/SidebarContext";
import React, { useMemo, useState } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import routes from "routes/routes";

// Custom Chakra theme
export default function Dashboard(props) {
  const { ...rest } = props;
  const [toggleSidebar, setToggleSidebar] = useState(false);
  const getRoute = () => {
    return window.location.pathname !== "/admin/full-screen-maps";
  };

 

  const getRoutes = (routes) => {
    return routes.map((prop, index) => {
      if (prop.layout === "/admin") {
        if (prop.path === '/ads') {
          return (
              <Route
              key={index}
                exact
                path={prop.layout + prop.path}
                render={(routeProps) => <prop.component {...routeProps} />}
              />
          );
        }
        if (prop.path === '/users/:userId') {
          return (
            <Route
              exact
                path={prop.layout + prop.path}
                render={(routeProps) => <prop.component {...routeProps} />}
              />
          );
        }
        return (
          <Route
          exact
            path={prop.layout + prop.path}
            key={index}
            render={(routeProps) => <prop.component {...routeProps} />}
          />
        );
      }
      if (prop.collapse) {
        return getRoutes(prop.items);
      }
      if (prop.category) {
        return getRoutes(prop.items);
      } else {
        return null;
      }
    });
  };
  const initialRoute = useMemo(() => {
    const role = localStorage.getItem('role');
    if(!role) return '/admin/default'
    switch(role){
      case ADMIN_ROLE.HR_ADMIN:
        return '/admin/job-applications'
      default:
        return '/admin/default'
    }
  }, [])
  document.documentElement.dir = "ltr";
document.documentElement.dir = "ltr";
  return (
    <Box>
      <Box>
        <SidebarContext.Provider
          value={{
            toggleSidebar,
            setToggleSidebar,
          }}
        >
          <Sidebar routes={routes} display="none" {...rest} />
          <Box
            pos="fixed"
            left="90px"
            minHeight="100vh"
            height="100%"
            overflow="auto"
            position="relative"
            transition="all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)"
            transitionDuration=".2s, .2s, .35s"
            transitionProperty="top, bottom, width"
            transitionTimingFunction="linear, linear, ease"
          >
            {getRoute() ? (
              <Box>
                <Switch>
                  {getRoutes(routes)}
                  <Redirect from="/" to={initialRoute} />
                </Switch>
              </Box>
            ) : null}
          </Box>
        </SidebarContext.Provider>
      </Box>
    </Box>
  );
}

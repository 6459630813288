import { Flex, Link, Text, Tooltip } from "@chakra-ui/react";
import StatusBadge from "components/badge/StatusBadge";
import { ASSETS_PATH } from "constants/endpoints";

const getMessage = (name, phone, job) => {
  const companyName = "Hexadecimal Software Pvt Ltd";
  const message = `Hello ${name || "Candidate"},%0AI'm from the HR team at ${companyName}. You recently applied for the ${job} position. Please feel free to give me a call whenever you're available.%0A%0AWebsite: https://www.hexadecimalsoftware.com/`;
  return `https://wa.me/+91${phone}?text=${message}`;
};

export const jobApplicationColumn = [
  {
    Header: "NAME",
    accessor: "name",
  },
  { Header: "EMAIL",
    accessor: "email",
    Cell: ({row, value}) => (
      <Link target="_blank" href={getMessage(row?.values?.name[0], row?.values?.phone, row?.values?.job_title)} fontWeight={'semibold'} >{value}</Link>
    ),
  },
  {
    Header: "PHONE",
    accessor: "phone",
    Cell: ({ value, row }) => (
      <Link target="_blank" href={getMessage(row?.values?.name[0], value, row?.values?.job_title)} fontWeight={'semibold'} >{value}</Link>
    ),
  },
  {
    Header: 'COMMENT',
    accessor: 'comment',
    Cell: ({ value }) => (
      <Flex align="center" justify="center">
        {
          value.length > 20 ? (<Tooltip label={value} aria-label='A tooltip'>
            {value.length > 20 ? `${value.slice(0, 20)}...` : value}
          </Tooltip>) : (<Text>{value}</Text>)
        }
      </Flex>
    ),
  },
  {
    Header: "COMMENT UPDATED ON",
    accessor: "last_comment_updated_on",
  },
  {
    Header: "STATUS",
    accessor: "status",
    Cell: ({ value }) => (
      <Text>
        <StatusBadge status={value} />
      </Text>),
  },
  {
    Header: "JOB TITLE",
    accessor: "job_title",
    Cell: ({ value }) => (
      <Flex align="center" justify="center">
        {
          value.length > 20 ? (<Tooltip label={value} aria-label='A tooltip'>
            {value.length > 20 ? `${value.slice(0, 20)}...` : value}
          </Tooltip>) : (<Text>{value}</Text>)
        }
      </Flex>
    ),
  },
  {
    Header: "SKILLS",
    accessor: "skills",
    Cell: ({ value }) => (
      <Flex align="center" justify="center">
        {
          value.length > 20 ? (<Tooltip label={value} aria-label='A tooltip'>
            {value.length > 20 ? `${value.slice(0, 20)}...` : value}
          </Tooltip>) : (<Text>{value || "-"}</Text>)
        }
      </Flex>
    ),
  },
  {
    Header: "EDUCATION",
    accessor: "education",
  },
  {
    Header: "PASSOUT IN",
    accessor: "passout_year",
  },
  {
    Header: "LOCATION",
    accessor: "current_location",
  },
  {
    Header: "WORK PREFERENCE",
    accessor: "work_type_preference",
  },
  {
    Header: "EXPERIENCE",
    accessor: "experience",
  },
  {
    Header: "JOB ID",
    accessor: "job_id",
  },
  {
    Header: "RESUME",
    accessor: "resume",
    Cell: ({ value }) => (<Flex align="center" justify={"center"}>
      <a href={`${ASSETS_PATH}${value}`} target="_blank">
        <StatusBadge status={"View Resume"} />
      </a>
    </Flex>),
  },
  {
    Header: "Answer",
    accessor: "answer",
    Cell: ({ value }) => (value ? <Flex align="center" justify={"center"}>
      <a href={`${ASSETS_PATH}${value}`} target="_blank">
        <StatusBadge status={"View Answer"} />
      </a>
    </Flex> : <Text>-</Text>),
  },
  {
    Header: "APPLIED ON",
    accessor: "created_at",
  },
  {
    Header: "LAST REMINDER SENT",
    accessor: "last_reminder_email_sent_on",
  },
  {
    Header: "DEV ASSIGNEE",
    accessor: "dev_asignee",
  },
  {
    Header: "DEADLINE",
    accessor: "deadline",
  },
];

import React, { useMemo } from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import { Box, Flex, Text } from '@chakra-ui/react';
import 'react-circular-progressbar/dist/styles.css'; // Make sure to import the CSS
import { getScoreCardDetails } from 'utils/common';

const ListingScoreCard = ({ score }) => {
  const cardDetails = useMemo(() => {
    return getScoreCardDetails(score);
  }, [score]);

  return (
    <Box p={6} boxShadow="sm" borderRadius="lg" textAlign="center">
      <Flex justify="center" mb={4}>
        <Box width="160px">
          <CircularProgressbar
            value={score}
            text={`${score}%`}
            circleRatio={1}
            styles={buildStyles({
              rotation: 0,
              strokeLinecap: 'round',
              textSize: '20px',
              pathTransitionDuration: 0.5,
              pathColor: cardDetails.listingScoreColor.filled,
              textColor: '#404040',
              trailColor: cardDetails.listingScoreColor.track,
            })}
            strokeWidth={10}
          />
        </Box>
      </Flex>
      <Text
        fontWeight="semibold"
        fontSize="xl"
        color={cardDetails.listingScoreColor.filled}
      >
        {cardDetails.listingScoreText.text}
      </Text>
      <Text fontSize="sm" color="gray.500">
        {cardDetails.listingScoreText.subText}
      </Text>
    </Box>
  );
};

export default ListingScoreCard;

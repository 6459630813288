import Agent from "./Agent.config";
import Builder from "./Builder.config";
import Company from "./Company.config";

const dynamicForm = {
  company: Company,
  builder: Builder,
  agent: Agent
};

export default dynamicForm;

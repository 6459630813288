import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
  Flex,
  Badge,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  TableContainer,
  Link,
} from "@chakra-ui/react";
import ErrorDisplay from "components/errorDisplay/ErrorDisplay";
import { OverlayLoaderComponent } from "components/loader/Loader";
import { PROPERTY_TABLE_TAG } from "constants/dbEnums";
import { AD_TYPE_TAGS } from "constants/dbEnums";
import { LOCALITY_INSIGHT_API } from "constants/endpoints";
import useApi from "hooks/useApi";
import { useCallback, useEffect, useState } from "react";
import { generateSlugUrl } from "utils/generateSlugUrl";

export default function LocalityInsight() {
  const [localityData, setLocalityData] = useState([]);
  const { apiCall, error, loading } = useApi();

  const transformData = (data) => {
    return {
      city: data.city,
      localities: data.localities.map((locality) => {
        const newLocality = {
          locality: locality.locality,
          total_ads: locality.total_ads,
        };
        const propertyTypes = new Set([
          ...Object.keys(locality.rent),
          ...Object.keys(locality.sell),
        ]);
        propertyTypes.forEach((type) => {
          newLocality[type] = {};

          if (type in locality.rent) {
            newLocality[type].rent = locality.rent[type];
          }
          if (type in locality.sell) {
            newLocality[type].sell = locality.sell[type];
          }
        });
        return newLocality;
      }),
    };
  };

  const fetchLocalityData = useCallback(async () => {
    try {
      const response = await apiCall.get(`${LOCALITY_INSIGHT_API}`, null);
      const transformedData = response.data.map((localityData) =>
        transformData(localityData)
      );
      const sortedData = transformedData.sort((a, b) => {
        const totalA = a.localities.reduce(
          (sum, locality) => sum + locality.total_ads,
          0
        );
        const totalB = b.localities.reduce(
          (sum, locality) => sum + locality.total_ads,
          0
        );
        return totalB - totalA;
      });
      sortedData.forEach((city) => {
        city.localities.sort((a, b) => b.total_ads - a.total_ads);
      });
      setLocalityData(sortedData);
    } catch (error) {
      console.log(error);
    }
  }, []);

  const totalListingCountInCity = useCallback(
    (city) => {
      const cityData = localityData?.filter((data) => data?.city === city);
      let totalAds = 0;
      cityData[0]?.localities?.forEach((locality) => {
        totalAds += locality?.total_ads;
      });
      return totalAds;
    },
    [localityData]
  );

  const totalListingCount = useCallback(
    (city, propertyType) => {
      const cityData = localityData?.filter((data) => data?.city === city);
      let totalAds = 0;
      cityData[0]?.localities?.forEach((locality) => {
        if (locality[propertyType]) {
          totalAds += locality[propertyType][AD_TYPE_TAGS.RENT] || 0;
          totalAds += locality[propertyType][AD_TYPE_TAGS.SELL] || 0;
        }
      });
      return totalAds;
    },
    [localityData]
  );

  const totalListingCountByAdType = useCallback(
    (city, propertyType, adType) => {
      const cityData = localityData?.filter((data) => data?.city === city);
      let totalAds = 0;
      cityData[0]?.localities?.forEach((locality) => {
        if (locality[propertyType]) {
          totalAds += locality[propertyType][adType] || 0;
        }
      });
      return totalAds;
    },
    [localityData]
  );

  useEffect(() => {
    fetchLocalityData();
  }, []);

  const sortByPropertyTypes = useCallback(
    (cityData) => {
      const propertyTotals = {};
      cityData.localities.forEach((locality) => {
        Object.keys(locality).forEach((propertyType) => {
          if (PROPERTY_TABLE_TAG[propertyType]) {
            const propertyCounts = locality[propertyType];
            if (!propertyTotals[propertyType]) {
              propertyTotals[propertyType] = 0;
            }
            if (propertyCounts.rent) {
              propertyTotals[propertyType] += propertyCounts.rent;
            }
            if (propertyCounts.sell) {
              propertyTotals[propertyType] += propertyCounts.sell;
            }
          }
        });
      });
      const sortedPropertyTypes = Object.keys(propertyTotals).sort((a, b) => {
        return propertyTotals[b] - propertyTotals[a];
      });
      const missingPropertyTypes = Object.keys(PROPERTY_TABLE_TAG).filter(type => !sortedPropertyTypes.includes(type));
      const sortedPropertyTableTag = {};
      sortedPropertyTypes.forEach(type => {
        sortedPropertyTableTag[type] = PROPERTY_TABLE_TAG[type];
      });
      missingPropertyTypes.forEach(type => {
        sortedPropertyTableTag[type] = PROPERTY_TABLE_TAG[type];
      });      
      return Object.keys(sortedPropertyTableTag);
    },
    [localityData]
  );
  

  if (error) {
    return <ErrorDisplay error={error} />;
  }

  return (
    <Box paddingTop="10" bg="gray.50" minH="100vh" paddingRight={"20"}>
      <OverlayLoaderComponent isOpen={loading} />
      <Flex justifyContent="center">
        <Accordion allowToggle width={"90vw"}>
          {localityData?.map((data, index) => (
            <AccordionItem key={index}>
              <h2>
                <AccordionButton
                  _expanded={{ bg: "gray.100" }}
                  _hover={{ bg: "gray.50" }}
                  borderRadius="md"
                  mb={2}
                >
                  <Box
                    as="span"
                    flex="1"
                    textAlign="left"
                    fontWeight="bold"
                    fontSize="lg"
                    color="gray.800"
                  >
                    {data?.city}
                  </Box>
                  <Box as="span" flex="1" textAlign="right" fontSize={"2xl"}>
                    <Badge fontSize="12" colorScheme="red">
                      Total Ads: {totalListingCountInCity(data?.city)}
                    </Badge>
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
                <TableContainer>
                  <Table
                    variant="simple"
                    border="1px solid"
                    borderColor="gray.500"
                    size="sm"
                  >
                    <Thead>
                      <Tr>
                        <Th
                          rowSpan={2}
                          border="1px solid"
                          borderColor="#E2E8F0"
                          left={0}
                          bg="gray.50"
                          fontSize={'10'}
                        >
                          Localities
                        </Th>
                        {sortByPropertyTypes(data).map((title, index) => (
                          <Th
                            colSpan={2}
                            key={index}
                            textAlign={"center"}
                            border="1px solid"
                            borderColor="#E2E8F0"
                            backgroundColor={'#FAF5FF'}
                            fontSize={'10'}
                          >
                            {`${PROPERTY_TABLE_TAG[title]} (${totalListingCount(
                              data?.city,
                              title
                            )})`}
                          </Th>
                        ))}
                      </Tr>
                      <Tr>
                        {sortByPropertyTypes(data).map((title) => (
                          <>
                            <Th border="1px solid" borderColor="#E2E8F0" fontSize={'10'}>
                              {`Rent(${totalListingCountByAdType(data?.city, title, AD_TYPE_TAGS.RENT)})`}
                            </Th>
                            <Th border="1px solid" borderColor="#E2E8F0" fontSize={'10'}>
                              {`Sell(${totalListingCountByAdType(data?.city, title, AD_TYPE_TAGS.SELL)})`}
                            </Th>
                          </>
                        ))}
                      </Tr>
                    </Thead>
                    <Tbody>
                      {data?.localities.map((localityData, index) => (
                        <Tr key={index}>
                          <Td
                            border="1px solid"
                            borderColor="#E2E8F0"
                            left={0}
                            bg="gray.50"
                            fontSize={'12'}
                          >
                            {localityData?.locality}
                          </Td>
                          {sortByPropertyTypes(data).map((property) => (
                            <>
                              <Td border="1px solid" borderColor="#E2E8F0" fontSize={'10'}>
                                <Link
                                  target="_blank"
                                  href={`${generateSlugUrl(
                                    property,
                                    AD_TYPE_TAGS.RENT,
                                    localityData?.locality,
                                    data?.city
                                  )}`}
                                >
                                  {(localityData[property] &&
                                    localityData[property][
                                      AD_TYPE_TAGS.RENT
                                    ]) ||
                                    '-'}
                                </Link>
                              </Td>
                              <Td border="1px solid" borderColor="#E2E8F0" fontSize={'10'}>
                                <Link
                                  target="_blank"
                                  href={`${generateSlugUrl(
                                    property,
                                    AD_TYPE_TAGS.SELL,
                                    localityData?.locality,
                                    data?.city
                                  )}`}
                                >
                                  {(localityData[property] &&
                                    localityData[property][
                                      AD_TYPE_TAGS.SELL
                                    ]) ||
                                    '-'}
                                </Link>
                              </Td>
                            </>
                          ))}
                        </Tr>
                      ))}
                    </Tbody>
                  </Table>
                </TableContainer>
              </AccordionPanel>
            </AccordionItem>
          ))}
        </Accordion>
      </Flex>
    </Box>
  );
}

import { Flex, Text } from '@chakra-ui/react';
import StatusBadge from 'components/badge/StatusBadge';

export const agentsColumn = [
  {
    Header: 'SLUG',
    accessor: 'slug_url',
    Cell: ({ value }) => (
      <a
        _hover={{ textDecoration: 'underline' }}
        cursor="pointer"
        href={`${process.env.REACT_APP_HEXAHOME_BASE_URL}/${value}`}
        target="_blank"
        rel="noreferrer"
        whiteSpace={'nowrap'}
        style={{ fontSize: '10px' }}
      >
        {value}
      </a>
    ),
  },
  {
    Header: 'NAME',
    accessor: 'name',
  },

  {
    Header: 'EMAIL',
    accessor: 'email',
  },
  {
    Header: 'STATUS',
    accessor: 'status',
    Cell: ({ value }) => (
      <Flex align="center" justify={'center'}>
        <Text>
          <StatusBadge status={value} />
        </Text>
      </Flex>
    ),
  },
  {
    Header: 'COMPANY NAME',
    accessor: 'company_name',
  },
];

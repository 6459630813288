import SelectInput from "../SelectInput";
import { Box, Flex } from "@chakra-ui/react";
import { POSSESSION_PERIOD } from "constants/dbEnums";
import { MONTHS_TAGS } from "constants/dbEnums";
import { OWNERSHIP_TAGS } from "constants/dbEnums";
import ValidatedInput from "../ValidatedInput";
import { AREA_UNIT } from "constants/dbEnums";
import { PER_AREA_UNIT } from "constants/dbEnums";
import { DIMENSION_UNIT_TAGS } from "constants/dbEnums";
import { DIMENSION } from "constants/dbEnums";
import { NO_OF_OPEN_SIDES_TAGS } from "constants/dbEnums";
import { FACING } from "constants/dbEnums";
import CheckboxComponent from "../Checkbox";
import { any_construction_done } from "constants/dbEnums";
import { CONSTRUCTION_TYPE_OF_PLOT_TAGS } from "constants/dbEnums";
import { ALLOW_BROKERS } from "constants/dbEnums";
import { boundary_wall } from "constants/dbEnums";
import { CORNER_PLOT } from "constants/dbEnums";
import { is_price_negotiable } from "constants/dbEnums";
import { tax_and_govt_charges_excluded } from "constants/dbEnums";
import { IS_HOT_DEAL } from "constants/dbEnums";
import { DISCOUNT_TYPE } from "constants/dbEnums";
import { DISCOUNT_TYPE_TAGS } from "constants/dbEnums";
import ErrorMessage from "../ErrorMessage";
import CalendarInput from "../CalendarInput";
import { FINANCING_AVAILABLE } from "constants/dbEnums";
import AutoSuggestComponent from "../location/locality";
import MultiSelect from "../MultiSelect";
import DescriptionGenerator from "../autoGenerateDescription";
import Image from "../imageUpload/index";
import { PG_AMENITIES_LIST } from "constants/dbEnums";
import { ZONE_TYPE_TAGS } from "constants/dbEnums";
import { AUTO_TITLE_DESC_PROPERTY_TYPE } from "constants/dbEnums";
import { getDataFromLocalStorage } from "utils/common";
import CitySelectInput from "../location/citySelectInput";
import { LOCATION_TYPE } from "constants/dbEnums";

const plotForm = {
  possession_period: (props) => {
    const { onChange, formData, name, error } = props;
    const showMonth = formData?.possession_period?.period?.startsWith("by_");
    const handleChange = (e) => {
      const { name: selectedName, value } = e.target;
      onChange({
        target: {
          name,
          value: {
            ...formData.possession_period,
            [selectedName]: value,
          },
        },
      });
    };
    return (
      <Flex justify="space-between" mb={4}>
        <Box flex="1" mr={formData.ad_type === "sell" ? 2 : 0}>
          <SelectInput
            name="period"
            label="Expected Time pf Possession"
            options={POSSESSION_PERIOD}
            isRequired={true}
            onChange={(e) => handleChange(e)}
            error={error?.period}
            selectedValue={formData?.possession_period?.period}
          />
        </Box>
        {showMonth && (
          <Box flex="1" ml={2}>
            <SelectInput
              name="month"
              label="Select Month"
              options={MONTHS_TAGS}
              selectedValue={formData?.possession_period?.month}
              isRequired={true}
              onChange={(e) => handleChange(e)}
              error={error?.month}
            />
          </Box>
        )}
      </Flex>
    );
  },
  ownership_and_zone_type: (props) => {
    const { onChange, formData, error } = props;
    const isCommercialProperty = formData?.property_type === AUTO_TITLE_DESC_PROPERTY_TYPE.COMMERCIAL;
    const handleChange = (e) => {
      const { name: selectedName, value } = e.target;
      onChange({
        target: {
          name: selectedName,
          value,
        },
      });
    };
    return (
      <Flex justify="space-between" mb={4}>
        <Box flex="1" mr={!isCommercialProperty ? 2 : 0}>
          <SelectInput
            name="ownership"
            label="Ownership"
            options={OWNERSHIP_TAGS}
            onChange={(e) => handleChange(e)}
            isRequired={true}
            error={error?.ownership}
            selectedValue={formData?.ownership}
          />
        </Box>
        {isCommercialProperty && (
          <Box flex="1" ml={2}>
            <SelectInput
              name="zone_type"
              label="Zone Type"
              options={ZONE_TYPE_TAGS}
              selectedValue={formData?.zone_type}
              onChange={(e) => handleChange(e)}
              error={error?.zone_type}
            />
          </Box>
        )}
      </Flex>
    );
  },
  area: (props) => {
    const { onChange, formData, name: selectedName, error } = props;
    const handleChange = (e) => {
      const { name, value } = e.target;
      const updatedValue = {
        ...formData.area,
        [name]: value,
      };
      onChange({
        target: {
          name: selectedName,
          value: updatedValue,
        },
      });
      if (name === "unit") {
        const updatedValue = {
          ...formData?.dimension,
          [name]: DIMENSION_UNIT_TAGS[value],
        };
        onChange({
          target: {
            name: "dimension",
            value: updatedValue,
          },
        });
      }
    };
    return (
      <>
      <Flex>
        <div style={{ flex: 3 }}>
          <ValidatedInput
            name="size"
            placeholder="Enter plot area"
            type="number"
            onChange={(e) => handleChange(e)}
            label="Plot Area"
            isRequired={true}
            valueInNumber={true}
            error={error?.size}
            value={formData?.area?.size}
          />
        </div>
        <div style={{ flex: 2 }}>
          <SelectInput
            name="unit"
            label="Unit"
            options={AREA_UNIT}
            selectedValue={formData?.area?.unit}
            onChange={(e) => handleChange(e)}
            error={error?.unit}
            isRequired={true}
          />
        </div>
      </Flex>
      {typeof error === "string" && <ErrorMessage error={error} />}
      </>
    );
  },
  dimension: (props) => {
    const { formData, onChange, name } = props;
    const handleChange = (e) => {
      const { name: selectedName, value } = e.target;
      const updatedValue = {
        ...formData?.dimension,
        [selectedName]: value,
        unit: DIMENSION_UNIT_TAGS[formData?.area?.unit]
      };
      onChange({
        target: {
          name,
          value: updatedValue,
        },
      });
    };
    return (
      <>
        <Flex gap={4}>
          <Box flex={2}>
            <ValidatedInput
              name="length"
              placeholder="Length of the plot"
              type="number"
              label="Length"
              valueInNumber={true}
              onChange={(e) => handleChange(e)}
              rightElement={PER_AREA_UNIT[formData?.area?.unit]}
              value={formData?.dimension?.length}
            />
          </Box>
          <Box flex={2}>
            <ValidatedInput
              name="width"
              placeholder="Width of the plot"
              type="number"
              label="Width"
              valueInNumber={true}
              onChange={(e) => handleChange(e)}
              rightElement={PER_AREA_UNIT[formData?.area?.unit]}
              value={formData?.dimension?.width}
            />
          </Box>
        </Flex>
      </>
    );
  },
  width_of_facing_road: (props) => {
    const { onChange, formData, name: selectedName, error } = props;
    const handleChange = (e) => {
      const { name, value } = e.target;
      const updatedValue = {
        ...formData.width_of_facing_road,
        [name]: value,
      };
      onChange({
        target: {
          name: selectedName,
          value: updatedValue,
        },
      });
    };
    return (
      <>
      <Flex>
        <div style={{ flex: 3 }}>
          <ValidatedInput
            name="length"
            placeholder="Enter width of the road"
            type="number"
            onChange={(e) => handleChange(e)}
            label="Width of facing road"
            isRequired={true}
            valueInNumber={true}
            {...(typeof error !== "string" && { error: error?.length })}
            value={formData.width_of_facing_road?.length}
          />
        </div>
        <div style={{ flex: 2 }}>
          <SelectInput
            name="unit"
            label="Unit"
            options={DIMENSION}
            selectedValue={formData?.width_of_facing_road?.unit}
            onChange={(e) => handleChange(e)}
            error={error?.unit}
            isRequired={true}
          />
        </div>
      </Flex>
      {typeof error === "string" && <ErrorMessage error={error}/>}
      </>
    );
  },
  open_sides_and_facing: (props) => {
    const { onChange, error, formData } = props;
    const handleChange = (e) => {
      const { name, value } = e.target;
      onChange({
        target: {
          name,
          value,
        },
      });
    };
    return (
      <Flex gap={4}>
        <Box flex={2}>
          <SelectInput
            name="open_sides"
            label="No. of open sides"
            isRequired={true}
            options={NO_OF_OPEN_SIDES_TAGS}
            onChange={(e) => handleChange(e)}
            error={error?.open_sides}
            selectedValue={formData?.open_sides}
          />
        </Box>
        <Box flex={2}>
          <SelectInput
            name="facing"
            label="Property facing"
            isRequired={true}
            options={FACING}
            onChange={(e) => handleChange(e)}
            error={error?.facing}
            selectedValue={formData?.facing}
          />
        </Box>
      </Flex>
    );
  },
  any_construction_done_and_type_of_construction: (props) => {
    const { onChange, formData, error } = props;
    const showConstructionType = formData?.any_construction_done;
    const handleChange = (e) => {
      const { name: selectedName, value } = e.target;
      const updatedValue = value === "yes" ? true : false;
      onChange({
        target: {
          name: selectedName,
          value:
            selectedName === "any_construction_done" ? updatedValue : value,
        },
      });
    };
    return (
      <Flex gap={4}>
        <Box flex={3}>
          <CheckboxComponent
            options={any_construction_done}
            name="any_construction_done"
            onChange={(e) => handleChange(e)}
            label="Any Construction done on this property?"
            isRequired={true}
            textSize="lg"
            checkboxSize="lg"
            {...(formData?.any_construction_done !== undefined && {
              selectedValues: formData.any_construction_done ? "yes" : "no",
            })}
            error={error?.any_construction_done}
          />
        </Box>
        {showConstructionType && (
          <Box flex={2}>
            <SelectInput
              name="type_of_construction"
              label="Type of Construction"
              options={CONSTRUCTION_TYPE_OF_PLOT_TAGS}
              selectedValue={formData.type_of_construction}
              isRequired={true}
              onChange={(e) => handleChange(e)}
              error={error?.type_of_construction}
            />
          </Box>
        )}
      </Flex>
    );
  },
  boundary_wall_and_corner_plot: (props) => {
    const { onChange, error, formData } = props;
    const handlChange = (e) => {
      const { name, checked } = e.target;
      onChange({
        target: {
          name,
          value: checked,
        },
      });
    };
    return (
      <Flex gap={4}>
        <Box flex={2}>
          <CheckboxComponent
            options={boundary_wall}
            name="boundary_wall"
            onChange={(e) => handlChange(e)}
            label="Boundary Wall"
            textSize="lg"
            checkboxSize="lg"
            error={error?.boundary_wall}
            {...(formData?.boundary_wall && { selectedValues: "true" })}
          />
        </Box>
        <Box flex={2}>
          <CheckboxComponent
            options={CORNER_PLOT}
            name="corner_plot"
            onChange={(e) => handlChange(e)}
            label="Corner Plot"
            textSize="lg"
            checkboxSize="lg"
            error={error?.corner_plot}
            {...(formData?.corner_plot && { selectedValues: "true" })}
          />
        </Box>
      </Flex>
    );
  },
  approx_price: (props) => (
    <ValidatedInput
      name="approx_price"
      label="Expected Price in Rs (All inclusive price)"
      placeholder="Enter price in rs"
      type="number"
      isRequired={true}
      valueInNumber={true}
      error={props?.error}
      onChange={props?.onChange}
      value={props?.formData?.approx_price}
    />
  ),
  annual_dues_payable: (props) => (
    <ValidatedInput
      name="annual_dues_payable"
      placeholder="Enter annual dues"
      type="number"
      onChange={props.onChange}
      label="Annual dues payable (Rs)"
      valueInNumber={true}
      error={props.error}
      value={props?.formData?.annual_dues_payable}
    />
  ),
  booking_amount: (props) => (
    <ValidatedInput
      name="booking_amount"
      value={props?.formData?.booking_amount}
      placeholder="Enter booking amount"
      type="number"
      onChange={props.onChange}
      label="Booking Amount (Rs)"
      valueInNumber={true}
      error={props.error}
    />
  ),
  other_charges: (props) => (
    <ValidatedInput
      name="other_charges"
      value={props.formData?.other_charges}
      placeholder="Enter other charges"
      type="number"
      onChange={props.onChange}
      label="Other Charges (Rs)"
      valueInNumber={true}
    />
  ),
  negotiable: (props) => {
    const { formData, onChange } = props;
    const handleChange = (e) => {
      const { name, checked } = e.target;
      onChange({
        target: {
          name,
          value: checked,
        },
      });
    };
    return (
      <Flex gap={4}>
        <div style={{ flex: 2 }}>
          <CheckboxComponent
            options={is_price_negotiable}
            name="negotiable"
            onChange={(e) => handleChange(e)}
            label="Is price negotiable"
            textSize="lg"
            checkboxSize="lg"
            {...(formData?.negotiable && {selectedValues: "true"})}
          />
        </div>
        <div style={{ flex: 3 }}>
          <CheckboxComponent
            options={tax_and_govt_charges_excluded}
            name="tax_and_govt_charges_excluded"
            onChange={(e) => handleChange(e)}
            label="Tax and govt charges"
            textSize="lg"
            checkboxSize="lg"
            {...(formData?.tax_and_govt_charges_excluded && {selectedValues: "true"})}
          />
        </div>
      </Flex>
    );
  },
  allow_brokers_and_is_hot_deal: (props) => {
    const { onChange, formData } = props;
    const handlChange = (e) => {
      const { name, checked } = e.target;
      onChange({
        target: {
          name,
          value: checked,
        },
      });
    };
    return (
      <Flex gap={4}>
        <Box>
          <CheckboxComponent
            options={ALLOW_BROKERS}
            name="allow_brokers"
            onChange={(e) => handlChange(e)}
            label="Allow Brokers"
            textSize="lg"
            checkboxSize="lg"
            {...(formData?.allow_brokers && {selectedValues: "true"})}
          />
        </Box>
        <Box>
          <CheckboxComponent
            options={IS_HOT_DEAL}
            name="is_hot_deal"
            onChange={(e) => handlChange(e)}
            label="Hot Deal"
            textSize="lg"
            checkboxSize="lg"
            {...(formData?.is_hot_deal && {selectedValues: "true"})}
          />
        </Box>
      </Flex>
    );
  },
  discount: (props) => {
    const { onChange, formData, name: selectedName, error } = props;
    const handleChange = (e) => {
      const { name, value } = e.target;
      const updatedValue = {
        ...formData.discount,
        [name]: value,
      };
      onChange({
        target: {
          name: selectedName,
          value: updatedValue,
        },
      });
    };
    return (
      <>
        {formData.is_hot_deal && (
          <>
            <Flex>
              <div style={{ flex: 3 }}>
                <ValidatedInput
                  name="amount"
                  placeholder={
                    formData?.discount?.type === DISCOUNT_TYPE.PERCENTAGE
                      ? "Enter Percentage"
                      : "Enter Amount"
                  }
                  type="number"
                  onChange={(e) => handleChange(e)}
                  label="Please Specify the type of the discount"
                  valueInNumber={true}
                  isRequired={true}
                  error={error?.amount}
                  value={formData?.discount?.amount}
                />
              </div>
              <div style={{ flex: 2 }}>
                <SelectInput
                  name="type"
                  label="Type"
                  options={DISCOUNT_TYPE_TAGS}
                  onChange={(e) => handleChange(e)}
                  isRequired={true}
                  error={error?.type}
                  selectedValue={formData?.discount?.type}
                />
              </div>
            </Flex>
            {typeof error === "string" && <ErrorMessage error={error} />}
          </>
        )}
      </>
    );
  },
  discount_validity_and_financing_available: (props) => {
    const { formData, onChange, error } = props;
    const handleChange = (e) => {
      const { name, checked, value } = e.target;
      onChange({
        target: {
          name: name,
          value: name === "financing_available" ? checked : value,
        },
      });
    };
    return (
      <>
        {formData.is_hot_deal && (
          <Flex gap={4}>
            <Box flex={2}>
              <CalendarInput
                name="discount_validity"
                label="Discount Validity"
                value={formData?.discount_validity}
                onChange={(e) => handleChange(e)}
                isRequired={true}
                error={error?.discount_validity}
              />
            </Box>
            <Box flex={2}>
              <CheckboxComponent
                options={FINANCING_AVAILABLE}
                name="financing_available"
                onChange={(e) => handleChange(e)}
                label="Financing Available"
                textSize="lg"
                checkboxSize="lg"
                isRequired={true}
                error={error?.financing_available}
                {...(formData?.financing_available && {selectedValues: "true"})}
              />
            </Box>
          </Flex>
        )}
      </>
    );
  },
  location: (props) => {
    const { onChange, error, formData } = props;
    const selectedValue = formData.location.city;
    const handleChange = (e) => {
      const { value: selectedValue } = e.target;
      const updatedValue = {
        city: selectedValue.name
      };
      onChange({
        target: {
          name: "location",
          value: updatedValue,
        },
      });
    };
    return (
      <>
        <CitySelectInput
          name="city"
          label="Location"
          onChange={(e) => handleChange(e)}
          isRequired={true}
          error={error?.city}
          selectedValue={selectedValue}
          result_type={LOCATION_TYPE.CITY}
        />
        {typeof error === "string" && <ErrorMessage error={error}/>}
      </>
    );
  },
  locality: (props) => {
    const { onChange, formData, error } = props;
    const topCities = getDataFromLocalStorage('topCities');
    const selectedValue = topCities?.find((city) => city.name === formData?.location?.city);
    const handleChange = (e) => {
      const { name, value: selectedValue } = e.target;
      if (name === "locality") {
        const data = JSON.parse(selectedValue);
        onChange({
          target: {
            name: "location",
            value: data,
          },
        });
      } else {
        onChange({
          target: {
            name: "location",
            value: {
              ...formData.location,
              [name]: selectedValue,
            },
          },
        });
      }
    };
    return (
      <>
        <Flex gap={4}>
          <div stle={{ flex: 2 }}>
            <AutoSuggestComponent
              name="locality"
              selectedValue={formData?.location?.label}
              placeholder="Search by locality, area"
              onChange={(e) => handleChange(e)}
              label="Locality (Enter area or locality address)"
              isRequired={true}
              selectedCity={formData?.location?.city ? formData.location?.city : selectedValue?.city}
              error={error}
              location={formData.location}
              result_type={LOCATION_TYPE.LOCALITY}
            />
          </div>
          <div style={{ flex: 2 }}>
            <ValidatedInput
              name="building"
              placeholder="Enter society or area"
              type="text"
              onChange={(e) => handleChange(e)}
              label="Building (Enter appartment or area)"
              value={formData?.location?.building}
            />
          </div>
        </Flex>
      </>
    );
  },
  amenities: (props) => (
    <MultiSelect
      name="amenities"
      label="Select Amenities"
      options={PG_AMENITIES_LIST}
      displayValue="name"
      value={props.formData?._id ? props.formData?.amenities : []}
      onChange={props.onChange}
    />
  ),
  photos: (props) => {
    const { formData, onChange, error } = props;
    const handleChange = (e) => {
      const { name, value } = e.target;
      onChange({
        target: {
          name,
          value: value,
        },
      });
    };
    return (
      <>
        <Image
          onChange={(e) => handleChange(e)}
          name="photos"
          collectionType={formData.type}
          error={error}
          value={formData?._id ? formData?.photos : []}
        />
      </>
    );
  },
  description: (props) => {
    const { value, onChange, formData, error } = props;
    return (
      <DescriptionGenerator
        onChange={onChange}
        formData={formData}
        value={value}
        name="description"
        isRequired={true}
        label="Description"
        placeholder="Enter description or generate by AI"
        error={error}
      />
    );
  },
  slug_url: (props) => {
    const { onChange, formData, error } = props;
    const handleChange = (e) => {
      const { name, value } = e.target;
      onChange({
        target: {
          name,
          value,
        },
      });
    };
    return (
      <>
        {formData?._id && (
          <ValidatedInput
            name="slug_url"
            label="Slug Url"
            placeholder="Add Slug Url"
            type="text"
            isRequired={true}
            onChange={(e) => handleChange(e)}
            value={formData.slug_url}
            error={error}
          />
        )}
      </>
    );
  },
  meta_title: (props) => {
    const { onChange, formData, error } = props;
    const handleChange = (e) => {
      const { name, value } = e.target;
      onChange({ target: { name, value } });
    };
    return (
      <Flex justify="space-between" mb={4}>
        <Box flex="1" mr={2}>
          <ValidatedInput
            name="meta_title"
            label="Meta Title"
            placeholder="Project name"
            type="text"
            onChange={(e) => handleChange(e)}
            error={error}
            value={formData?.meta_title}
            styles={{ height: "60px" }}
          />
        </Box>
      </Flex>
    );
  },
  meta_description: (props) => {
    const { onChange, formData, error } = props;
    const handleChange = (e) => {
      const { name, value } = e.target;
      onChange({ target: { name, value } });
    };
    return (
      <Flex justify="space-between" mb={4}>
        <Box flex="1" mr={2}>
          <ValidatedInput
            name="meta_description"
            label="Meta Description"
            placeholder="Enter about"
            type="text"
            onChange={(e) => handleChange(e)}
            error={error}
            value={formData?.meta_description}
            styles={{ height: "60px" }}
          />
        </Box>
      </Flex>
    );
  },
};

export default plotForm;

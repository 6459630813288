import { showToast } from "components/toast/Toast";
import { BUILDER_FORM_TYPE_TAGS } from "constants/dbEnums";
import { AGENTS_LISTING_API } from "constants/endpoints";
import { BUILDERS_LISTING_API } from "constants/endpoints";
import { CREATE_COMPANY } from "constants/endpoints";

export const createForm = async ({ apiCall, payload, formType }) => {
  try {
    const url =
      formType === BUILDER_FORM_TYPE_TAGS.Company
        ? CREATE_COMPANY
        : formType === BUILDER_FORM_TYPE_TAGS.Agent ? AGENTS_LISTING_API : BUILDERS_LISTING_API;
        const method = payload?._id ? 'patch' : 'post';
    console.log(url, formType);
    const response = await apiCall[method](url, payload);
    if (response) {
      showToast({
        message: "Form has been successfully submitted",
        success: true,
        customStyle: {
          background: "#52c41a",
          color: "#fff",
          padding: "10px",
        },
      });
    }
    return response;
  } catch (error) {
    console.error("Error creating/updating builder form:", error);
    showToast({
      message: error?.response?.data?.error?.message
        ? error?.response?.data?.error?.message
        : error?.response?.data?.error,
      success: false,
      customStyle: {
        background: "#F24052",
        color: "#fff",
        padding: "10px",
      },
    });
    throw error?.response?.data?.error?.message
      ? error?.response?.data?.error?.message
      : error?.response?.data?.error;
  }
};

import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  HStack,
  VStack,
  Text,
  Menu,
  MenuButton,
  MenuList,
  MenuDivider,
  FormLabel
} from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import ErrorMessage from "./ErrorMessage";

const NumberInput = ({ label, value, onIncrement, onDecrement }) => (
  <HStack>
    <Text flex="1">{label}</Text>
    <Button size="sm" onClick={onDecrement}>
      -
    </Button>
    <Text>{value}</Text>
    <Button size="sm" onClick={onIncrement}>
      +
    </Button>
  </HStack>
);

const AmenitiesInput = ({ options, onChange, name, furnishType, isRequired, error, label, selectedValue }) => {
  const [amenities, setAmenities] = useState({});

  const increment = (key) => {
    setAmenities((prev)=>{
      const updatedAmenities = { ...prev, [key]: prev[key] + 1 };
      handleChange(updatedAmenities);
      return updatedAmenities;
    })
  };

  useEffect(() => {
    const initialAmenities = Object.keys(options).reduce((acc, key) => {
      acc[key] = selectedValue[key] || 0;
      return acc;
    }, {});
    setAmenities(initialAmenities);
  }, [options, selectedValue]);

  const decrement = (key) => {
    setAmenities((prev) => {
      const updatedAmenities = { ...prev, [key]: Math.max(prev[key] - 1, 0) };
      handleChange(updatedAmenities);
      return updatedAmenities;
    });
  };

  const handleChange = (amenities) => {
    const filteredAmenities = Object.keys(amenities)
      .filter((key) => amenities[key] > 0)
      .reduce((acc, key) => {
        acc[key] = amenities[key];
        return acc;
      }, {});

    const customEvent = {
      target: {
        name,
        value: filteredAmenities,
      },
    };
    onChange(customEvent);
  };

  const minimumSelectedItems = furnishType === "fully_furnished" ? 5 : 3;

  return (
    <Box p={4}>
      <FormLabel htmlFor={name}>
        {label}
        {isRequired && (
          <Box as="span" color="red.500" ml={1}>
            *
          </Box>
        )}
      </FormLabel>
      <Menu>
        <MenuButton
          as={Button}
          rightIcon={<ChevronDownIcon />}
          colorScheme="blue"
        >
          Select Amenities
        </MenuButton>
        <ErrorMessage error={error}/>
        <MenuList>
          <VStack spacing={4} p={4} align="start">
            <Text color="red.500" fontSize="sm" fontWeight="bold">Please select a minimum of {minimumSelectedItems} amenities.</Text>
            {Object.keys(options).map((key) => (
              <Box key={key} width="100%">
                <NumberInput
                  label={`No. of ${options[key]}`}
                  value={amenities[key]}
                  onIncrement={() => increment(key)}
                  onDecrement={() => decrement(key)}
                />
              </Box>
            ))}
          </VStack>
          <MenuDivider />
        </MenuList>
      </Menu>
    </Box>
  );
};

export default AmenitiesInput;

import React, { useCallback, useEffect, useMemo, useState } from 'react';

import {
  Badge,
  Box,
  Button,
  Flex,
  HStack,
  InputGroup,
  Text,
} from '@chakra-ui/react';

import { propertiesColumn } from 'views/admin/properties/variables';
import useApi from 'hooks/useApi';
import { PROPERTIES_LISTING_API } from 'constants/endpoints';
import { HttpStatusCode } from 'axios';

import {
  COMMERCIAL_PROPERTY_TYPE,
  TRANSACTION_TYPE_DATA,
  PROPERTY_STATUS,
  AD_TYPE_DATA,
  SELL_RESIDENTIAL_PROPERTY_TYPE,
  SORT_TYPES,
  SORT_DIRECTIONS,
  SELL_OR_RENT_TRANSACTION_TYPE_DATA,
  // COMMERCIAL_PROPERTY_TYPE,
} from 'constants/filterDropdownData';
import Pagination from 'components/pagination/Pagination';

import ErrorDisplay from 'components/errorDisplay/ErrorDisplay';
import { SearchBar } from 'components/navbar/searchBar/SearchBar';

import { showToast } from 'components/toast/Toast';
import { UPDATE_USER_AND_PROPERTY_API } from 'constants/endpoints';
import { OverlayLoaderComponent } from 'components/loader/Loader';

import SidebarFilter from 'components/sidebarFilter/SidebarFilter';
import { getFilterFromLocalStorage } from 'utils/common';
import { ENTITY_FILTER_TYPES } from 'utils/common';
import { setFilterToLocalStorage } from 'utils/common';

import ManageColumns from 'components/manageColumns/ManageColumns';
import NoDataFound from 'components/noDataFound/NoDataFound';
import { getOneMonthFromToday } from 'utils/common';
import { areAllStatusesSame } from 'utils/common';

import { transactionType as defaultTransactionType } from 'constants/defaultState';
import { ENTITY_COLUMNS_TYPES } from 'utils/common';

import { FiChevronsDown, FiFilter } from 'react-icons/fi';

import { formatDateToDDMMYYYY } from 'utils/common';
import CustomTable from 'components/table/CustomTable';
import { formatAdsTableData } from 'utils/common';

import Menu from 'components/menu/MainMenu';
import MultiSelectMenu from 'components/menu/MultiSelectMenu';
import { useHistory, useLocation } from 'react-router-dom';
import { getTopCities } from 'utils/common';

const PAGE_SIZE = 50;



const QUERY_PROPERTY_STATUS = 'property_status';

const sortableColumns = ['listing_score', 'created_at', 'expires_on'];

export default function Properties() {
  const { apiCall, loading = true, error } = useApi();

  const [selectedAction, setSelectedAction] = useState('');
  const [isOpenFilterSidebar, setIsOpenFilterSidebar] = useState(false);
  const history = useHistory();
  const [cityOptions, setCityOptions] = useState([]);
  const [selectedCity, setSelectedCity] = useState('');
 

  const [sortConfig, setSortConfig] = useState({
    sortType: 'created_at',
    sortDirection: 'descending',
  });

  const lsAdsDateRange = getFilterFromLocalStorage(
    ENTITY_FILTER_TYPES.ADS_DATE_RANGE
  );

  const [startDate, setStartDate] = useState(
    lsAdsDateRange ? lsAdsDateRange[0] : ""
  );
  const [endDate, setEndDate] = useState(
    lsAdsDateRange ? lsAdsDateRange[1] : ""
  );

  const lsFilters = getFilterFromLocalStorage(ENTITY_FILTER_TYPES.ADS_FILTER);
  const lsFiltersAdsPageSize = getFilterFromLocalStorage(
    ENTITY_FILTER_TYPES.ADS_PAGE_SIZE
  );

  const [pageSize, setPageSize] = useState(
    lsFiltersAdsPageSize ? lsFiltersAdsPageSize : PAGE_SIZE
  );

  const { search } = useLocation();

  const queryObject = Object.fromEntries(new URLSearchParams(search));

  const [searchText, setSearchText] = useState('');

  const [filters, setFilters] = useState(
    queryObject[QUERY_PROPERTY_STATUS]
      ? {
          adType: '',
          transactionType: '',
          propertyType: '',
          propertyStatus: queryObject[QUERY_PROPERTY_STATUS],
          sortType: 'created_at',
          sortDirection: 'descending',
        }
      : lsFilters
      ? lsFilters
      : {
          adType: '',
          transactionType: '',
          propertyType: '',
          propertyStatus: '',
          sortType: 'created_at',
          sortDirection: 'descending',
        }
  );

  const {
    adType = "",
    transactionType = "",
    propertyType = "",
    propertyStatus = "",
    sortType = "",
    sortDirection = "",
  } = filters || {};

  const [filteredData, setFilteredData] = useState(
    lsFilters ? Object.keys(lsFilters).filter((item) => filters[item]) : []
  );

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(10);
  const [propertiesData, setPropertiesData] = useState([]);
  const [filterRemoveFlagCount, setFilterRemoveFlagCount] = useState(0);
  const [totalCount, setTotalCount] = useState(0);

  const [selectedRowsData, setSelectedRowsData] = useState([]);

  const fetchPropertiesData = useCallback(
    async (text) => {
      let body = {
        ad_type: adType,
        collection_type: transactionType,
        property_type: propertyType,
        property_status: propertyStatus ? propertyStatus : '',
        sort_by:
          sortConfig?.sort_by === 'listing_score'
            ? 'total_listing_score'
            : sortConfig?.sort_by || '',
        sort_order: sortConfig?.sort_order || '',
        page: currentPage,
        limit: pageSize,
        search_text: text || searchText,
        start_date: formatDateToDDMMYYYY(startDate),
        end_date: formatDateToDDMMYYYY(endDate),
        city: selectedCity,
      };
      if (queryObject?.search_text){
        body = {
          search_text: queryObject?.search_text
        }
      }
      try {
        const res = await apiCall.get(`${PROPERTIES_LISTING_API}`, body);
        if (res?.statusCode === HttpStatusCode.Ok) {
          setPropertiesData(res?.data?.data);
          setTotalPages(Math.ceil(res?.data?.count / pageSize));
          setTotalCount(res?.data?.count);
        }
      } catch (error) {
        console.log('Error while fetching properties: ', error.message);
      }
    },
    [
      searchText,
      currentPage,
      adType,
      transactionType,
      propertyStatus,
      propertyType,
      sortType,
      sortDirection,
      pageSize,
      startDate,
      endDate,

      sortConfig,
      selectedCity,
    ]
  );

  useEffect(() => {
    const fetchCities = async () => {
      const topCities = await getTopCities();
      setCityOptions(topCities || []);
    };
    fetchCities();
  }, []);

  const handleFetchSearch = useCallback(
    (text) => {
      setCurrentPage(1);

      fetchPropertiesData(text);
    },
    [fetchPropertiesData]
  );

  const toggleSidebar = () => {
    setIsOpenFilterSidebar(!isOpenFilterSidebar);
  };

  useEffect(() => {
    if (queryObject?.search_text) {
      setSearchText(queryObject?.search_text);
      handleFetchSearch(queryObject?.search_text);
    } else {
      fetchPropertiesData();
      setFilterToLocalStorage(ENTITY_FILTER_TYPES.ADS_FILTER, filters);
    }
  }, [filterRemoveFlagCount, queryObject?.search_text, pageSize, currentPage,sortConfig]);

  const handleFilterChange = useCallback((key, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [key]: value,
    }));
    setCurrentPage(1);
  }, []);

  const handleApplyFilter = () => {
    const applyFilterArray = filters
      ? Object.keys(filters).filter((key) => filters[key])
      : [];

    setFilteredData(applyFilterArray);
    setFilterToLocalStorage(ENTITY_FILTER_TYPES.ADS_FILTER, filters);
    setCurrentPage(1);

    setSelectedRowsData([]);
    fetchPropertiesData();
  };

  const handleRemoveAllFilter = () => {
    setFilteredData([]);

    setFilters({
      adType: '',
      transactionType: '',
      propertyType: '',

      propertyStatus: '',
    });

    setEndDate('');
    setStartDate('');
    setSelectedCity('');
  };

  const handleUpdatePropertyStatus = async ({
    updatedPropertyType,
    slug,
  }) => {
    try {
      const data = propertiesData.find((item) => item.slug_url === slug);
      if(updatedPropertyType === "/admin/form"){
        history.push(`${updatedPropertyType}?id=${data._id}&type=${data?.collection_type}&property_type=${data?.property_type}`)
        return;
      }
      const {
        _id: id,
        collection_type,

        property_status,
      } = data;

      await apiCall.patch(UPDATE_USER_AND_PROPERTY_API, {
        updateData: [
          {
            type: collection_type,

            _id: id,

            property_status: updatedPropertyType,

            expires_on:
              property_status === 'expired' ? getOneMonthFromToday() : '',
          },
        ],
      });

      setFilterRemoveFlagCount((prev) => prev + 1);

      showToast({
        message: 'Property status updated successfully',
        customStyle: {
          background: 'green',
          color: '#fff',
          padding: '10px',
        },
      });
    } catch (error) {
      console.log(error);
    }
  };

  const dropdownFilters = [
    {
      name: 'propertyStatus',
      placeholder: 'Select Status',
      value: filters?.propertyStatus,
      data: PROPERTY_STATUS,
    },
    {
      name: 'adType',
      placeholder: 'Select Ad Type',
      value: filters?.adType,
      data: AD_TYPE_DATA,
    },
    {
      name: 'transactionType',
      placeholder: 'Select Transaction Type',
      value: filters?.transactionType,
      data:
        filters.adType === 'rent' || filters.adType === 'sell'
          ? SELL_OR_RENT_TRANSACTION_TYPE_DATA
          : TRANSACTION_TYPE_DATA,
    },

    {
      name: 'propertyType',
      placeholder: 'Select Property Type',
      value: filters.propertyType,

      data:
        filters?.transactionType === defaultTransactionType.Residential
          ? SELL_RESIDENTIAL_PROPERTY_TYPE
          : COMMERCIAL_PROPERTY_TYPE,
      isDisabled: !(
        filters?.transactionType === defaultTransactionType.Residential ||
        filters?.transactionType === defaultTransactionType.Commercial
      ),
    },
  ];

  const dropdownSorts = [
    {
      name: 'sortType',
      placeholder: '',
      value: sortType,
      data: SORT_TYPES,
    },
    {
      name: 'sortDirection',
      placeholder: '',
      value: sortDirection,
      data: SORT_DIRECTIONS,
    },
  ];

  const allFiltersProps = {
    dropdowns: dropdownFilters,
    sorts: dropdownSorts,
  };

  const columns = useMemo(() => propertiesColumn, [propertiesColumn]);
  const data = useMemo(
    () => formatAdsTableData(propertiesData),
    [propertiesData]
  );

  const lsLocalStorageManageColumns = getFilterFromLocalStorage(
    ENTITY_COLUMNS_TYPES.ADS_COLUMNS
  );

  const excludedColumnVisiblity = [
    'meta_title',
    'meta_description',
    'owner_email',
  ];

  const [columnVisibility, setColumnVisibility] = useState(
    lsLocalStorageManageColumns
      ? lsLocalStorageManageColumns
      : columns.reduce((acc, column) => {
          acc[column.accessor] = !excludedColumnVisiblity.includes(
            column.accessor
          );

          return acc;
        }, {})
  );



  const [manageColumnSelectḀ̥̥̥̥̥̥̥̥llCheckbox, setManageColumnSelectAllCheckbox] =
    useState(false);

  const handleColumnChange = (accessor) => {
    setColumnVisibility((prev) => ({
      ...prev,
      [accessor]: !prev[accessor],
    }));
  };

  useEffect(() => {
  
    setFilterToLocalStorage(ENTITY_COLUMNS_TYPES.ADS_COLUMNS, columnVisibility);
  }, [columnVisibility]);

  const visibleColumns = columns.filter(
    (column) => columnVisibility[column.accessor]
  );

  const handleInputFormSubmit = (e) => {
    e.preventDefault();

    setCurrentPage(1);
    fetchPropertiesData(searchText);
  };

  const filterBySlugUrl = (smallArray, fullArray) => {
    return fullArray.filter((fullItem) =>
      smallArray.some((smallItem) => smallItem.slug_url === fullItem.slug_url)
    );
  };

  const handleMultipleUpdateStatus = async ({ updatedStatus }) => {
    const statusArray = selectedRowsData?.map((item) => item?.property_status);

    if (!areAllStatusesSame(statusArray)) {
      showToast({
        message: 'All selected properties must have the same status',
        customStyle: {
          background: '#ff4d4f',
          color: '#fff',
          padding: '10px',
        },
      });
      return;
    }

    const filterdToBeUpdatedData = filterBySlugUrl(
      selectedRowsData,
      propertiesData
    );

    const updatedData = filterdToBeUpdatedData.map((item) => {
      return {
        type: item.collection_type,
        _id: item._id,

        property_status: updatedStatus,

        expires_on: statusArray[0] === 'Expired' ? getOneMonthFromToday() : '',
      };
    });

    try {
      await apiCall.patch(UPDATE_USER_AND_PROPERTY_API, {
        updateData: updatedData,
      });

      setFilterRemoveFlagCount((prev) => prev + 1);

      showToast({
        message: 'Property status updated successfully',
        customStyle: {
          background: 'green',
          color: '#fff',
          padding: '10px',
        },
      });

      setSelectedRowsData([]);
    } catch (error) {}
  };

  // SELECT ROW LOGIC
  const handleRowSelect = useCallback(
    (row) => {
      const findData = selectedRowsData.find(
        (item) => item.slug_url === row.slug_url
      );

      setSelectedRowsData((prev) => {
        if (findData) {
          return prev.filter((item) => item.slug_url !== findData.slug_url);
        } else {
          return [...prev, row];
        }
      });
    },
    [setSelectedRowsData, selectedRowsData]
  );

  const selectedRowsSlug = selectedRowsData.map((item) => item.slug_url);

  const selectedData = useMemo(
    () =>
      selectedRowsSlug.map((slug) => data.find((row) => row.slug_url === slug)),
    [selectedRowsSlug, data]
  );

  if (error) {
    return <ErrorDisplay error={error} />;
  }

  return (
    <Box
      height="100vh"
      bg="#F8FAFB"
      // pt={{ base: "40px", md: "20px", xl: "20px" }}
    >
      <Flex
        justify="space-between"
        align="center"
        p="2"
        // bg={useColorModeValue("gray.50", "gray.700")}
        boxShadow="sm"
        borderRadius="sm"
        // width="100vw - 96px"
        pos="fixed"
        style={{ width: 'calc(100vw - 96px)' }}
        zIndex={1000}
      >
        <HStack spacing="4" p={2}>
          <Box position="relative" display="inline-block">
            <Button
              paddingX={6}
              leftIcon={<FiFilter />}
              rightIcon={<FiChevronsDown />} // Changed the icon to ChevronDown
              colorScheme="teal"
              variant="outline"
              borderRadius="md"
              size="sm"
              onClick={() => {
                toggleSidebar();
                setFilters(lsFilters);
              }}
            >
              Filter
            </Button>
            {filteredData.length > 0 && (
              <Badge
                position="absolute"
                top="-3"
                right="0"
                colorScheme="teal"
                borderRadius="full"
                px={2}
                fontSize="0.8em"
              >
                {filteredData.length}
              </Badge>
            )}
          </Box>

          <ManageColumns
            columns={columns}
            handleColumnChange={handleColumnChange}
            columnVisibility={columnVisibility}
            setManageColumnSelectAllCheckbox={setManageColumnSelectAllCheckbox}
            manageColumnSelectḀ̥̥̥̥̥̥̥̥llCheckbox={manageColumnSelectḀ̥̥̥̥̥̥̥̥llCheckbox}
          />

          <form onSubmit={handleInputFormSubmit}>
            <InputGroup size="sm">
              <SearchBar
                background="#F9F9FB"
                color="#637381"
                setSearchText={(e) => setSearchText(e.target.value)}
                searchText={searchText}
                borderRadius="5px"
                borderWidth="0.5px"
                borderColor="#637381"
              />
            </InputGroup>
          </form>
        </HStack>
        <Text
          borderRadius="5px"
          borderWidth="0.5px"
          borderColor="#637381"
          background="#F9F9FB"
          color="#637381"
          p={1}
          fontWeight={'bold'}
        >
          Total Ads: {totalCount}
        </Text>
        <Button
            borderRadius="5px"
            borderColor="#637381"
            background={'linear-gradient(180deg, #22ACEE 0%, #0177B4 100%)'}
            color="#ffffff"
            fontSize="sm"
            colorScheme="teal"
            onClick={() => {
              history.push('/admin/form');
            }}
          >
            Create Ads
          </Button>
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={(value) => {
            setSelectedRowsData([]);
            setCurrentPage(value);
          }}
          totalCount={totalCount}
          setCurrentPage={setCurrentPage}
          setPageSize={setPageSize}
          pageSize={pageSize}
          setPageSizeToLocalStorage={(value) =>
            setFilterToLocalStorage(ENTITY_FILTER_TYPES.ADS_PAGE_SIZE, value)
          }
        />
      </Flex>

      <SidebarFilter
        filters={allFiltersProps}
        handleFilterChange={handleFilterChange}
        handleApplyFilter={handleApplyFilter}
        isOpen={isOpenFilterSidebar}
        onClose={toggleSidebar}
        appliedFilters={filters}
        setFilters={setFilters}
        lsFilters={lsFilters}
        handleRemoveAllFilter={handleRemoveAllFilter}
        // onFilterDateRangeSubmit={onFilterDateRangeSubmit}
        //date filter
        isCalenderFilter={true}
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
        ////location filter
        locationCitiesFilter={cityOptions}
        selectedCity={selectedCity}
        setSelectedCity={setSelectedCity}
      />
      {propertiesData.length !== 0 ? (
        <>
          <CustomTable
            columns={visibleColumns}
            data={data}
            onSelectedAction={setSelectedAction}
            selectedRowsData={selectedRowsData}
            selectedRowsSlug={selectedRowsSlug}
            selectedData={selectedData}
            handleRowSelect={handleRowSelect}
            setSelectedRowsData={setSelectedRowsData}
            //sorting props
            sortableColumns={sortableColumns}
            sortConfig={sortConfig}
            setSortConfig={setSortConfig}
            // single menu props
            menuComponent={
              <Menu
                data={Object.values(PROPERTY_STATUS)}
                onUpdate={() => {}}
                onUpdatePropertyStatus={handleUpdatePropertyStatus}
                selectedAction={selectedAction}
              />
            }
            // multi select menu props
            multiSelectMenuComponent={
              selectedRowsSlug?.length > 0 &&
              areAllStatusesSame(
                selectedData.map((item) => item?.property_status)
              ) && (
                <MultiSelectMenu
                  multiSelectCurrentValue={selectedData[0]?.property_status}
                  onMultipleUpdateStatus={handleMultipleUpdateStatus}
                  selectedAction={selectedAction}
                  selectedRowsData={selectedRowsData}
                  bgColor="#B2F5EA"
                />
              )
            }
          />
        </>
      ) : !loading ? (
        <NoDataFound />
      ) : null}
      <OverlayLoaderComponent isOpen={loading} />
    </Box>
  );
}

//type  					total  		active 			pending 		expired    deleted        rejected
export const columnDataTotalAds = [
  {
    Header: "TYPE",
    accessor: "type",
  },
  {
    Header: "TOTAL",
    accessor: "total",
  },
  {
    Header: "ACTIVE",
    accessor: "active",
  },
  {
    Header: "PENDING",
    accessor: "pending",
  },
  {
    Header: "INACTIVE",
    accessor: "inactive",
  },
  {
    Header: "EXPIRED",
    accessor: "expired",
  },
  {
    Header: "DELETED",
    accessor: "deleted",
  },
  {
    Header: "REJECTED",
    accessor: "rejected",
  },
];

export const columnsDataActiveAds = [
  {
    Header: "TYPE",
    accessor: "type",
  },
  {
    Header: "RENT",
    accessor: "rent",
  },
  {
    Header: "SELL",
    accessor: "sell",
  },
  {
    Header: "TOTAL ADS",
    accessor: "total_ads",
  },
];


export const columnsDataUserStatus = [
  {
    Header: "User Type",
    accessor: "user_type",
  },
  {
    Header: "Active",
    accessor: "active",
  },
  {
    Header: "Inactive",
    accessor: "inactive",
  },
  {
    Header: "Pending",
    accessor: "pending_verification",
  },
  {
    Header: "Rejected",
    accessor: "rejected",
  },
];

import React from "react";
import { Box, SimpleGrid } from "@chakra-ui/react";
import dynamicForm from "./FieldConfig/index.js";

const GenericForm = ({ formType, formData, onChange, errors, setErrors }) => {
  const fields = dynamicForm[formType] || {};

  return (
    <SimpleGrid columns={{ base: 1, md: 2 }} spacing={4}>
      {Object.entries(fields).map(([name, renderField]) => (
        <Box key={name}>
          {renderField({
            name,
            value: formData[name] || "",
            onChange: (name, value) => onChange(name, value),
            formData: formData,
            error: errors[name],
            setErrors: setErrors
          })}
        </Box>
      ))}
    </SimpleGrid>
  );
};

export default GenericForm;

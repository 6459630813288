import {
  Flex,
  Stat,
  StatLabel,
  StatNumber,
  useColorModeValue,
  Text,
} from "@chakra-ui/react";

import { Link } from "react-router-dom";

// Custom components
import Card from "components/card/Card.js";
// Custom icons
import React from "react";

export default function Default(props) {
  const {
    categoryName,
    stats,
  } = props;
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "secondaryGray.600";

  return (
    <Link to={props.link}>
      
      <Card
        px='0px'
        py='4px'
        borderRadius="lg"
        boxShadow="md"
        _hover={{ boxShadow: "lg", transform: "scale(1.02)" }}
        transition="all 0.2s"
        minW="max-content"
        
      >
        <Text
          fontSize="base"
          fontWeight={"bold"}
          textAlign="center"
          color={textColor}
        >
          {categoryName}
        </Text>
        <Flex
          direction="row"
          align="center"
          justify="center"
          w="100%"
          wrap="wrap"
        >
          {stats.map((stat) => (
            <Link
              key={stat.name}
              to={stat.link}
              style={{ width: "auto", margin: "0 10px" }}
            >
              <Flex
                direction="column"
                align="center"
                justify="center"
                borderRadius="lg"
                
              >
                <Stat my="auto">
                  <StatLabel
                    lineHeight="100%"
                    color={stat.chipColor.textSecondary}
                    fontSize={{ base: "xs" }}
                  >
                    {stat.name}
                  </StatLabel>
                  <StatNumber
                    color={stat.chipColor.text}
                    fontSize={{ base: "16px" }}
                    textAlign={"center"}
                  >
                    {stat.value}
                  </StatNumber>
                </Stat>
              </Flex>
            </Link>
          ))}
        </Flex>
      </Card>
    </Link>
  );
}

import React from "react";
import { Box, Text } from "@chakra-ui/react";

const ErrorMessage = ({ error }) => {
  const renderErrors = (error) => {
    if (typeof error === "string") {
      return (
        <Text color="red.500" fontSize="sm" key="error-string">
          {error}
        </Text>
      );
    }

    if (typeof error === "object" && error !== null) {
      return Object.values(error).map((err, index) => {
        if (typeof err === "object" && err !== null) {
          return Object.values(err).map((nestedError, nestedIndex) => (
            <Text color="red.500" fontSize="sm" key={`${index}-${nestedIndex}`}>
              {nestedError}
            </Text>
          ));
        }

        return (
          <Text color="red.500" fontSize="sm" key={index}>
            {err}
          </Text>
        );
      });
    }
    return null;
  };
  return <Box mt={2}>{renderErrors(error)}</Box>;
};

export default ErrorMessage;

export const initialState = {
  PG: {
    pg_name: "",
    preferred_tenant: "",
    suitable_for: [],
    total_floors: "",
    room_sharing_type: [],
    capacity_and_availability: {},
    furnish_type: "",
    covered_parking: 0,
    open_parking: 0,
    property_managed_by: "",
    manager_stays_at_pg: "",
    pricing: "",
    maintenance_charges: 0,
    booking_amount: 0,
    other_charges: 0,
    negotiable: false,
    is_electricity_and_water_charge: false,
    included_services: [],
    meals_available: "",
    meal_type: "",
    meal_on_weekends: [],
    meal_on_weekdays: [],
    notice_period: 0,
    lock_in_period: {},
    location: {},
    amenities: [],
    pg_rules: [],
    common_areas: [],
    photos: [],
    description: "",
    gate_timing: "",
  },
  commercial_property: {
    ad_type: "",
    construction_status: "",
    area: { unit: "square_feet" },
  },
  residential_property: {
    ad_type: "",
    bathrooms: "",
    balconies: "",
    additional_rooms: [],
    location: {},
    photos: [],
    area: { unit: "square_feet" },
  },
  plot: {
    possession_period: { period: "", month: "" },
    area: { unit: "square_feet" },
    width_of_facing_road: { unit: "feet" },
    location: {},
    boundary_wall: false,
    corner_plot: false,
    is_hot_deal: false,
    dimension: {},
    financing_available: false
  },
  projects: {
  },
};

import { decode } from "base64-arraybuffer";
import { COMPANY_CERTIFICATE_UPLOAD_API } from "constants/endpoints";
import { COMPANY_IMAGE_UPLOAD_API } from "constants/endpoints";

export const handlePhotoUpload = async ({
  apiCall,
  selectedImages,
  formData,
  url,
  is_gst_certificate,
  photoUpload
}) => {
  try {
    const filePhotos = selectedImages?.filter((photo) => photo instanceof File);
    const [photoResponse] = await Promise.all([
      handlePhotoSignedURL({
        apiCall,
        photos: filePhotos,
        formData,
        url,
        is_gst_certificate,
        photoUpload
      }),
    ]);
    return photoResponse?.file;
  } catch (error) {
    console.error("Error submitting project data:", error);
    throw error;
  } finally {
  }
};

export const handlePhotoSignedURL = async ({
  apiCall,
  photos,
  formData,
  url,
  is_gst_certificate,
  photoUpload
}) => {
  try {
    if (photos?.length === 0) {
      return null;
    }
    const response = await getAdsSignedInURL({
      apiCall,
      formData,
      url,
      is_gst_certificate,
      photoUpload
    });
    const pdfContentType = "application/pdf";
    if (photos[0].type === pdfContentType) {
      const dataUrl = await handleValidFile(photos[0]);
      if (typeof dataUrl === "string") {
        const base64Image = dataUrl;
        await UploadToS3(
          response.signedUrl[0],
          base64Image,
          {},
          pdfContentType
        );
      } else {
        console.error("Invalid dataUrl format:", dataUrl);
      }
    } else {
      await handleUploadToS3(response, photos);
    }
    return response;
  } catch (error) {
    console.error("Error fetching photo signed URLs:", error);
    throw error;
  }
};

export const handleUploadToS3 = async (response, files) => {
  try {
    for (let i = 0; i < response.signedUrl.length; i++) {
      const signedUrl = response.signedUrl[i];
      const file = files[i];

      const dataUrl = await handleValidFile(file);
      if (typeof dataUrl === "string") {
        const base64Image = dataUrl.split(",")[1];
        await UploadToS3(signedUrl, base64Image);
      } else {
        console.error("Invalid dataUrl format:", dataUrl);
      }
    }
  } catch (error) {
    console.error("Error uploading image:", error);
    throw error;
  }
};

export const getAdsSignedInURL = async ({
  apiCall,
  formData,
  url,
  is_gst_certificate=false,
  photoUpload=false
}) => {
  try {
    let payload;
    if(photoUpload){
      payload = {
        name: formData?.name,
        role: 'builder'
      }
    } else {
      payload = {
        company_name: formData?.name,
        is_gst_certificate: is_gst_certificate
      }
    }
    const response = await apiCall.post(url, payload);
    if (response.data) {
      if(photoUpload && response.data.signedUrl && response.data.file){
        return {
          signedUrl: [response.data.signedUrl],
          file: [response.data.file],
        };
      } else if(response.data.signedUrls && response.data.filePath) {
        return {
          signedUrl: response.data.signedUrls,
          file: response.data.filePath,
        };
      } else {
        return {};
      }
    } else {
      throw new Error("Invalid response format");
    }
  } catch (error) {
    console.error("Error fetching signed URL:", error);
    throw new Error("Invalid response format", error);
  }
};

export const handleValidFile = async (file) => {
  if (
    file &&
    (file?.type?.startsWith("image/") || file?.type === "application/pdf")
  ) {
    return readFileAsDataURL(file);
  }
  return null;
};

export const UploadToS3 = async (
  signedURL,
  file,
  headers = {},
  contentType = "image/jpg"
) => {
  const response = await fetch(signedURL, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": contentType,
      ...headers,
    },
    body: decode(file),
  });

  if (!response.ok) {
    return response.text().then((error) => {
      console.log("ERROR", error);
      throw new Error(error);
    });
  }
};

export const readFileAsDataURL = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
    reader.readAsDataURL(file);
  });
};

export const applyUploadedImagesToCompanyData = async ({
  apiCall,
  formData
}) => {
  try {
    const uploadPromises = [];
    let reraCertificate = null;
    if (Array.isArray(formData.rera_certificate)) {
      reraCertificate = handlePhotoUpload({
        apiCall,
        selectedImages: formData.rera_certificate,
        formData,
        url: COMPANY_CERTIFICATE_UPLOAD_API
      });
      uploadPromises.push(reraCertificate);
    }
    let gstCertificate = null;
    if (Array.isArray(formData.gst_certificate)) {
      gstCertificate = handlePhotoUpload({
        apiCall,
        selectedImages: formData.gst_certificate,
        formData,
        url: COMPANY_CERTIFICATE_UPLOAD_API,
        is_gst_certificate: true
      });
      uploadPromises.push(gstCertificate);
    }
    let photo = null;
    if (Array.isArray(formData.photo)) {
      photo = handlePhotoUpload({
        apiCall,
        selectedImages: formData.photo,
        formData,
        url: COMPANY_IMAGE_UPLOAD_API,
        photoUpload: true
      });
      uploadPromises.push(photo);
    }
    const [reraCertificateResult, gstCertificateResult, photoResult] = await Promise.all([
      reraCertificate,
      gstCertificate,
      photo
    ]);
    const updatedFormData = {
      ...formData,
      ...(reraCertificate && { rera_certificate: reraCertificateResult[0] }),
      ...(gstCertificate && { gst_certificate: gstCertificateResult[0] }),
      ...(photo && { photo: photoResult[0] }),
    };
    return updatedFormData;
  } catch (error) {
    console.error("Error updating form data with uploads:", error);
    throw error;
  }
};

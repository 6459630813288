import { decode } from "base64-arraybuffer";
import { PLOT_PROPERTY_TAGS_ARRAY } from "constants/dbEnums";
import { ALL_PROPERTY_TYPE_TAGS } from "constants/dbEnums";
import { AD_TYPE_TAGS } from "constants/dbEnums";
import { PROPERTY_COLLECTIONS } from "constants/dbEnums";
import { COMMERCIAL_PROPERTY_ARRAY } from "constants/dbEnums";
import { RESIDENTIAL_PROPERTY_ARRAY } from "constants/dbEnums";
import { UPLOAD_SIGNED_PROJECTS_URL_API } from "constants/endpoints";
import { ADS_UPLOAD_SIGNED_URL_API } from "constants/endpoints";

export const handlePhotoUpload = async ({
  apiCall,
  selectedImages,
  collectionType,
  formData,
  floor_plan,
  uploadProjectPhotos,
  bhk_type,
  area,
  brochure,
  icon
}) => {
  try {
    const filePhotos = selectedImages?.filter((photo) => photo instanceof File);
    const [photoResponse] = await Promise.all([
      handlePhotoSignedURL({ apiCall, photos: filePhotos, collectionType, formData, floor_plan, uploadProjectPhotos, bhk_type, area, brochure, icon }),
    ]);
    return photoResponse?.file || [];
  } catch (error) {
    console.error("Error submitting project data:", error);
    throw error;
  } finally {
  }
};

export const handlePhotoSignedURL = async ({
  apiCall,
  photos,
  collectionType,
  formData,
  floor_plan,
  uploadProjectPhotos,
  bhk_type,
  area,
  brochure,
  icon
}) => {
  try {
    if (photos?.length === 0) {
      return null;
    }
    const response = await getAdsSignedInURL({
      apiCall,
      photos,
      collectionType,
      formData,
      floor_plan,
      uploadProjectPhotos,
      bhk_type,
      area,
      brochure,
      icon
    });
    const pdfContentType = 'application/pdf';

    if (photos[0].type === pdfContentType) {
      const dataUrl = await handleValidFile(photos[0]);
      if (typeof dataUrl === "string") {
        const base64Image = dataUrl.split(",")[1];
        await UploadToS3(response.signedUrl[0], base64Image, {}, pdfContentType);
      } else {
        console.error("Invalid dataUrl format:", dataUrl);
      }
    } else {
      await handleUploadToS3(response, photos);
    }
    return response;
  } catch (error) {
    console.error("Error fetching photo signed URLs:", error);
    throw error;
  }
};

export const handleUploadToS3 = async (response, files) => {
  try {
    for (let i = 0; i < response.signedUrl.length; i++) {
      const signedUrl = response.signedUrl[i];
      const file = files[i];

      const dataUrl = await handleValidFile(file);
      if (typeof dataUrl === "string") {
        const base64Image = dataUrl.split(",")[1];
        await UploadToS3(signedUrl, base64Image);
      } else {
        console.error("Invalid dataUrl format:", dataUrl);
      }
    }
  } catch (error) {
    console.error("Error uploading image:", error);
    throw error;
  }
};

export const getAdsSignedInURL = async ({
  apiCall,
  photos,
  collectionType,
  formData,
  floor_plan,
  uploadProjectPhotos,
  bhk_type,
  area,
  brochure,
  icon
}) => {
  try {
    let payload = {};
    if(collectionType === ALL_PROPERTY_TYPE_TAGS.projects || uploadProjectPhotos){
      const isAreaMissing = !area || !area.size || !area.unit;
      if(floor_plan && !bhk_type && !isAreaMissing){
        return null;
      }
      const floorPlanArray = formData?.floor_plan_and_pricing?.flatMap(item => {
      return Array.isArray(item?.image) ? { bhk: item.bhk, area: item.area } : [];
    });
      payload = {
        ...(icon ? { icon: true } : ""),
        ...(brochure ? { brochure: true } : ""),
        ...(floor_plan ? {floor_plan } : ""),
        ...(floor_plan ? {floor_plan_array: floorPlanArray } : ""),
        ...(uploadProjectPhotos ? {count: photos?.length} : ""),
        builder_name: formData?.company_name,
        project_name: formData?.name,
      };
    } else {
      payload = {
        collection_type: ALL_PROPERTY_TYPE_TAGS[collectionType],
        count: photos?.length,
      };
    }
    const url = (collectionType === ALL_PROPERTY_TYPE_TAGS.projects || uploadProjectPhotos) ? UPLOAD_SIGNED_PROJECTS_URL_API : ADS_UPLOAD_SIGNED_URL_API;
    const response = await apiCall.post(url, payload);
    if (response.data && response.data.signedUrls && response.data.filePath) {
      return {
        signedUrl: response.data.signedUrls,
        file: response.data.filePath,
      };
    } else {
      throw new Error("Invalid response format");
    }
  } catch (error) {
    console.error("Error fetching signed URL:", error);
    throw new Error("Invalid response format", error);
  }
};

export const handleValidFile = async (file) => {
  if (
    file &&
    (file?.type?.startsWith("image/") || file?.type === "application/pdf")
  ) {
    return readFileAsDataURL(file);
  }
  return null;
};

export const UploadToS3 = async (
  signedURL,
  file,
  headers = {},
  contentType = "image/jpg"
) => {
  const response = await fetch(signedURL, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": contentType,
      ...headers,
    },
    body: decode(file),
  });

  if (!response.ok) {
    return response.text().then((error) => {
      console.log("ERROR", error);
      throw new Error(error);
    });
  }
};

export const readFileAsDataURL = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
    reader.readAsDataURL(file);
  });
};

export const filterPgData = (data, sharing_type) => {
  const updatedData= {};
  sharing_type.map((type) => {
    updatedData[type] = data[type];
  });
  return updatedData;
};

export const getTagsForSelectedTypes = (selectedTypes) => {
  let tags = [];
  if (selectedTypes?.includes("residential_property")) {
    tags = tags?.concat(RESIDENTIAL_PROPERTY_ARRAY);
  }

  if (selectedTypes?.includes("commercial_property")) {
    tags = tags?.concat(COMMERCIAL_PROPERTY_ARRAY);
  }

  if (selectedTypes?.includes("plot")) {
    tags = tags?.concat(PLOT_PROPERTY_TAGS_ARRAY);
  }
  const uniqueTags = tags?.reduce((acc, tag) => {
    if (!acc.find((t) => t.id === tag.id)) {
      acc.push(tag);
    }
    return acc;
  }, []);

  return uniqueTags;
};

function updateFloorPlanAndPricingWithImages(formData, floorPlansImageUploadedResult) {
  const usedImages = new Set();
  formData.floor_plan_and_pricing = formData.floor_plan_and_pricing.map(item => {
    if (!item.image) return item; 
    const matchingImage = floorPlansImageUploadedResult.find(imageUrl => {
      if (usedImages.has(imageUrl)) return false;

      const imageUrlSegments = imageUrl.split('/').map(segment => segment.toLowerCase());
      const bhkTypeMatch = item.bhk && imageUrlSegments.some(segment => segment.includes(item.bhk.toLowerCase().replace(' ', '')));
      const areaSizeMatch = item.area?.size && imageUrlSegments.some(segment => segment.includes(item.area.size.toLowerCase()));
      const areaUnitMatch = item.area?.unit && imageUrlSegments.some(segment => segment.includes(item.area.unit.toLowerCase().replace('_', '-')));
      if (bhkTypeMatch || areaSizeMatch || areaUnitMatch) {
        usedImages.add(imageUrl);
        return true;
      }
      return false;
    });
    return matchingImage ? { ...item, image: matchingImage } : item;
  });
}

export const updateFormDataWithUploads = async ({ apiCall, propertyType, formData, s3Imgs }) => {
  try {
    const uploadPromises = [];
    let brochureUpload = null;
    if (Array.isArray(formData.brochure)) {
      brochureUpload =  handlePhotoUpload({
        apiCall,
        selectedImages: formData.brochure,
        collectionType: propertyType,
        formData,
        brochure: true
      })
      uploadPromises.push(brochureUpload);
    }
    let iconUpload = null;
    if (Array.isArray(formData.icon)) {
      iconUpload =  handlePhotoUpload({
        apiCall,
        selectedImages: formData.icon,
        collectionType: propertyType,
        formData,
        icon: true
      })
      uploadPromises.push(iconUpload);
    }
    const imageArray = formData?.floor_plan_and_pricing?.flatMap(item => 
      Array.isArray(item?.image) ? item.image : []
    );    
    let floorPlansImageUpload=null;
    if(imageArray?.length>0){
      floorPlansImageUpload = handlePhotoUpload({
        apiCall,
        selectedImages: imageArray,
        collectionType: propertyType,
        formData,
        floor_plan: true
      });
      uploadPromises.push(floorPlansImageUpload);
    }
      const [brochureUploadResult, iconUploadResult, floorPlansImageUploadedResult] = await Promise.all([
        brochureUpload,
        iconUpload,
        floorPlansImageUpload,
      ]);
      if (floorPlansImageUploadedResult?.length) {
        updateFloorPlanAndPricingWithImages(formData, floorPlansImageUploadedResult);
      }
    const updatedFormData = {
      ...formData,
      photos: s3Imgs,
      ...(iconUpload && { icon: iconUploadResult[0] }),
      ...(brochureUpload && { brochure: brochureUploadResult[0] }),
      ...(imageArray?.length > 0 && {
        floor_plan_and_pricing: formData?.floor_plan_and_pricing,
      }),
    };
    return updatedFormData;
  } catch (error) {
      console.error("Error updating form data with uploads:", error);
      throw error;
  }
};

export const filterEmptyValues = (data) => {
  return data.map(item => {
    const filteredItem = Object.fromEntries(
      Object.entries(item).filter(([key, value]) => value !== "")
    );
    return filteredItem;
  });
};

export const filterUnusedData = (data, propertyType) => {
  if (data.ad_type === 'sell' && data._id) {
    switch (propertyType) {
      case 'retail_shop':
        delete data.is_on_lease;
        break;
      case 'warehouse':
        delete data.is_on_lease;
          break;  
      case 'commercial_other':
        delete data.is_on_lease;
          break;  
      default:
        break;
    }
  }
  const locationKey = data.address ? 'address' : data.location ? 'location' : null;
  if(locationKey && data[locationKey]){
    const filteredLocation = Object.fromEntries(
      Object.entries(data[locationKey]).filter(([key, value]) => value !== "")
    );
    data[locationKey] = filteredLocation;
  }
  switch (data?.type) {
    case ALL_PROPERTY_TYPE_TAGS.residential_plot:
    case ALL_PROPERTY_TYPE_TAGS.commercial_plot:
      if (data?.is_hot_deal === false) {
        delete data.financing_available;
        delete data.discount;
        delete data.discount_validity;
      }
      break;
      case PROPERTY_COLLECTIONS.COMMERCIAL_PROPERTY:
        if (data.ad_type === AD_TYPE_TAGS.SELL) {
          delete data.is_on_lease;
          delete data.is_electricity_and_water_charge;
        }
        break; 
    default:
      break;
  }
  return data;
};



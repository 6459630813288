// chakra imports
import { Box, Flex, Stack } from "@chakra-ui/react";
//   Custom components
import Brand from "components/sidebar/components/Brand";
import Links from "components/sidebar/components/Links";

import CustomTooltip from "components/tooltip/CustomTooltip";
import { useAuth } from "contexts/AuthContext";
import { useModal } from "contexts/ModalContext";
import React, { useMemo } from "react";
import { CiLogout } from "react-icons/ci";
// FUNCTIONS

function SidebarContent(props) {
  const { routes } = props;

  const { openModal } = useModal();
  const { logout } = useAuth();
  const handleLogout = () => {
    openModal({
      title: 'Log Out',
      bodyText: `Are you sure you want to <span style="font-weight: bold;">logout</span>?`,
      actions: [
        {
          label: 'Log Out',
          colorScheme: 'red',
          onClick: () => {
            logout();
          },
        },
      ],
    });
  };

  const filteredRoutes = useMemo(() => {
    const excludeRoutes = ["/sign-in", '/form', '/users/:userId', '/builder'];
    const role = localStorage.getItem('role');
    const excludeRoutesList = routes.filter((item) =>
      excludeRoutes.includes(item.path) ? false : true
    );
    if (role) {
      return excludeRoutesList.filter((routes) =>
        routes?.authorized?.includes(role)
      );
    } else {
      return excludeRoutesList;
    }
  }, [routes]);

  return (
    <Flex
      direction="column"
      height="100%"
      // pt="25px"
      // px="16px"
      // borderRadius="30px"
      // bg={"teal"}

      p={0}
      m={0}
    >
      <Brand />
      <Stack direction="column" mb="auto" mt="8px">
        <Box
        // ps="20px" pe={{ md: "16px", "2xl": "1px" }}
        >
          <Links routes={filteredRoutes} />
        </Box>
      </Stack>

      {/* Logout */}
      <CustomTooltip dataLabel={'Logout'}>
        <CiLogout
          size={'24px'}
          fontWeight={'bold'}
          onClick={handleLogout}
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',

            width: '100%',
            marginBottom: '30px',
            cursor: 'pointer',
            color: 'red',
          }}
        />
      </CustomTooltip>
      {/* </Button> */}

      {/* <Box mt="60px" mb="40px" borderRadius="30px">
        <SidebarCard />
      </Box> */}
    </Flex>
  );
}

export default SidebarContent;

export const USERS_LISTING_API = '/admin/users';
export const PROPERTIES_LISTING_API = '/admin/property';

export const GENERATE_OTP_API = '/mfauth/generate-otp';
export const LOGIN_API = '/admin/login';
export const TOKEN_VERIFICATION_API = '/admin/verify-token';
export const ASSETS_PATH = 'https://assets.hexahome.in'
export const GET_USER_API_FN = (userId) => `/admin/users/${userId}`;

export const GET_PROPERTY_API_FN = (propertyType, propertyId) =>
  `/admin/${propertyType}/${propertyId}`;

export const UPDATE_USER_AND_PROPERTY_API = '/admin/update-status';
export const LOCALITY_INSIGHT_API = '/admin/locality-insights';
export const DOWNLOAD_SITEMAP_API = '/admin/download-sitemap';
export const UPDATE_RECENT_SEARCH_PATH = '/admin/update-recent-search';

export const BUILDERS_LISTING_API = '/admin/builders';

export const AGENTS_LISTING_API = '/admin/agents';

export const ADS_LOCATION_API = '/location/autocomplete';

export const DASHBOARD_API = '/admin/dashboard/ads';

export const DASHBOARD_USER = '/admin/dashboard/users';

export const CREATE_USER = '/admin/users';
export const USER_ACTIVITY = '/admin/user-activity'
export const JOB_APPLICATIONS_API = '/admin/applied-jobs'

export const SEND_ASSIGNMENT_REMINDER_API = '/admin/send-reminder-mail'

export const DEV_ASSIGNEE_API = '/admin/dev-asignee'
export const UPDATE_ENTITY_PATH = '/admin/update-entity'

export const STATIC_DATA_PATH = '/static'
export const HEXAHOME_PATH = 'https://hexahome.in/'
/**post property routes**/ 
export const CREATE_ADS_API = '/admin/property';
export const GET_AUTO_COMPLETE = '/location/autosuggest';
export const AD_LEAD_API = '/admin/leads';
export const GET_LOCATION_DETAILS = '/location/place-detail';
export const ADS_UPLOAD_SIGNED_URL_API = '/admin/upload-signed-url/ad';
export const AUTO_GENERATE_DES_API = '/nlp/autogenerate-desc';
export const GET_STATIC_DATA_API = '/static/top-banks.json';
export const UPLOAD_SIGNED_PROJECTS_URL_API = '/admin/upload-signed-url/project';
export const CREATE_PROJECTS = '/admin/projects';
export const UPDATE_PROJECTS_STATUS = '/admin/projects/update-status';
export const CREATE_COMPANY = '/admin/company';
export const COMPANY_CERTIFICATE_UPLOAD_API = '/admin/upload-signed-url/certificate';
export const COMPANY_IMAGE_UPLOAD_API = '/admin/upload-signed-url/company-photo';
export const STATIC_CONTENT_DATA_API = (relativePath) =>`/static${relativePath}`;
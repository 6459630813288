import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import {
  HStack,
  IconButton,
  Text,
  Input,
  Select,
  Flex,
} from "@chakra-ui/react";
import { showToast } from "components/toast/Toast";
import { useEffect, useState } from "react";

const textColor = "gray.800";

const Pagination = ({
  currentPage,
  totalPages,
  onPageChange,
  setCurrentPage,
  totalCount,
  pageSize,
  setPageSize,
  setPageSizeToLocalStorage,
}) => {
  const [currentInputPage, setCurrentInputPage] = useState(1);

  useEffect(() => {
    setCurrentInputPage(currentPage);
  }, [currentPage]);

  return (
    <HStack spacing="4" align="center">
      <Flex alignItems="center" cursor={"pointer"}>
        <Text mr={2}>Size</Text>
        <Select
          value={pageSize}
          onChange={(e) => {
            setPageSize(Number(e.target.value));
            setPageSizeToLocalStorage(Number(e.target.value));
            setCurrentPage(1);
          }}
          size="sm"
          width="auto"
          cursor={"pointer"}
          borderRadius={"md"}
        >
          <option value={10}>10</option>
          <option value={15}>15</option>
          <option value={20}>20</option>
          <option value={50}>50</option>
        </Select>
      </Flex>

      <Text color={textColor}>Page</Text>
      <IconButton
        icon={<ChevronLeftIcon />}
        colorScheme="teal"
        variant="outline"
        onClick={() => {
          onPageChange(+currentPage - 1);
          setCurrentInputPage(+currentPage - 1);
        }}
        disabled={currentPage === 1}
        borderRadius="full"
        size="sm"
      />

      <form
        onSubmit={(e) => {
          e.preventDefault();

          if (currentInputPage > totalPages || currentInputPage < 1) {
            showToast({
              message: "Please Enter Valid Page Number",
              customStyle: {
                background: "#ff4d4f",
                color: "#fff",
                padding: "10px",
              },
            });
            return;
          }

          if (currentInputPage === 0) {
            setCurrentInputPage(1);
          }

          setCurrentPage(currentInputPage);
          onPageChange(currentInputPage);
        }}
      >
        <Input
          // type="number"
          value={currentInputPage}
          onChange={(e) => {
            const value = e.target.value;
            if (value === "" || !isNaN(value)) {
              setCurrentInputPage(+value);
            } else {
              showToast({
                message: "Please Enter Valid Number",

                customStyle: {
                  background: "#ff4d4f",
                  color: "#fff",
                  padding: "10px",
                },
              });
            }
          }}
          width="50px"
          textAlign="center"
          borderColor="gray.300"
          borderRadius="md"
          size="sm"
          // min={1}
          // max={totalPages}
        />
      </form>

      <IconButton
        icon={<ChevronRightIcon />}
        colorScheme="teal"
        variant="outline"
        onClick={() => {
          onPageChange(+currentPage + 1);
          setCurrentInputPage(+currentPage + 1);
        }}
        disabled={currentPage === totalPages}
        borderRadius="full"
        size="sm"
      />
      <Text color={textColor}>of {totalPages}</Text>
    </HStack>
  );
};

export default Pagination;

import {
  Icon,
  Flex,
  Text,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useDisclosure,
  useColorModeValue,
} from '@chakra-ui/react';
// Assets
import { MdOutlineMoreHoriz, MdOutlineSettings } from 'react-icons/md';
import {
  PROPERTY_STATUS,
  JOB_APPLICATION_STATUS_DATA,
} from 'constants/filterDropdownData';
import { useModal } from 'contexts/ModalContext';
import { useMemo } from 'react';
import { JOB_APPLICATION_STATUS } from 'constants/dbEnums';
import { MISCELLANEOUS } from 'constants/dbEnums';

export default function MultiSelectMenu(props) {
  const {
    multiSelectCurrentValue,
    onSelectedAction = () => {},
    onMultipleUpdateStatus = () => {},
    selectedAction,
    // selectedRowsData,
    bgColor = '',
    dataType = 'properties',
    ...rest
  } = props;

  const textColor = useColorModeValue('secondaryGray.500', 'white');
  const textHover = useColorModeValue(
    { color: 'secondaryGray.900', bg: 'unset' },
    { color: 'secondaryGray.500', bg: 'unset' }
  );
  const iconColor = useColorModeValue('brand.500', 'white');
  const bgList = useColorModeValue('white', 'whiteAlpha.100');
  const bgShadow = useColorModeValue(
    '14px 17px 40px 4px rgba(112, 144, 176, 0.08)',
    'unset'
  );
  const bgButton = useColorModeValue('secondaryGray.300', 'whiteAlpha.100');
  const bgHover = useColorModeValue(
    { bg: 'secondaryGray.400' },
    { bg: 'whiteAlpha.50' }
  );
  const bgFocus = useColorModeValue(
    { bg: 'secondaryGray.300' },
    { bg: 'whiteAlpha.100' }
  );

  const {
    isOpen: isOpen1,
    onOpen: onOpen1,
    onClose: onClose1,
  } = useDisclosure();

  const { openModal } = useModal();

  const getAvailableStatuses = (currentStatus) => {
    if (dataType === 'properties') {
      switch (currentStatus) {
        case 'Pending':
          return ['Rejected', 'Active'];
        case 'Inactive':
          return ['Active'];
        case 'Active':
          return ['Inactive'];
        case 'Expired':
          return ['Active'];
        case 'Rejected':
          return ['Pending'];
        case 'Deleted':
          return ['Active'];
        default:
          return [];
      }
    } else if (dataType === 'jobApplications') {
      switch (currentStatus) {
        case 'Assignment Submitted':
          return ['Assignment Reviewed', MISCELLANEOUS.add_comment, 'Rejected'];
        case 'Need To Complete Assignment':
          return [MISCELLANEOUS.send_remider_mail, MISCELLANEOUS.add_comment, 'Rejected', 'Shortlisted'];
        case 'Assignment Reviewed':
          return ['Rejected', 'Shortlisted', MISCELLANEOUS.add_comment];
        case 'Shortlisted':
          return [MISCELLANEOUS.add_comment, 'Rejected'];
        case 'Rejected':
          return [MISCELLANEOUS.add_comment];
        default:
          return [];
      }
    }
  };

  const statusData = useMemo(() => {
    if (dataType === 'jobApplications') {
      return [...JOB_APPLICATION_STATUS_DATA, {
        label: MISCELLANEOUS.send_remider_mail,
        value: MISCELLANEOUS.send_remider_mail
      },{
        label: MISCELLANEOUS.add_comment,
        value: MISCELLANEOUS.add_comment
      }];
    }
    return PROPERTY_STATUS;
  }, [dataType]);

  const handleMenuClick = () => {
    onOpen1();
    onSelectedAction();
  };

  const handleMenuItemClick = (selectedStatus, comment, sendEmail) => {
    const updatedPropertyType = statusData.find(
      (item) => item.label === selectedStatus
    ).value;

    onMultipleUpdateStatus({
      updatedStatus: updatedPropertyType,
      comment: comment,
      sendEmail
    });
  };

  const handleMenuItemClickModal = (selectedStatus) => {
    const isMailStatus = selectedStatus === MISCELLANEOUS.send_remider_mail;
    let bodyText = isMailStatus ? `Are you sure you want to send reminder mail? ` : `Are you sure you want to update status to <span style="font-weight: bold;">${selectedStatus}</span>? `
    let title = isMailStatus ? 'Send Mail' : 'Update Status';
    const label = isMailStatus ? 'Send Mail' : 'Yes, update';
    if(selectedStatus === MISCELLANEOUS.add_comment){
      bodyText = "";
      title = MISCELLANEOUS.add_comment
    } 
    openModal({
      title: title,
      bodyText: bodyText,
      actions: [
        {
          label: label,
          colorScheme: 'blue',
          onClick: (comment, sendEmail) => {
            handleMenuItemClick(selectedStatus, comment, sendEmail);
          },
        },
      ],
      showInputBox: dataType === 'jobApplications' && (selectedStatus === JOB_APPLICATION_STATUS.rejected || selectedStatus === JOB_APPLICATION_STATUS.shortlisted || selectedStatus === MISCELLANEOUS.add_comment) ? true : false,
      showEmailCheckBox: selectedStatus === JOB_APPLICATION_STATUS.rejected ? true : false
    });
  };

  return (
    <Menu isOpen={isOpen1} onClose={onClose1}>
      <MenuButton
        align="center"
        justifyContent="center"
        // bg={bgColor ? bgColor : bgButton}
        _hover={bgHover}
        _focus={bgFocus}
        _active={bgFocus}
        w="37px"
        h="37px"
        lineHeight="100%"
        onClick={handleMenuClick}
        borderRadius="10px"
        bg="teal.200"
        style={{ backgroundColor: '#B2F5EA' }}
        // {...rest}
      >
        <Icon as={MdOutlineMoreHoriz} color={iconColor} w="24px" h="24px" />
      </MenuButton>
      <MenuList
        w="15vw"
        minW="unset"
        border="transparent"
        backdropFilter="blur(63px)"
        bg={bgList}
        boxShadow={bgShadow}
        borderRadius="20px"
        p="20px"
      >
        {getAvailableStatuses(multiSelectCurrentValue)
          ?.filter((item) => item !== multiSelectCurrentValue)
          ?.map((item) => {
            return (
              <MenuItem
                key={item}
                fontSize={'md'}
                transition="0.2s linear"
                color={textColor}
                _hover={textHover}
                p="0px"
                borderRadius="8px"
                _active={{
                  bg: 'transparent',
                }}
                _focus={{
                  bg: 'transparent',
                }}
                onClick={() => handleMenuItemClickModal(item)}
              >
                <Flex align="center">
                  <Icon as={MdOutlineSettings} h="16px" w="16px" me="8px" />
                  <Text fontSize="sm" fontWeight="400">
                    {item}
                  </Text>
                </Flex>
              </MenuItem>
            );
          })}
      </MenuList>
    </Menu>
  );
}

import { Flex, Text } from '@chakra-ui/react';
import StatusBadge from 'components/badge/StatusBadge';
import CustomTooltip from 'components/tooltip/CustomTooltip';

export const buildersColumns = [
  {
    Header: 'SLUG',
    accessor: 'slug_url',
    Cell: ({ value }) => (
      <a
        _hover={{ textDecoration: 'underline' }}
        cursor="pointer"
        href={`${process.env.REACT_APP_HEXAHOME_BASE_URL}/${value}`}
        target="_blank"
        rel="noreferrer"
        whiteSpace={'nowrap'}
        style={{ fontSize: '10px' }}
      >
        {value}
        {/* {value.slice(0, 20) + "..."} */}
      </a>
    ),
  },
  {
    Header: 'NAME',
    accessor: 'name',
  },

  {
    Header: 'STATUS',
    accessor: 'status',
    Cell: ({ value }) => (
      <Flex align="center" justify={'center'}>
        <Text>
          <StatusBadge status={value} />
        </Text>
      </Flex>
    ),
  },

  // {
  //   Header: "VERIFIED",
  //   accessor: "verified",
  // },
  {
    Header: 'EXPERIENCE',
    accessor: 'experience',
  },
  {
    Header: 'COMPANY NAME',
    accessor: 'company_name',
  },
  {
    Header: 'Total Projects',
    accessor: 'total_projects',
  },
];

import { Box, Flex, useColorModeValue, GridItem, Grid, Button } from '@chakra-ui/react';

import MiniStatistics from 'components/card/MiniStatistics';
import { DASHBOARD_API } from 'constants/endpoints';
import useApi from 'hooks/useApi';
import React, { useCallback, useEffect, useState } from 'react';
import CheckTable from 'views/admin/default/components/CheckTable';

import {
  columnDataTotalAds,
  columnsDataActiveAds,
} from 'views/admin/default/variables/columnsData';
import { OverlayLoaderComponent } from 'components/loader/Loader';
import { transformedTotalAdsDataFn } from 'utils/common';
import { transformedActiveAdsDataFn } from 'utils/common';
import FilterCalendar from 'components/calendar/FilterCalendar';
import { formatDateToDDMMYYYY } from 'utils/common';
import { DASHBOARD_USER } from 'constants/endpoints';
import { transformUserData } from 'utils/common';
import { columnsDataUserStatus } from './variables/columnsData';
import ErrorDisplay from 'components/errorDisplay/ErrorDisplay';
import { DOWNLOAD_SITEMAP_API } from 'constants/endpoints';

const today = new Date();
const tenDaysAgo = new Date();
tenDaysAgo.setDate(today.getDate() - 10);
export default function UserReports() {
  const { apiCall, error, loading } = useApi();

  const [dashboardData, setDashboardData] = React.useState({});
  const [userResponseData, setUserResponseData] = React.useState({});

  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  const fetchData = useCallback(async () => {
    try {
      const [dashboardData, userResponse] = await Promise.all([
        apiCall.get(DASHBOARD_API, {
          start_date: formatDateToDDMMYYYY(startDate),
          end_date: formatDateToDDMMYYYY(endDate),
        }),
        apiCall.get(DASHBOARD_USER),
      ]);
      setDashboardData(dashboardData?.data);
      setUserResponseData(userResponse?.data);
    } catch (error) {
      console.log(error);
    }
  }, [startDate, endDate]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleDownload = async () => {
    try {
      const data = await apiCall.get(DOWNLOAD_SITEMAP_API, null, 'blob');
      console.log("download sitemap function calling---------------")
      const blob = new Blob([data], { type: "application/zip" });
      const urlObject = URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.href = urlObject;
      link.download = "sitemap.zip";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      URL.revokeObjectURL(urlObject);
    } catch (error) {
      console.log(error);
    }
  };

  const {
    total_active_ads = 0,
    total_ads = 0,
    total_pending_verification_ads = 0,
  } = dashboardData;

  const activeAgents = userResponseData?.agents?.active;
  const pendingVerificationAgents =
    userResponseData?.agents?.pending_verification;

  const activeBuilder = userResponseData?.builders?.active;
  const pendingVerificationbuilders =
    userResponseData?.builders?.pending_verification;

  const activeProject = userResponseData?.projects?.active;
  const pendingVerificationProject =
    userResponseData?.projects?.pending_verification;

  const statistics = {
    'Total Ads': [
      {
        name: 'Active',
        value: total_active_ads,
        link: '/admin/ads?property_status=active',
        chipColor: {
          bg: 'green.50',
          text: 'green.500',
          textSecondary: 'green.400',
          iconBg: 'green.200',
        },
      },
      {
        name: 'Pending',
        value: total_pending_verification_ads,
        link: '/admin/ads?property_status=pending_verification',
        chipColor: {
          bg: 'orange.50',
          text: 'orange.500',
          textSecondary: 'orange.400',
          iconBg: 'orange.200',
        },
      },
    ],
    Agent: [
      {
        name: 'Active Agent',
        value: activeAgents,
        link: '/admin/agents?status=active',
        chipColor: {
          bg: 'green.50',
          text: 'green.500',
          textSecondary: 'green.400',
          iconBg: 'green.200',
        },
      },
      {
        name: 'Pending  Agent',
        value: pendingVerificationAgents,
        link: '/admin/agents?status=pending_verification',
        chipColor: {
          bg: 'orange.50',
          text: 'orange.500',
          textSecondary: 'orange.400',
          iconBg: 'orange.200',
        },
      },
    ],
    Builder: [
      {
        name: 'Active Builder',
        value: activeBuilder,
        link: '/admin/builders?status=active',
        chipColor: {
          bg: 'green.50',
          text: 'green.500',
          textSecondary: 'green.400',
          iconBg: 'green.200',
        },
      },
      {
        name: 'Pending  Builder',
        value: pendingVerificationbuilders,
        link: '/admin/builders?status=pending_verification',
        chipColor: {
          bg: 'orange.50',
          text: 'orange.500',
          textSecondary: 'orange.400',
          iconBg: 'orange.200',
        },
      },
    ],
    Projects: [
      {
        name: 'Active Project',
        value: activeProject,
        link: '/admin/ads?property_status=active',
        chipColor: {
          bg: 'green.50',
          text: 'green.500',
          textSecondary: 'green.400',
          iconBg: 'green.200',
        },
      },
      {
        name: 'Pending  Project',
        value: pendingVerificationProject,
        link: '/admin/ads?property_status=pending_verification',
        chipColor: {
          bg: 'orange.50',
          text: 'orange.500',
          textSecondary: 'orange.400',
          iconBg: 'orange.200',
        },
      },
    ],
  };

  const transformedTotalAdsData = transformedTotalAdsDataFn(dashboardData);
  const transformedActiveAdsData = transformedActiveAdsDataFn(dashboardData);
  const transformUserDatas = transformUserData(userResponseData);

  const categories = Object.entries(statistics);

  const updatedTransformedActiveAdsData = [
    ...transformedActiveAdsData,
    {
      type: 'PG',
      rent: dashboardData?.pg?.active || 0,
      sell: 0,
      total_ads: dashboardData?.pg?.active || 0,
    },
    {
      type: 'Plot',
      rent: 0,
      sell: dashboardData?.plot?.active || 0,
      total_ads: dashboardData?.plot?.active || 0,
    },
    {
      type: 'Share Flat',
      rent: dashboardData?.share_flat?.active || 0,
      sell: 0,
      total_ads: dashboardData?.share_flat?.active || 0,
    },
  ];

  if (error) {
    return <ErrorDisplay error={error} />;
  }

  return (
    <Box pt="20px" overflowX={'hidden'} w="93%" minH="100vh">
      <Flex gap="20px" mb="20px" flexWrap={'wrap'}>
        {categories.map(([categoryName, stats]) => (
          <MiniStatistics
            key={categoryName}
            categoryName={categoryName}
            stats={stats}
          />
        ))}
        <FilterCalendar
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
        />

        <Button colorScheme="pink" onClick={handleDownload}>
          Download XML
        </Button>
      </Flex>

      <Grid templateColumns="repeat(2, 1fr)" gap={1}>
        <Grid templateRows="repeat(2, 1fr)" rowGap={0} width={'70%'}>
          <GridItem mb={0}>
            <CheckTable
              columnsData={columnDataTotalAds}
              tableData={transformedTotalAdsData}
              title="Total Ads"
            />
          </GridItem>

          <GridItem mb={0}>
            <CheckTable
              columnsData={columnsDataUserStatus}
              tableData={transformUserDatas}
              title="Total Users"
            />
          </GridItem>
        </Grid>
        <Grid>
          <GridItem>
            <CheckTable
              columnsData={columnsDataActiveAds}
              tableData={updatedTransformedActiveAdsData}
              title="Total Active Ads"
            />
          </GridItem>
        </Grid>
      </Grid>

      <OverlayLoaderComponent isOpen={loading} />
    </Box>
  );
}

import { Flex, Text } from "@chakra-ui/react";
import StatusBadge from "components/badge/StatusBadge";
import TooltipModal from "components/modal/TooltipModal";

export const projectsColumn = [
  {
    Header: "UID",
    accessor: "uid",
  },
  {
    Header: "PROJECT NAME",
    accessor: "name",
  },
  {
    Header: "SLUG",
    accessor: "slug_url",
    Cell: ({ value }) => (
      <a
        _hover={{ textDecoration: 'underline' }}
        href={`${process.env.REACT_APP_HEXAHOME_BASE_URL}/${value}`}
        target="_blank"
        rel="noreferrer"
        cursor="pointer"
        whiteSpace={'nowrap'}
        style={{ fontSize: '11px', fontWeight: 600, textAlign: 'left' }}
      >
        {value}
      </a>
    ),
  },
  {
    Header: "PROPERTY_TYPE",
    accessor: "property_type",
  },
  {
    Header: "PROJECT_STATUS",
    accessor: "project_status",
  },
  {
    Header: "STATUS",
    accessor: "status",
  },
  {
    Header: "COMPANY NAME",
    accessor: "company_name",
  },
  {
    Header: "META TITLE",
    accessor: "meta_title",
  },
  {
    Header: "META DESCRIPTION",
    accessor: "meta_description",
    Cell: ({ value }) => (
      <Flex align="center" justify="center">
        <Text cursor="pointer" whiteSpace={"nowrap"}>
          {value.slice(0, 10) + "..."}
        </Text>

        <TooltipModal dataLabel={value} title="Projects Meta Description" />
      </Flex>
    ),
  },
  {
    Header: "ADDRESS",
    accessor: "address",
    Cell: ({ value }) => (
      <Text
        cursor="pointer"
        whiteSpace={"nowrap"}
        style={{ fontSize: "11px", fontWeight: 600, textAlign: "left" }}
      >
        {value}
      </Text>
    ),
  },
];

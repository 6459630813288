import React from "react";
import { Table, Thead, Tbody, Tr, Th, Td } from "@chakra-ui/react";

const UserActivityTable = ({ data = [], columns, onMakeImportant }) => {
  return (
    <Table
      variant="simple"
      border="1px solid"
      borderColor="gray.200"
      borderRadius="md"
    >
      <Thead>
        <Tr>
          {columns.map((column, index) => (
            <Th key={index} padding="0.2rem">
              {column.label}
            </Th>
          ))}
        </Tr>
      </Thead>
      <Tbody>
        {data.map((row, index) => (
          <Tr key={index}>
            {columns.map((column, index) => (
              <Td key={index} padding="0.2rem" lineHeight="1.2" fontSize={13}>
                {(column.render
                  ? column.render(row, onMakeImportant)
                  : row[column.accessor]) || "-"}
              </Td>
            ))}
          </Tr>
        ))}
      </Tbody>
    </Table>
  );
};

export default UserActivityTable;

import { Box, Button, Flex } from "@chakra-ui/react";
import MultiSelect from "components/addForm/MultiSelect";
import ValidatedInput from "components/addForm/ValidatedInput";
import { LOCATION_TYPE } from "constants/dbEnums";
import { PROJECT_PROPERTY_TYPE_ARRAY } from "constants/dbEnums";
import { GET_AUTO_COMPLETE } from "constants/endpoints";
import useApi from "hooks/useApi";
import debounce from "lodash.debounce";
import { useCallback, useEffect, useMemo, useState } from "react";
import { getDataFromLocalStorage } from "utils/common";

export const OperatingCities = (props) =>{
  const topCities = getDataFromLocalStorage('topCities');
  const [filteredOptions, setFilteredOptions] = useState(topCities);
  const [searchText, setSearchText] = useState('');
  const { apiCall } = useApi();

  const cities = useMemo(() => {
    const existingCities = Array.isArray(filteredOptions) ? filteredOptions : [];
    const valueArray = Array.isArray(props.value) ? props.value.map(city => city) : [];
    const additionalCities = valueArray
      .filter(city => !existingCities.some(option => option.title === city))
      .map(city => ({ name: city, id: city }));

    const mappedCities = existingCities.map(city => ({
      name: city.title,
      id: city.title ? city.title : city.name,
    }));
    return [...mappedCities, ...additionalCities];
  }, [filteredOptions, props.value]);

  const searchCities = useCallback(
    debounce(async (query) => {
      try {
        if (query.trim() && query?.length >=3 ) {
          const url = `${GET_AUTO_COMPLETE}?q=${query}&result_type=${LOCATION_TYPE.CITY}`;
          const response = await apiCall.get(url);
          const data = response?.data || [];
          setFilteredOptions(data);
        }
      } catch (error) {
        console.error("Error fetching cities:", error);
      }
    }, 500),
    [apiCall]
  );

  const handleSearchChange = async (searchTerm) => {
    const value = searchTerm;
    setSearchText(value);
    if (value) {
      const filtered = topCities.filter((city) =>
        city.title.includes(value)
      );
      setFilteredOptions(filtered);
    } else {
      setFilteredOptions(topCities);
    }
  };

  const   resetOptions = () => {
    setFilteredOptions(topCities);
    setSearchText('');
  };

  useEffect(() => {
    if (typeof searchText === 'string' && searchText?.trim() && filteredOptions.length === 0) {
      searchCities(searchText);
    }
    return () => searchCities.cancel();
  }, [searchText, filteredOptions]);

  return (
    <>
    <MultiSelect
      name="operating_cities"
      label="Select Operating Cities"
      options={cities}
      displayValue="name"
      value={props.value}
      onChange={(e) => {
        props.onChange(e);
        resetOptions();
      }}
      isRequired={true}
      error={props.error}
      fetchSuggestions={handleSearchChange}
    />
    </>
  )
};

export const createMetaTitle = (companyName) => {
  const MAX_LENGTH = 60;
  let metaTitle = `${companyName}: Find New, Ongoing & Upcoming Projects`;
  
  const newMetaTitle = metaTitle.length < MAX_LENGTH ? `${metaTitle} - Builder` : metaTitle;
  return newMetaTitle.length <= MAX_LENGTH ? newMetaTitle : metaTitle;
};

export const createMetaDescription = (companyName) => {
  const MAX_LENGTH = 160;
  let metaDescription = `Find ${companyName} builders new Residential & Commercial projects.`;
  const newMetaDescription = metaDescription.length < MAX_LENGTH ? `${metaDescription} Full details & Price trends of latest projects.` : metaDescription;
  return newMetaDescription.length <= MAX_LENGTH ? newMetaDescription : metaDescription;
};

const Builder = {
  slug_url: props => (
    <ValidatedInput
      name="slug_url"
      label="Slug Url"
      value={props.value}
      placeholder="Enter Slug url"
      type="text"
      isRequired={true}
      {...props}
    />
  ),
  experience: (props) => (
    <ValidatedInput
      name="experience"
      label="Experience"
      value={props.value}
      placeholder="Enter builder experience"
      type="number"
      isRequired={true}
      {...props}
      valueInNumber={true}
    />
  ),
  operating_cities: OperatingCities,
  dealing_property_types: (props) => (
    <MultiSelect
      name="dealing_property_types"
      label="Select Dealing Property Types"
      options={PROJECT_PROPERTY_TYPE_ARRAY}
      displayValue="name"
      value={props.value}
      onChange={props.onChange}
      isRequired={true}
      error={props?.error}
    />
  ),
  meta_title: (props) => {
    const { onChange, formData, error } = props;
    const {meta_title} = formData;
    const handleChange = (e) => {
      const { name, value } = e.target;
      onChange({ target: { name, value } });
    };
    const handleGenerateMetaTitle = () => {
      const { company_name } = formData;
      const generatedMetaTitle = createMetaTitle(company_name);
      onChange({ target: { name: "meta_title", value: generatedMetaTitle } });
    };
    return (
      <Flex justify="space-between" mb={4}>
        <Box flex="1" mr={2}>
          <ValidatedInput
            name="meta_title"
            label="Meta Title"
            placeholder="Create Meta Title"
            type="text"
            onChange={(e) => handleChange(e)}
            error={error}
            value={meta_title}
            styles={{ height: "60px" }}
            isRequired={true}
          />
          <Flex justify="space-between" alignItems="center" mt={2}>
          <Button
            colorScheme="blue"
            size="sm"
            fontSize="sm"
            width="auto"
            px={4}
            onClick={handleGenerateMetaTitle}
          >
            Generate Title
          </Button>
          <Box>{meta_title?.length}/60</Box>
          </Flex>
        </Box>
      </Flex>
    );
  },
  meta_description: (props) => {
    const { onChange, formData, error } = props;
    const { company_name, meta_description } = formData;
    const handleChange = (e) => {
      const { name, value } = e.target;
      onChange({ target: { name, value } });
    };
    const handleGenerateMetaTitle = () => {
      const generatedMetaDescription = createMetaDescription(company_name);
      onChange({
          target: {
              name: "meta_description",
              value: generatedMetaDescription
          },
      });
    };
    return (
      <Flex justify="space-between" mb={4}>
        <Box flex="1" mr={2}>
          <ValidatedInput
            name="meta_description"
            label="Meta Description"
            placeholder="Enter Meta Description"
            type="text"
            onChange={(e) => handleChange(e)}
            error={error}
            value={meta_description}
            styles={{ height: "60px" }}
            isDisabled={!company_name}
            isRequired={true}
          />
          <Flex justify="space-between" alignItems="center" mt={2}>
          <Button
            colorScheme="blue"
            size="sm"
            fontSize="sm"
            width="auto"
            px={4}
            onClick={handleGenerateMetaTitle}
          >
            Generate Description
          </Button>
          <Box>{meta_description?.length}/160</Box>
          </Flex>
        </Box>
      </Flex>
    );
  },
};

export default Builder;

import { QuestionIcon } from "@chakra-ui/icons";

const {
  useDisclosure,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  ModalHeader,
  IconButton,
  Text,
} = require("@chakra-ui/react");

function TooltipModal({ dataLabel, title }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <IconButton
        aria-label="Toggle Tooltip"
        icon={<QuestionIcon />}
        onClick={onOpen}
        variant="ghost"
        size="sm"
        borderRadius="full"
        color="gray.500"
      />
      <Modal isOpen={isOpen} onClose={onClose} size="6xl">
        <ModalOverlay />
        <ModalContent borderRadius="lg" boxShadow="lg">
          <ModalHeader
            fontWeight="bold"
            borderBottomWidth="1px"
            borderColor="gray.200"
            pb={2}
          >
            {title}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody p={6}>
            <Text mb={4} lineHeight="tall">
              {dataLabel}
            </Text>
          </ModalBody>
          <ModalFooter justifyContent="center">
            <Button onClick={onClose} colorScheme="blue" size="md">
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default TooltipModal;

import { AD_TYPE_TAGS } from "constants/dbEnums";
import { SLUG_URL_PROPERTY_TAG } from "constants/dbEnums";
import { HEXAHOME_PATH } from "constants/endpoints";

export const generateSlugUrl = (propertyType, adType, locality, city) => {
    let url = '';
    const replaceSpaces = (str) => str?.replace(/\s+/g, '-').toLowerCase();
    city = replaceSpaces(city);
    locality = replaceSpaces(locality);
    switch (propertyType) {
        case "pg":
            url = `pg-in-${locality}-${city}-hmfr/`;
            break;

        case "residential-plots-land":
            url = `residential-plots-land-for-sale-in-${locality}-${city}-hmfs/`;
            break;

        case "commercial-plots-land":
            url = `commercial-plots-land-for-sale-in-${locality}-${city}-hmfs/`;
            break;

        case "share_flat":
            url = `shared-flats-${locality}-${city}-hmfr/`;
            break;

        default:
            if (adType === AD_TYPE_TAGS.RENT || adType === AD_TYPE_TAGS.SELL) {
                url = `${SLUG_URL_PROPERTY_TAG[propertyType]}-for-${adType === AD_TYPE_TAGS.RENT ? adType : "sale"}-in-${locality}-${city}-${adType === AD_TYPE_TAGS.RENT ? 'hmfr' : 'hmfs'}`;
            }
            break;
    }
    url = `${HEXAHOME_PATH}${url}`;
    return url;
};

export const generateSlugUrlForUserActivity = (type, propertyType, adType, locality, city) => {
    let url = '';
    const replaceSpaces = (str) => str?.replace(/\s+/g, '-').toLowerCase();
    city = replaceSpaces(city);
    locality = replaceSpaces(locality);
    switch (type) {
        case "PG":
            url = `pg-in${locality ? `-${locality}` : ''}-${city}-hmfr/`;
            break;

        case "plot":
            if(propertyType[0] === "residential"){
                url = `residential-plots-land-for-sale-in${locality ? `-${locality}` : ''}-${city}-hmfs/`;
            }else{
                url = `commercial-plots-land-for-sale-in${locality ? `-${locality}` : ''}-${city}-hmfs/`;
            }
            break;

        case "share_flat":
            url = `shared-flats-in${locality ? `-${locality}` : ''}-${city}-hmfr/`;
            break;

        default:
            if (adType === AD_TYPE_TAGS.RENT || adType === AD_TYPE_TAGS.SELL) {
                if (!propertyType || !propertyType?.length) {
                    if (type === "residential_property") {
                        url = `residential-property-for-${adType === AD_TYPE_TAGS.RENT ? adType : "sale"}-in${locality ? `-${locality}` : ''}-${city}-${adType === AD_TYPE_TAGS.RENT ? 'hmfr' : 'hmfs'}`;
                    } else {
                        url = `commercial-property-for-${adType === AD_TYPE_TAGS.RENT ? adType : "sale"}-in${locality ? `-${locality}` : ''}-${city}-${adType === AD_TYPE_TAGS.RENT ? 'hmfr' : 'hmfs'}`;
                    }
                } else {
                    propertyType = propertyType[0]?.property_type
                    url = `${SLUG_URL_PROPERTY_TAG[propertyType]}-for-${adType === AD_TYPE_TAGS.RENT ? adType : "sale"}-in${locality ? `-${locality}` : ''}-${city}-${adType === AD_TYPE_TAGS.RENT ? 'hmfr' : 'hmfs'}`;
                }
            }
            break;
    }
    url = `${HEXAHOME_PATH}${url}/`;
    return url;
};

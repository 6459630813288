import { PROJECT_PROPERTY_TYPE } from "constants/dbEnums";

const Joi = require("joi");

const builderSchema = {
  create: Joi.object({
    user_id: Joi.string().regex(/^[0-9a-fA-F]{24}$/).required().messages({
      "string.base": "user_id must be a string",
      "string.empty": "user_id cannot be empty",
    }),
    company_id: Joi.string().regex(/^[0-9a-fA-F]{24}$/).required().messages({
      "string.base": "company_id must be a string",
      "string.empty": "company_id cannot be empty",
    }),
    experience: Joi.number().required(),
    operating_cities: Joi.array().items(Joi.string().required()).required(),
    dealing_property_types: Joi.array().items(Joi.string().valid(...Object.values(PROJECT_PROPERTY_TYPE)).required()).required(),
    meta_title: Joi.string().trim().required(),
    meta_description: Joi.string().trim().required(),
    slug_url: Joi.string().trim().required().required(),
  }),
  update: Joi.object({
    _id: Joi.string().regex(/^[0-9a-fA-F]{24}$/).required().messages({
      "string.base": "user_id must be a string",
      "string.empty": "user_id cannot be empty",
    }),
    user_id: Joi.string().regex(/^[0-9a-fA-F]{24}$/).required().messages({
      "string.base": "user_id must be a string",
      "string.empty": "user_id cannot be empty",
    }),
    company_id: Joi.string().regex(/^[0-9a-fA-F]{24}$/).required().messages({
      "string.base": "company_id must be a string",
      "string.empty": "company_id cannot be empty",
    }),
    experience: Joi.number().required(),
    operating_cities: Joi.array().items(Joi.string().required()).required(),
    dealing_property_types: Joi.array().items(Joi.string().valid(...Object.values(PROJECT_PROPERTY_TYPE)).required()).required(),
    meta_title: Joi.string().trim().required(),
    meta_description: Joi.string().trim().required(),
    slug_url: Joi.string().trim().required().required(),
  })
}

export default builderSchema;
import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useAuth } from 'contexts/AuthContext';

const AuthRoute = ({ component: Component, ...rest }) => {
  const { token } = useAuth();

  return (
    <Route
      {...rest}
      render={(props) =>
        !token ? <Component {...props} /> : <Redirect to="/admin" />
      }
    />
  );
};

export default AuthRoute;

//change to public route

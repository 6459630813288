import React from "react";
import { Badge } from "@chakra-ui/react";
import { getStatusColor } from "utils/common";

const StatusBadge = ({ status }) => {
  const { colorScheme, customColor } = getStatusColor(status);
  return (
    <Badge
      colorScheme={colorScheme}
      bg={customColor}
      color={customColor ? "white" : undefined} // Set text color to white for custom colors
      variant="solid"
      borderRadius="full"
      px={2}
      py={1}
    >
      {status}
    </Badge>
  );
};

export default StatusBadge;

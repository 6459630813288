import React, { useEffect, useRef } from "react";
import {
  FormControl,
  FormLabel,
  Input,
  Box,
  InputGroup,
  InputRightElement,
} from "@chakra-ui/react";
import ErrorMessage from "./ErrorMessage";

const ValidatedInput = ({
  name,
  value,
  onChange,
  type = "text",
  placeholder = "",
  label,
  isRequired,
  isDisabled = false,
  valueInNumber = false,
  styles = {},
  error,
  rightElement,
}) => {
  const inputRef = useRef(null);

  const handleChange = (e) => {
    const { value } = e.target;
    if (valueInNumber === true) {
      onChange({
        target: {
          name,
          value: Number(value),
        },
      });
    } else {
      onChange(e);
    }
  };

  useEffect(() => {
    const input = inputRef.current;
    if (input) {
      const preventScroll = (e) => {
        e.preventDefault();
      };
      input.addEventListener("wheel", preventScroll);

      return () => {
        input.removeEventListener("wheel", preventScroll);
      };
    }
  }, []);

  return (
    <FormControl mb={4}>
      <FormLabel htmlFor={name}>
        {label}
        {isRequired && (
          <Box as="span" color="red.500" ml={1}>
            *
          </Box>
        )}
      </FormLabel>
      <InputGroup>
        <Input
          as="input"
          id={name}
          name={name}
          type={type}
          placeholder={placeholder}
          onChange={handleChange}
          isDisabled={isDisabled}
          value={value}
          ref={inputRef}
          {...styles}
        />
        {rightElement && (
          <InputRightElement width="4rem">
            <Box textAlign="left" w="100%">{rightElement}</Box>
          </InputRightElement>
        )}
      </InputGroup>
      <ErrorMessage error={error} />
    </FormControl>
  );
};

export default ValidatedInput;

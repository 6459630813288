import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  TableContainer,
  Box,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Button,
} from "@chakra-ui/react";
import { USER_ACTIVITY } from "constants/endpoints";
import useApi from "hooks/useApi";
import { OverlayLoaderComponent } from "components/loader/Loader";
import ErrorDisplay from "components/errorDisplay/ErrorDisplay";
import { formatDateToDDMMYYYY } from "utils/common";
import { UPDATE_RECENT_SEARCH_PATH } from "constants/endpoints";
import UserActivityTable from "components/table/UserActivityTable";
import SearchesColumns from "./variables/searches.js";
import LeadColumns from "./variables/leads.js";
import { FiChevronsDown, FiFilter } from "react-icons/fi";
import SidebarFilter from "components/sidebarFilter/SidebarFilter.jsx";
import { USER_ACTIVITY_ROLE_DATA } from "constants/filterDropdownData.js";
import CommonModal from "components/modal/CommonModal.jsx";
import { UPDATE_ENTITY_PATH } from "constants/endpoints.js";

export default function UserActivity() {
  const today = new Date();
  const tenDaysAgo = new Date();
  tenDaysAgo.setDate(today.getDate() - 10);
  const initialFIlterData = {
    role: [],
    showImportant: false,
  };
  const [userActivity, setUserActivity] = useState({
    searches: [],
    leads: [],
    totlatSearches: 0,
    totlatLeads: 0,
  });
  const [startDate, setStartDate] = useState(tenDaysAgo);
  const [isOpenFilterSidebar, setIsOpenFilterSidebar] = useState(false);
  const [endDate, setEndDate] = useState(today);
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [updatedData, setUpdatedData] = useState({ data: [], type: "" });
  const { apiCall, error } = useApi();
  const [filters, setFilters] = useState(initialFIlterData);
  const { role = [], showImportant = false } = filters || {};

  const fetchUserActivity = useCallback(async () => {
    try {
      setLoading(true);
      const params = {
        start_date: formatDateToDDMMYYYY(startDate) || undefined,
        end_date: formatDateToDDMMYYYY(endDate) || undefined,
        showImportant,
        role: role?.join(",") || undefined,
      };
      const { data } = await apiCall.get(USER_ACTIVITY, params);
      if (data) setUserActivity(data);
    } catch (error) {
      console.log("Error in fetching user activity", error.message);
    } finally {
      setLoading(false);
    }
  }, [startDate, endDate, filters]);

  useEffect(() => {
    fetchUserActivity();
  }, []);

  const setAsImportant = useCallback(async () => {
    setLoading(true);
    const updateData = [
      {
        _id: updatedData?.data[0]?._id,
        type: "users",
        data: {
          important: updatedData?.data[0]?.important,
        },
      },
    ];
    await apiCall.patch(UPDATE_ENTITY_PATH, { updateData });
    fetchUserActivity();
  }, [updatedData]);

  const handleSetImportant = useCallback(async() => {
    const dataType =
      updatedData?.type === "leads"
        ? userActivity?.leads
        : userActivity?.searches;
    for (let i = 0; i < dataType.length; i++) {
      let activity = dataType[i];
      if (activity?.user_data?._id === updatedData?.data[0]?._id) {
        activity.user_data.important = !updatedData?.data?.important;
        await setAsImportant();
        break;
      }
    }
    setIsModalOpen(false);
  }, [userActivity, updatedData]);

  const toggleModal = (currentState, id, type) => {
    setIsModalOpen(!isModalOpen);
    setUpdatedData({
      data: [{ _id: id, important: !currentState, type: "users" }],
      type,
    });
  };

  const handleApplyFilter = () => {
    fetchUserActivity();
  };

  const handleFilterChange = useCallback((key, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [key]: value,
    }));
  }, []);

  const toggleSidebar = () => {
    setIsOpenFilterSidebar(!isOpenFilterSidebar);
  };

  const handleRemoveAllFilter = () => {
    setFilters(initialFIlterData);
    setEndDate("");
    setStartDate("");
  };

  const dropdownFilters = [
    {
      name: "role",
      data: USER_ACTIVITY_ROLE_DATA,
      placeholder: "Select Role",
      value: role,
    },
  ];

  const checkBoxFilter = [
    {
      name: "showImportant",
      label: "Show Only Important",
      value: showImportant,
    },
  ];

  const allFiltersProps = {
    dropdowns: dropdownFilters,
    checkBox: checkBoxFilter,
  };

  const modalText = useMemo(() => {
    let heading = "Mark As Important!";
    let description = "Do You Want To Mark As Important ?"
    if(!updatedData?.data[0]?.important){
      heading = "Remove From Important!";
      description = "Do You Want To Remove From Important ?"
    }
    return {
      heading, description
    }
  }, [updatedData])

  if (error) {
    return <ErrorDisplay error={error} />;
  }

  return (
    <Box>
      <Box width={"90%"} display="flex" mb={4}>
        <SidebarFilter
          filters={allFiltersProps}
          handleFilterChange={handleFilterChange}
          handleApplyFilter={handleApplyFilter}
          isOpen={isOpenFilterSidebar}
          onClose={toggleSidebar}
          appliedFilters={filters}
          setFilters={setFilters}
          handleRemoveAllFilter={handleRemoveAllFilter}
          isCalenderFilter={true}
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
          enableMultiselect={true}
          multiSelectKey={["role"]}
        />
      </Box>
      <CommonModal
        onDone={handleSetImportant}
        heading={modalText?.heading}
        isOpen={isModalOpen}
        description={modalText?.description}
        onClose={() => setIsModalOpen(false)}
      />
      <Tabs variant="soft-rounded" colorScheme="green">
        <TabList display={"flex"} gap={5} paddingLeft={10}>
          <Button
            paddingX={6}
            leftIcon={<FiFilter />}
            rightIcon={<FiChevronsDown />}
            colorScheme="teal"
            variant="outline"
            borderRadius="md"
            size="sm"
            onClick={() => {
              toggleSidebar();
            }}
          >
            Filter
          </Button>
          <Tab>Searches ({userActivity?.totlatSearches})</Tab>
          <Tab>Leads ({userActivity?.totlatLeads})</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <TableContainer paddingEnd={20}>
              <OverlayLoaderComponent isOpen={loading} />
              <UserActivityTable
                columns={SearchesColumns}
                data={userActivity?.searches}
                onMakeImportant={(important, id) =>
                  toggleModal(important, id, "recent_searches")
                }
              />
            </TableContainer>
          </TabPanel>
          <TabPanel>
            <TableContainer paddingEnd={20}>
              <UserActivityTable
                columns={LeadColumns}
                data={userActivity?.leads}
                onMakeImportant={(important, id) =>
                  toggleModal(important, id, "leads")
                }
              />
            </TableContainer>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
}

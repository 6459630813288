import { PROJECT_AREA_UNIT } from "constants/dbEnums";
import { PROJECT_PROPERTIES } from "constants/dbEnums";
import { PROJECT_AMENITIES } from "constants/dbEnums";
import { AREA_UNIT_TAGS } from "constants/dbEnums";
import { ENTITY_STATUS } from "constants/dbEnums";
import { FURNISH_TYPE_TAGS } from "constants/dbEnums";
import { PROJECT_STATUS } from "constants/dbEnums";
import { PROJECT_PROPERTY_TYPE } from "constants/dbEnums";
import { MONTHS } from "constants/dbEnums";
import { PROJECT_INFO_STEPS } from "constants/dbEnums";

const Joi = require("joi");

const projectsSchema = {
  create: Joi.object({
    owner_id: Joi.string()
      .regex(/^[0-9a-fA-F]{24}$/)
      .required(),
    name: Joi.string().required().trim(),
    uid: Joi.string().trim().required(),
    slug_url: Joi.string().required().trim(),
    meta_title: Joi.string().trim(),
    meta_description: Joi.string().trim(),
    step_completed: Joi.string()
      .valid(...Object.values(PROJECT_INFO_STEPS))
      .default(PROJECT_INFO_STEPS.BASIC_INFORMATION),
    about: Joi.string().required().trim(),
    address: Joi.object({
      type: Joi.string(),
      label: Joi.string(),
      place_type: Joi.string(),
      coordinates: Joi.array().items(Joi.number()).required(),
      city: Joi.string().required().trim(),
      state: Joi.string(),
      state_code: Joi.string(),
      country_code: Joi.string(),
      country: Joi.string(),
      locality: Joi.string().required(),
      sub_locality: Joi.string(),
      street: Joi.string().allow("").trim(),
      building: Joi.string(),
      postal_code: Joi.string(),
    })
      .required()
      .custom((value, helpers) => {
        if (!value.locality || value.locality.trim() === "") {
          return helpers.error("any.custom", { key: "locality" });
        }
        return value;
      }),
    launch_date: Joi.object({
      month: Joi.string()
        .valid(...Object.values(MONTHS))
        .required(),
      year: Joi.string().required(),
    }).required(),
    possession_by: Joi.object({
      month: Joi.string()
        .valid(...Object.values(MONTHS))
        .required(),
      year: Joi.string().required(),
    }).required(),
    RERA_ID: Joi.array()
      .items(
        Joi.object({
          id: Joi.string().required().trim(),
          status: Joi.string().required().trim(),
          phase: Joi.string().required().trim(),
        }).required()
      )
      .required(),
    icon: Joi.string().trim(),
    photos: Joi.array().items(Joi.string().trim()),
    brochure: Joi.string(),
    area: Joi.object({
      size: Joi.string().required(),
      unit: Joi.string()
        .valid(...Object.values(PROJECT_AREA_UNIT))
        .required(),
    }).required(),
    units: Joi.string().required().trim(),
    towers: Joi.string().trim(),
    type: Joi.array()
      .items(Joi.string().valid(...Object.values(PROJECT_PROPERTY_TYPE)))
      .required(),
    property_type: Joi.array()
      .items(
        Joi.string()
          .valid(...Object.values(PROJECT_PROPERTIES))
          .optional()
      )
      .optional(),
    project_status: Joi.string()
      .valid(...Object.values(PROJECT_STATUS))
      .required(),
    amenities: Joi.array()
      .items(Joi.string().valid(...Object.values(PROJECT_AMENITIES)))
      .required(),
    floor_plan_and_pricing: Joi.array().items(
      Joi.object({
        image: Joi.string().allow(""),
        selling_price: Joi.number().allow(null),
        rent_price: Joi.number().allow(null),
        bathrooms: Joi.string(),
        bhk: Joi.string(),
        furnish_type: Joi.string().valid(...Object.values(FURNISH_TYPE_TAGS)),
        area: Joi.object({
          size: Joi.string().required(),
          unit: Joi.string()
            .valid(...Object.values(AREA_UNIT_TAGS))
            .required(),
        }).required(),
        available_units: Joi.number().required(),
        property_type: Joi.string()
          .valid(...Object.values(PROJECT_PROPERTIES))
          .required(),
      })
    ),
    loan_offers: Joi.array().items(
      Joi.object({
        image: Joi.string().optional().trim(),
        name: Joi.string().optional().trim(),
      })
    ),
    additional_charges: Joi.array().items(
      Joi.object({
        key: Joi.string(),
        value: Joi.string().required(),
      })
    ),
  }).unknown(true),
  update: Joi.object({
    _id: Joi.string().regex(/^[0-9a-fA-F]{24}$/).required(),
    builder_id: Joi.string().regex(/^[0-9a-fA-F]{24}$/).required(),
    status: Joi.string().required().valid(...Object.values(ENTITY_STATUS)),
    name: Joi.string().required().trim(),
    uid: Joi.string().trim().required(),
    slug_url: Joi.string().required().trim(),
    meta_title: Joi.string().trim(),
    meta_description: Joi.string().trim(),
    step_completed: Joi.string()
      .valid(...Object.values(PROJECT_INFO_STEPS))
      .default(PROJECT_INFO_STEPS.BASIC_INFORMATION),
    about: Joi.string().required().trim(),
    address: Joi.object({
      type: Joi.string(),
      label: Joi.string(),
      place_type: Joi.string(),
      coordinates: Joi.array().items(Joi.number()).required(),
      city: Joi.string().required().trim(),
      state: Joi.string(),
      state_code: Joi.string(),
      country_code: Joi.string(),
      country: Joi.string(),
      locality: Joi.string().required(),
      sub_locality: Joi.string(),
      street: Joi.string().allow("").trim(),
      building: Joi.string(),
      postal_code: Joi.string(),
    })
      .required()
      .custom((value, helpers) => {
        if (!value.locality || value.locality.trim() === "") {
          return helpers.error("any.custom", { key: "locality" });
        }
        return value;
      }),
    launch_date: Joi.object({
      month: Joi.string()
        .valid(...Object.values(MONTHS))
        .required(),
      year: Joi.string().required(),
    }).required(),
    possession_by: Joi.object({
      month: Joi.string()
        .valid(...Object.values(MONTHS))
        .required(),
      year: Joi.string().required(),
    }).required(),
    RERA_ID: Joi.array()
      .items(
        Joi.object({
          id: Joi.string().required().trim(),
          status: Joi.string().required().trim(),
          phase: Joi.string().required().trim(),
          _id: Joi.string().regex(/^[0-9a-fA-F]{24}$/).optional()
        }).required()
      )
      .required(),
    icon: Joi.string().trim().allow(""),
    photos: Joi.array().items(Joi.string().trim()),
    brochure: Joi.string().allow(""),
    area: Joi.object({
      size: Joi.string().required(),
      unit: Joi.string()
        .valid(...Object.values(PROJECT_AREA_UNIT))
        .required(),
    }).required(),
    units: Joi.string().required().trim(),
    towers: Joi.string().trim(),
    type: Joi.array()
      .items(Joi.string().valid(...Object.values(PROJECT_PROPERTY_TYPE)))
      .required(),
    property_type: Joi.array()
      .items(
        Joi.string()
          .valid(...Object.values(PROJECT_PROPERTIES))
          .optional()
      )
      .optional(),
    project_status: Joi.string()
      .valid(...Object.values(PROJECT_STATUS))
      .required(),
    amenities: Joi.array()
      .items(Joi.string().valid(...Object.values(PROJECT_AMENITIES)))
      .required(),
    floor_plan_and_pricing: Joi.array().items(
      Joi.object({
        image: Joi.string().allow(""),
        selling_price: Joi.number().allow(null),
        rent_price: Joi.number().allow(null),
        bathrooms: Joi.string(),
        bhk: Joi.string(),
        furnish_type: Joi.string().valid(...Object.values(FURNISH_TYPE_TAGS)),
        area: Joi.object({
          size: Joi.string().required(),
          unit: Joi.string()
            .valid(...Object.values(AREA_UNIT_TAGS))
            .required(),
        }).required(),
        available_units: Joi.number().required(),
        property_type: Joi.string()
          .valid(...Object.values(PROJECT_PROPERTIES))
          .required(),
        _id: Joi.string().regex(/^[0-9a-fA-F]{24}$/).optional()  
      })
    ),
    loan_offers: Joi.array().items(
      Joi.object({
        image: Joi.string().optional().trim(),
        name: Joi.string().optional().trim(),
        _id: Joi.string().regex(/^[0-9a-fA-F]{24}$/).optional()
      })
    ),
    additional_charges: Joi.array().items(
      Joi.object({
        key: Joi.string(),
        value: Joi.string().required(),
        _id: Joi.string().regex(/^[0-9a-fA-F]{24}$/).optional(),
      })
    ),
  }).unknown(true)
};

export const projectsKeysArrya = ["name", "about", "icon", "brochure", "units", "towers"];

export const projectsKeys = {
  "name" : "name_and_about",
  "about" : "name_and_about",
  "icon" : "icon_and_brochure",
  "brochure" : "icon_and_brochure",
  "units" : "units_and_towers",
  "towers" : "units_and_towers"
}

export default projectsSchema;

import React, { useMemo } from 'react';
import {
  Icon,
  Flex,
  Text,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useDisclosure,
  useColorModeValue,
} from '@chakra-ui/react';
import {
  MdOutlineMoreHoriz,
 
  MdOutlineCardTravel,
  
  MdBlock,
} from 'react-icons/md';
import { useModal } from 'contexts/ModalContext';
import { getStatusColor } from 'utils/common';
import { JOB_APPLICATION_STATUS_DATA } from 'constants/filterDropdownData';
import { JOB_APPLICATION_STATUS } from 'constants/dbEnums';
import { MISCELLANEOUS } from 'constants/dbEnums';

export default function Banner(props) {
  const {
    data,
    onUpdate = () => {},
    currentValue,
    onSelectedAction = () => {},
    onUpdatePropertyStatus = () => {},
    selectedAction,
    ...rest
  } = props;

  const { openModal } = useModal();

  const textColor = useColorModeValue('secondaryGray.500', 'white');
  const textHover = useColorModeValue(
    { color: 'secondaryGray.900', bg: 'unset' },
    { color: 'secondaryGray.500', bg: 'unset' }
  );
  const iconColor = useColorModeValue('brand.500', 'white');
  const bgList = useColorModeValue('white', 'whiteAlpha.100');
  const bgShadow = useColorModeValue(
    '14px 17px 40px 8px rgba(112, 144, 176, 0.15)',
    'unset'
  );
  const bgButton = useColorModeValue('secondaryGray.300', 'whiteAlpha.100');
  const bgHover = useColorModeValue(
    { bg: 'secondaryGray.400' },
    { bg: 'whiteAlpha.50' }
  );
  const bgFocus = useColorModeValue(
    { bg: 'secondaryGray.300' },
    { bg: 'whiteAlpha.100' }
  );
  const borderColorOpen = useColorModeValue('blue.500', 'blue.300');

  const {
    isOpen: isOpen1,
    onOpen: onOpen1,
    onClose: onClose1,
  } = useDisclosure();

  const getAvailableStatuses = (currentStatus) => {
    switch (currentStatus) {
      case 'Assignment Submitted':
        return ['Assignment Reviewed', MISCELLANEOUS.add_comment, 'Rejected'];
        case 'Need To Complete Assignment':
          return [MISCELLANEOUS.send_remider_mail, MISCELLANEOUS.add_comment, 'Rejected', 'Shortlisted'];
      case 'Assignment Reviewed':
        return ['Rejected', 'Shortlisted', MISCELLANEOUS.add_comment];
      case 'Shortlisted':
        return [MISCELLANEOUS.add_comment, 'Rejected'];
      case 'Rejected':
        return [MISCELLANEOUS.add_comment];
      default:
        return [];
    }
  };

  const handleMenuClick = () => {
    onOpen1();
    onSelectedAction();
  };

  const handleMenuItemClick = (selectedStatus, comment, sendEmail) => {
    const updatedJobStatusArray = [...JOB_APPLICATION_STATUS_DATA, {
      label: MISCELLANEOUS.send_remider_mail,
      value: MISCELLANEOUS.send_remider_mail
    }, {
      label: MISCELLANEOUS.add_comment,
      value: MISCELLANEOUS.add_comment
    }]
    const updatedApplicationStatus = updatedJobStatusArray.find(
      (item) => item.label === selectedStatus
    ).value;
    onUpdatePropertyStatus({ 
        updatedApplicationStatus,
        _id: selectedAction._id,
        comment,
        sendEmail
     });
  };

  const statusIcons = {
    Rejected: MdBlock,
    Shortlisted: MdOutlineCardTravel,
  };

  const handleMenuItemClickModalOpen = (selectedStatus) => {
    const isMailStatus = selectedStatus === MISCELLANEOUS.send_remider_mail;
    let bodyText = isMailStatus ? `Are you sure you want to send reminder mail? ` : `Are you sure you want to update status to <span style="font-weight: bold;">${selectedStatus}</span>? `
    let title = isMailStatus ? 'Send Mail' : 'Update Status';
    const label = isMailStatus ? 'Send Mail' : 'Yes, update'
    if(selectedStatus === MISCELLANEOUS.add_comment){
      bodyText = "";
      title = MISCELLANEOUS.add_comment
    } 
    openModal({
      title: title,
      bodyText: bodyText,
      comment: selectedAction?.comment === "-" ? "" : selectedAction?.comment,
      actions: [
        {
          label: label,
          colorScheme: 'blue',
          onClick: (comment, sendEmail = false) => {
            handleMenuItemClick(selectedStatus, comment, sendEmail);
          },
        },
      ],
      showInputBox: (selectedStatus === JOB_APPLICATION_STATUS.rejected || selectedStatus === JOB_APPLICATION_STATUS.shortlisted || selectedStatus === MISCELLANEOUS.add_comment) ? true : false,
      showEmailCheckBox: selectedStatus === JOB_APPLICATION_STATUS.rejected ? true : false
    });
  };

  return (
    <Menu isOpen={isOpen1} onClose={onClose1} placement="right-start">
      <MenuButton
        align="center"
        justifyContent="center"
        bg={bgButton}
        _hover={bgHover}
        _focus={bgFocus}
        _active={bgFocus}
        w="37px"
        h="37px"
        lineHeight="100%"
        onClick={handleMenuClick}
        borderRadius="10px"
        border={isOpen1 ? `2px solid ${borderColorOpen}` : 'none'}
        // transition="border 0.2s ease"
      >
        <Icon as={MdOutlineMoreHoriz} color={iconColor} w="24px" h="24px" />
      </MenuButton>
      <MenuList
        w="200px"
        minW="unset"
        border="transparent"
        backdropFilter="blur(63px)"
        bg={bgList}
        boxShadow={bgShadow}
        borderRadius="20px"
        p="15px"
        style={{ zIndex: 5 }}
      >
        {getAvailableStatuses(currentValue)
          ?.filter((item) => item !== currentValue)
          ?.map((item) => (
            <MenuItem
              key={item}
              fontSize={'md'}
              transition="0.2s linear"
              color={textColor}
              _hover={textHover}
              p="0px"
              borderRadius="8px"
              _active={{ bg: 'transparent' }}
              _focus={{ bg: 'transparent' }}
              onClick={() => handleMenuItemClickModalOpen(item)}
            >
              <Flex align="center" marginY="0.5">
                <Icon as={statusIcons[item]} h="16px" w="16px" me="8px" />
                <Text fontSize="sm" fontWeight="400">
                  {item}
                </Text>
              </Flex>
            </MenuItem>
          ))}
      </MenuList>
    </Menu>
  );
}

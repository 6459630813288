import { Box, Flex, FormLabel } from "@chakra-ui/react";
import MultipleImageUpload from "./multipleImageUpload";
import { DeleteIcon } from "@chakra-ui/icons";
import React, { useCallback, useEffect, useState } from "react";
import ErrorMessage from "../ErrorMessage";

const Image = ({ onChange, name, collectionType, error, label, accept, maxLenght, value=[] }) => {
  const [selectedImages, setSelectedImages] = useState([]);

  useEffect(() => {
    const isSelectedImagesEmptyOrAllEmptyStrings = !selectedImages?.length || selectedImages.every(img => img === '');
    if(isSelectedImagesEmptyOrAllEmptyStrings && value?.length>0){
      setSelectedImages(value);
    }
  }, [value]);

  const removeImage = useCallback(
    (index) => {
      setSelectedImages((prevImages) => {
        const updatedImages = prevImages.filter((_, i) => i !== index);
        const defaultValue = updatedImages.length === 0 && maxLenght === '1' ? '' : updatedImages;
        onChange({
          target: {
            name,
            value: defaultValue,
          },
        });
        return updatedImages;
      });
    },
    [name, onChange]
  );

  const renderImage = (image) => {
    if (image?.name) {
      return image.name;
    } else {
      return <a href={`${process.env.REACT_APP_IMAGE_CDN_URL}${image}`} target="_blank">{process.env.REACT_APP_IMAGE_CDN_URL}{image}</a>;
    }
  };

  return (
    <>
      <div>
        <div className="w-[330px] h-[180px]">
        <FormLabel>{label ? label : "Upload Images (max 10)"}</FormLabel>
          <MultipleImageUpload
            selectedImages={selectedImages}
            setSelectedImages={setSelectedImages}
            onChange={onChange}
            name={name}
            accept={accept}
            maxLenght={maxLenght}
          />
        </div>
        {selectedImages?.length > 0 && (
          <div className="flex flex-wrap gap-4">
            {Array.isArray(selectedImages) && selectedImages?.map((image, index) => (
              <>
              <div className="relative shadow-md">
                <Flex>
                  <FormLabel>
                    {renderImage(image)}
                    <Box
                      as="span"
                      color="red.500"
                      ml={1}
                      onClick={() => removeImage(index)}
                    >
                      <DeleteIcon />
                    </Box>
                  </FormLabel>
                </Flex>
              </div>
              <ErrorMessage error={error && error[index]}/>
              </>
            ))}
          </div>
        )}
      </div>
    </>
  );
};
export default Image;

import { Box, Button, Flex, FormLabel, VStack, Text } from "@chakra-ui/react";
import CheckboxComponent from "components/addForm/Checkbox";
import DynamicInputField from "components/addForm/DynamicInputField";
import ErrorMessage from "components/addForm/ErrorMessage";
import CitySelectInput from "components/addForm/location/citySelectInput";
import AutoSuggestComponent from "components/addForm/location/locality";
import MultiSelect from "components/addForm/MultiSelect";
import SearchInput from "components/addForm/searchInput";
import ValidatedInput from "components/addForm/ValidatedInput";
import { LOCATION_TYPE } from "constants/dbEnums";
import { DEALS_IN_TAGS } from "constants/dbEnums";
import { GET_STATIC_DATA_API } from "constants/endpoints";
import useApi from "hooks/useApi";
import { useCallback, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { createValueLabelArray } from "utils/common";
import { getTagsForSelectedTypes } from "views/admin/form/formHandlers";

export const createMetaTitle = (companyName) => {
  const MAX_LENGTH = 60;
  let metaTitle = `${companyName} | Top Real Estate Agent`;

  const newMetaTitle =
    metaTitle.length < MAX_LENGTH ? `${metaTitle} | Leading Property Dealers` : metaTitle;
  return newMetaTitle.length <= MAX_LENGTH ? newMetaTitle : metaTitle;
};

export const createMetaDescription = (companyName) => {
  const MAX_LENGTH = 160;
  let metaDescription = `Explore ${companyName}, your expert real estate agent dedicated to finding the perfect property for you.`;
  const newMetaDescription =
    metaDescription.length < MAX_LENGTH
      ? `${metaDescription} Check out their profile for detailed projects and insights.`
      : metaDescription;
  return newMetaDescription.length <= MAX_LENGTH
    ? newMetaDescription
    : metaDescription;
};

export const LoanOffers = (props) => {
  const { onChange, name, formData, error } = props;
  const [bankList, setBankList] = useState([]);
  const { apiCall } = useApi();

  const fetchBankList = useCallback(async () => {
    try {
      const response = await apiCall.get(GET_STATIC_DATA_API);
      setBankList(response?.data);
    } catch (err) {
      console.error("Error on fetching the bank list", err);
      toast.error(err);
    }
  }, [apiCall]);
  useEffect(() => {
    fetchBankList();
  }, []);
  const handleChange = (e) => {
    const { name, value } = e.target;
    const updatedValue = value?.map((item) => {
      const bankData = bankList?.find((bank) => bank.id === Number(item.name));
      return bankData?.name;
    });
    onChange({
      target: {
        name,
        value: updatedValue,
      },
    });
  };
  const formDataForComponent = formData[name]?.map((bankName) => {
    const bankData = bankList?.find((bank) => bank.name === bankName);
    return bankData
      ? { name: bankData.id, image: bankData.image }
      : { name: bankName, image: '' };
  });
  return (
    <VStack spacing={4} align="stretch">
      <FormLabel htmlFor={name}>Loan Offers</FormLabel>
      <DynamicInputField
        formData={{ [name]: formDataForComponent }}
        onChange={handleChange}
        name={name}
        fields={[
          {
            name: "name",
            placeholder: "Enter name",
            type: "select",
            label: "Name",
            option: bankList,
          },
        ]}
        showAddIcon={true}
        showDeleteIcon={true}
        getList={true}
      />
      {typeof error === "string" && <ErrorMessage error={error} />}
    </VStack>
  );
};

export const MultiSelectLocation = (props) => {
  const { formData, onChange, error } = props;
  const [selectedLocation, setSelectedLocation] = useState({});
  const [isEditing, setIsEditing] = useState(false);
  const [editingIndex, setEditingIndex] = useState(null);
  const selectedValue = selectedLocation.city;

  const handleCityChange = (e) => {
    const { value: selectedValue } = e.target;
    const updatedValue = {
      city: selectedValue.name
    };
    setSelectedLocation(updatedValue);
  };

  const handleLocalityChange = (e) => {
    const { value: selectedValue } = e.target;
    const data = JSON.parse(selectedValue);
    setSelectedLocation(data);
  };

  const handleAddressSave = () => {
    if (isEditing) {
      saveEditedLocation();
    } else {
      const updatedAreasOfOperation = [
        ...(formData.areas_of_operation || []),
        selectedLocation,
      ];
      onChange({
        target: {
          name: "areas_of_operation",
          value: updatedAreasOfOperation,
        },
      });
      setSelectedLocation({});
    }
  };

  const handleEditLocation = (index) => {
    setSelectedLocation(formData.areas_of_operation[index]);
    setEditingIndex(index);
    setIsEditing(true);
  };

  const saveEditedLocation = () => {
    const updatedAreasOfOperation = [...formData.areas_of_operation];
    updatedAreasOfOperation[editingIndex] = selectedLocation;
    onChange({
      target: {
        name: "areas_of_operation",
        value: updatedAreasOfOperation,
      },
    });
    setSelectedLocation({});
    setIsEditing(false);
    setEditingIndex(null);
  };

  const handleDeleteLocation = (index) => {
    const updatedAreasOfOperation = formData.areas_of_operation.filter(
      (_, i) => i !== index
    );
    onChange({
      target: {
        name: "areas_of_operation",
        value: updatedAreasOfOperation,
      },
    });
  };

  return (
    <Box>
      <Flex gap={4}>
        <CitySelectInput
          name="city"
          label="Location"
          onChange={handleCityChange}
          isRequired={true}
          selectedValue={selectedValue}
        />
        <AutoSuggestComponent
          name="locality"
          selectedValue={selectedLocation.label}
          placeholder="Search by locality, area"
          onChange={handleLocalityChange}
          label="Locality"
          isRequired={true}
          selectedCity={selectedValue}
          location={selectedLocation}
          result_type={LOCATION_TYPE.LOCALITY}
        />
        <Button
          onClick={handleAddressSave}
          disabled={!selectedLocation.locality}
          width="50%"
          colorScheme={isEditing ? "blue" : "teal"}
        >
          {isEditing ? "Update" : "Save"}
        </Button>
      </Flex>
      <ErrorMessage error={error} />
      <Box mt={4}>
        <Text fontSize="lg" fontWeight="bold">
          Areas of Operation:
        </Text>
        {formData?.areas_of_operation?.length > 0 ? (
          <ul>
            {formData?.areas_of_operation.map((location, index) => (
              <li key={index}>
                {location.city} - {location.label || "Locality not set"}
                <Button
                  onClick={() => handleEditLocation(index)}
                  ml={2}
                  size="sm"
                  variant="outline"
                  colorScheme="blue"
                >
                  Edit
                </Button>
                <Button
                  onClick={() => handleDeleteLocation(index)}
                  ml={2}
                  size="sm"
                  variant="outline"
                  colorScheme="red"
                >
                  Delete
                </Button>
              </li>
            ))}
          </ul>
        ) : (
          <Text>No areas of operation added yet.</Text>
        )}
      </Box>
    </Box>
  );
};

const Agent = {
  user_id: (props) => {
    const {onChange, formData} = props;
    const { user_id, company_name } = formData;
    return (
      <SearchInput
        label="User Phone Number or ID"
        name="user_id"
        value={user_id}
        onChange={onChange}
        placeholder="Search by Phone Number"
        type="text"
        isRequired={true}
        error={props.error}
        setErrors={props.setErrors}
        disabled={company_name}
      />
    );
  },
  slug_url: (props) => (
    <ValidatedInput
      name="slug_url"
      label="Slug Url"
      value={props.value}
      placeholder="Enter Slug url"
      type="text"
      isRequired={true}
      {...props}
    />
  ),
  deals_in: (props) => (
    <MultiSelect
      name="deals_in"
      label="Deals In"
      options={DEALS_IN_TAGS}
      onChange={props.onChange}
      displayValue="name"
      value={props.value}
      error={props.error}
      isRequired={true}
    />
  ),
  dealing_property_type: (props) => {
    const selectedtabs = ["residential_property", "commercial_property"];
    const PROPERTY_TYPES_ARRAY = getTagsForSelectedTypes(selectedtabs);
    return (
      <MultiSelect
        name="dealing_property_type"
        label="Select Dealing Property Types"
        options={PROPERTY_TYPES_ARRAY}
        displayValue="name"
        value={props.value}
        onChange={props.onChange}
        isRequired={true}
        error={props?.error}
      />
    );
  },
  areas_of_operation: MultiSelectLocation,
  nar_registration: (props) => {
    const { onChange, formData, error, name } = props;
    const NAR_REGISTRATION_OPTIONS = createValueLabelArray(name);
    const handlChange = (e) => {
      const { name, checked } = e.target;
      onChange({
        target: {
          name: name,
          value: checked,
        },
      });
    };
    return (
      <>
        <CheckboxComponent
          options={NAR_REGISTRATION_OPTIONS}
          name="nar_registration"
          onChange={(e) => handlChange(e)}
          label="NAR Registration"
          textSize="lg"
          checkboxSize="lg"
          {...(formData?.nar_registration && {
            selectedValues: "true",
          })}
          error={error}
        />
      </>
    );
  },
  loan_offers: LoanOffers,
  rera_certified: (props) => {
    const { onChange, formData, error, name } = props;
    const rera_certified_options = createValueLabelArray(name);
    const handlChange = (e) => {
      const { name, checked } = e.target;
      onChange({
        target: {
          name: name,
          value: checked,
        },
      });
    };
    return (
      <>
        <CheckboxComponent
          options={rera_certified_options}
          name="rera_certified"
          onChange={(e) => handlChange(e)}
          label="Rera Certified"
          textSize="lg"
          checkboxSize="lg"
          {...(formData?.rera_certified && {
            selectedValues: "true",
          })}
          error={error}
        />
      </>
    );
  },
  meta_title: (props) => {
    const { onChange, formData, error } = props;
    const { meta_title } = formData;
    const handleChange = (e) => {
      const { name, value } = e.target;
      onChange({ target: { name, value } });
    };
    const handleGenerateMetaTitle = () => {
      const { company_name } = formData;
      const generatedMetaTitle = createMetaTitle(company_name);
      onChange({ target: { name: "meta_title", value: generatedMetaTitle } });
    };
    return (
      <Flex justify="space-between" mb={4}>
        <Box flex="1" mr={2}>
          <ValidatedInput
            name="meta_title"
            label="Meta Title"
            placeholder="Create Meta Title"
            type="text"
            onChange={(e) => handleChange(e)}
            error={error}
            value={meta_title}
            styles={{ height: "60px" }}
            isRequired={true}
          />
          <Flex justify="space-between" alignItems="center" mt={2}>
            <Button
              colorScheme="blue"
              size="sm"
              fontSize="sm"
              width="auto"
              px={4}
              onClick={handleGenerateMetaTitle}
            >
              Generate Title
            </Button>
            <Box>{meta_title?.length}/60</Box>
          </Flex>
        </Box>
      </Flex>
    );
  },
  meta_description: (props) => {
    const { onChange, formData, error } = props;
    const { company_name, meta_description } = formData;
    const handleChange = (e) => {
      const { name, value } = e.target;
      onChange({ target: { name, value } });
    };
    const handleGenerateMetaTitle = () => {
      const generatedMetaDescription = createMetaDescription(company_name);
      onChange({
        target: {
          name: "meta_description",
          value: generatedMetaDescription,
        },
      });
    };
    return (
      <Flex justify="space-between" mb={4}>
        <Box flex="1" mr={2}>
          <ValidatedInput
            name="meta_description"
            label="Meta Description"
            placeholder="Enter Meta Description"
            type="text"
            onChange={(e) => handleChange(e)}
            error={error}
            value={meta_description}
            styles={{ height: "60px" }}
            isRequired={true}
          />
          <Flex justify="space-between" alignItems="center" mt={2}>
            <Button
              colorScheme="blue"
              size="sm"
              fontSize="sm"
              width="auto"
              px={4}
              onClick={handleGenerateMetaTitle}
            >
              Generate Description
            </Button>
            <Box>{meta_description?.length}/160</Box>
          </Flex>
        </Box>
      </Flex>
    );
  },
};

export default Agent;

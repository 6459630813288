import { Flex } from "@chakra-ui/react";
import DescriptionGenerator from "components/addForm/autoGenerateDescription";
import CalendarInput from "components/addForm/CalendarInput";
import AutoSuggestComponent from "components/addForm/location/locality";
import ValidatedInput from "components/addForm/ValidatedInput";
import Image from "components/addForm/imageUpload/index";
import CheckboxComponent from "components/addForm/Checkbox";
import { trade_license_registration } from "constants/dbEnums";
import { COMPANY_EXPERTISE_IN } from "constants/dbEnums";
import MultiSelect from "components/addForm/MultiSelect";
import ErrorMessage from "components/addForm/ErrorMessage";
import SearchInput from "components/addForm/searchInput";
import { getDataFromLocalStorage } from "utils/common";
import CitySelectInput from "components/addForm/location/citySelectInput";
import { LOCATION_TYPE } from "constants/dbEnums";

const Company = {
  user_id: props => {
    const {created_by} = props.formData;
    return (
      <SearchInput
          label='User Phone Number or ID'
          name="user_id"
          value={created_by}
          onChange={props.onChange}
          placeholder="Search by Phone Number"
          type="text"
          isRequired={true}
          error={props.error}
          setErrors={props.setErrors}
        />
    )
  },
  name: (props) => (
    <ValidatedInput
      name="name"
      label="Company Name"
      placeholder="Enter Company Name"
      type="text"
      isRequired={true}
      {...props}
    />
  ),
  registered_name: (props) => (
    <ValidatedInput
      name="registered_name"
      label="Registered Name"
      placeholder="Enter Registered Name"
      type="text"
      isRequired={true}
      {...props}
    />
  ),
  registration_date: (props) => (
    <CalendarInput
      name="registration_date"
      label="Registration Date"
      value={props.value}
      isRequired={true}
      error={props.error}
      {...props}
      disablePastDate={false}
    />
  ),
  cin: (props) => (
    <ValidatedInput
      name="cin"
      label="Corporate Identification Number(CIN)"
      placeholder="Enter Corporate Identification Number"
      type="text"
      {...props}
    />
  ),
  gst_no: (props) => (
    <ValidatedInput
      name="gst_no"
      label="GST No"
      placeholder="Enter gst no"
      type="text"
      {...props}
    />
  ),
  RERA_ID: (props) => (
    <ValidatedInput
      name="RERA_ID"
      label="RERA Id"
      placeholder="Enter RERA_ID"
      type="text"
      {...props}
    />
  ),
  rera_certificate: (props) => {
    const { formData, onChange, error } = props;
    const selectedCertificate = Array.isArray(formData?.rera_certificate) ? formData.rera_certificate : formData?.rera_certificate && formData.rera_certificate.trim() !== '' ? [formData.rera_certificate] : undefined;
    const handleChange = (e) => {
      const { name, value } = e.target;
      onChange({
        target: {
          name,
          value: value,
        },
      });
    };
    return (
      <>
        <Image
          onChange={(e) => handleChange(e)}
          name="rera_certificate"
          collectionType={formData.type}
          error={error}
          label="Upload RERA Certificate"
          maxLenght="1"
          accept="application/pdf"
          value={selectedCertificate}
        />
      </>
    );
  },
  gst_certificate: (props) => {
    const { formData, onChange, error } = props;
    const selectedCertificate = Array.isArray(formData?.gst_certificate) ? formData.gst_certificate : formData?.gst_certificate && formData.gst_certificate.trim() !== '' ? [formData.gst_certificate] : undefined;
    const handleChange = (e) => {
      const { name, value } = e.target;
      onChange({
        target: {
          name,
          value: value,
        },
      });
    };
    return (
      <>
        <Image
          onChange={(e) => handleChange(e)}
          name="gst_certificate"
          collectionType={formData.type}
          error={error}
          label="Upload GST Certificate"
          maxLenght="1"
          accept="application/pdf"
          value={selectedCertificate}
        />
      </>
    );
  },
  total_members: (props) => (
    <ValidatedInput
      name="total_members"
      label="Total Members"
      placeholder="Enter Total Members"
      type="text"
      {...props}
    />
  ),
  expertise_in: (props) => (
    <MultiSelect
      name="expertise_in"
      label="Expertise In"
      options={COMPANY_EXPERTISE_IN}
      onChange={props.onChange}
      displayValue="name"
      value={props.value}
      error={props.error}
    />
  ),
  website: (props) => (
    <ValidatedInput
      name="website"
      label="Website"
      placeholder="Enter Website Name"
      type="text"
      {...props}
    />
  ),
  trade_license_registration: (props) => {
    const { onChange, formData, error } = props;
    const handlChange = (e) => {
      const { name, checked } = e.target;
      onChange({
        target: {
          name: name,
          value: checked,
        },
      });
    };
    return (
      <>
        <CheckboxComponent
          options={trade_license_registration}
          name="trade_license_registration"
          onChange={(e) => handlChange(e)}
          label="Trade License Registration"
          textSize="lg"
          checkboxSize="lg"
          {...(formData?.trade_license_registration && {
            selectedValues: "true",
          })}
          error={error}
        />
      </>
    );
  },
  email: (props) => (
    <ValidatedInput
      name="email"
      label="Email"
      placeholder="Enter company email"
      type="text"
      {...props}
    />
  ),
  photo: (props) => {
    const { formData, onChange, error } = props;
    const selectedPhoto = Array.isArray(formData?.photo) ? formData.photo : formData?.photo && formData.photo.trim() !== '' ? [formData.photo] : undefined;
    const handleChange = (e) => {
      const { name, value } = e.target;
      onChange({
        target: {
          name,
          value: value,
        },
      });
    };
    return (
      <>
        <Image
          onChange={(e) => handleChange(e)}
          name="photo"
          collectionType={formData.type}
          error={error}
          label="Upload Company Photo"
          maxLenght="1"
          value={selectedPhoto}
        />
      </>
    );
  },
  address: (props) => {
    const { onChange, error, formData } = props;
    const selectedValue = formData.address && formData?.address?.city;
    const handleChange = (e) => {
      const { value: selectedValue } = e.target;
      const updatedValue = {
        city: selectedValue.name
      };
      onChange({
        target: {
          name: "address",
          value: updatedValue,
        },
      });
    };
    return (
      <>
        <CitySelectInput
          name="city"
          label="Address"
          onChange={(e) => handleChange(e)}
          isRequired={true}
          error={error?.city}
          selectedValue={selectedValue}
          result_type={LOCATION_TYPE.CITY}
        />
        {typeof error === "string" && <ErrorMessage error={error}/>}
      </>
    );
  },
  locality: (props) => {
    const { onChange, formData, error } = props;
    const topCities = getDataFromLocalStorage('topCities');
    const selectedValue = topCities?.find(
      (city) => city.name === formData?.address?.city
    );
    const handleChange = (e) => {
      const { name, value: selectedValue } = e.target;
      if (name === "locality") {
        const data = JSON.parse(selectedValue);
        onChange({
          target: {
            name: "address",
            value: data,
          },
        });
      } else {
        onChange({
          target: {
            name: "address",
            value: {
              ...formData.address,
              [name]: selectedValue,
            },
          },
        });
      }
    };
    return (
      <>
        <Flex gap={4}>
          <div stle={{ flex: 2 }}>
            <AutoSuggestComponent
              name="locality"
              selectedValue={formData?.address?.label}
              placeholder="Search by locality, area"
              onChange={(e) => handleChange(e)}
              label="Locality (Enter area or locality address)"
              isRequired={true}
              selectedCity={
                formData?.address?.city ? formData.address?.city : selectedValue?.city
              }
              error={error}
              location={formData.address}
              result_type={LOCATION_TYPE.LOCALITY}
            />
          </div>
          <div style={{ flex: 2 }}>
            <ValidatedInput
              name="building"
              placeholder="Enter society or area"
              type="text"
              onChange={(e) => handleChange(e)}
              label="Building (Enter appartment or area)"
              value={formData?.address?.building}
            />
          </div>
        </Flex>
      </>
    );
  },
  about: (props) => (
    <DescriptionGenerator
      onChange={props.onChange}
      formData={props.formData}
      name="about"
      isRequired={true}
      label="About"
      placeholder="Enter Company "
      showButton={false}
      maxLength={300}
      value={props?.formData?.about}
      error={props.error}
    />
  ),
};

export default Company;

import React, { useCallback, useEffect, useState } from "react";
import MultiSelect from "../MultiSelect";
import { PROJECT_PROPERTY_TYPE_TAGS } from "constants/dbEnums";
import ValidatedInput from "../ValidatedInput";
import { Box, Button, Flex, FormLabel, VStack } from "@chakra-ui/react";
import SelectInput from "../SelectInput";
import { MONTHS_TAGS } from "constants/dbEnums";
import ErrorMessage from "../ErrorMessage";
import AutoSuggestComponent from "../location/locality";
import DynamicInputField from "../DynamicInputField";
import Image from "../imageUpload/index";
import { PROJECT_AREA_UNIT_TAGS } from "constants/dbEnums";
import { PROJECT_STATUS_TAGS } from "constants/dbEnums";
import { PROJECT_AMENITIES_TAGS } from "constants/dbEnums";
import { RERA_STATUS_TAGS } from "constants/dbEnums";
import { FURNISH_TYPE } from "constants/dbEnums";
import { BHK } from "constants/dbEnums";
import { AREA_UNIT } from "constants/dbEnums";
import { getTagsForSelectedTypes } from "views/admin/form/formHandlers";
import { shortRandomIdGenerator } from "utils/common";
import { SLUG_ROLE_TYPES } from "constants/dbEnums";
import { createMetaTitle } from "utils/common";
import { createMetaDescription } from "utils/common";
import useApi from "hooks/useApi";
import { GET_STATIC_DATA_API } from "constants/endpoints";
import toast from "react-hot-toast";
import { getDataFromLocalStorage } from "utils/common";
import CitySelectInput from "../location/citySelectInput";
import { LOCATION_TYPE } from "constants/dbEnums";

const generateSlug = (name) => {
  const uid = shortRandomIdGenerator();
  const project_name = name?.toLowerCase().replace(/ /g, "-");
  const slug_url = `${project_name}-${SLUG_ROLE_TYPES.PROJECT}-${uid}`;
  return {uid, slug_url};
};

export const LoanOffers = (props) => {
  const { onChange, name, formData, error } = props;
  const [bankList, setBankList] = useState([]);
  const { apiCall } = useApi();

  const fetchBankList = useCallback(async () => {
    try {
      const response = await apiCall.get(GET_STATIC_DATA_API);
      setBankList(response?.data);
    } catch (err) {
      console.error("Error on fetching the bank list", err);
      toast.error(err);
    }
  }, [apiCall]);
  useEffect(() => {
    fetchBankList();
  }, []);
  const handleChange = (e) => {
    const { name, value } = e.target;
    const updatedValue = value?.map((item) => {
      const bankData = bankList?.find((bank) => bank.id === Number(item.name));
      return {
        name: item.name,
        image: bankData?.image,
      };
    });
    onChange({
      target: {
        name,
        value: updatedValue,
      },
    });
  };
  return (
    <VStack spacing={4} align="stretch">
      <FormLabel htmlFor={name}>Loan Offers</FormLabel>
      <DynamicInputField
        formData={{ [name]: formData[name] }}
        onChange={handleChange}
        name={name}
        fields={[
          {
            name: "image",
            type: "image",
            label: "Image"
          },
          {
            name: "name",
            placeholder: "Enter name",
            type: "select",
            label: "Name",
            option: bankList,
          },
        ]}
        showAddIcon={true}
        showDeleteIcon={true}
        getList={true}
      />
      {typeof error === "string" && <ErrorMessage error={error} />}
    </VStack>
  );
};

const projectsForm = {
  type: (props) => (
    <MultiSelect
      name="type"
      label="Project Type"
      options={PROJECT_PROPERTY_TYPE_TAGS}
      displayValue="name"
      value={props.value}
      onChange={props.onChange}
      isRequired={true}
      error={props.error}
    />
  ),
  property_type: (props) => {
    const { formData } = props;
    const PROJECT_PROPERTIES_ARRAY = getTagsForSelectedTypes(formData.type);
    return (
      <MultiSelect
        name="property_type"
        label="Project Property"
        options={PROJECT_PROPERTIES_ARRAY}
        displayValue="name"
        value={props.value}
        onChange={props.onChange}
        error={props.error}
      />
    );
  },
  name_and_about: (props) => {
    const { onChange, formData, error } = props;
    const handleChange = (e) => {
      const { name, value } = e.target;
      if (name === "name" && !formData?.slug_updated) {
        const { uid, slug_url } = generateSlug(value);
        onChange({
          target: {
            name: "slug_url",
            value: slug_url,
          },
        });
        onChange({
          target: {
            name: "uid",
            value: uid,
          },
        });
      }
      onChange({
        target: {
          name,
          value,
        },
      });
    };
    return (
      <Flex justify="space-between" mb={4}>
        <Box flex="1" mr={2}>
          <ValidatedInput
            name="name"
            label="Name"
            placeholder="Project name"
            type="text"
            isRequired={true}
            onChange={(e) => handleChange(e)}
            error={error?.name}
            value={formData?.name}
          />
        </Box>
        <Box flex="1" ml={2}>
          <ValidatedInput
            name="about"
            label="About"
            placeholder="Enter about"
            type="text"
            isRequired={true}
            onChange={(e) => handleChange(e)}
            error={error?.about}
            value={formData?.about}
          />
        </Box>
      </Flex>
    );
  },
  slug_url: (props) => {
    const { onChange, formData, error } = props;
    const handleChange = (e) => {
      const { name, value } = e.target;
      if (!formData?.uid) {
        const uid = shortRandomIdGenerator();
        onChange({
          target: {
            name: "uid",
            value: uid,
          },
        });
      }
      onChange({
        target: {
          name,
          value,
        },
      });
      onChange({
        target: {
          name: "slug_updated",
          value: true,
        },
      });
    };
    return (
      <ValidatedInput
        name="slug_url"
        label="Slug Url"
        placeholder="Add Slug Url"
        type="text"
        isRequired={true}
        onChange={(e) => handleChange(e)}
        value={formData.slug_url}
        error={error}
      />
    );
  },
  project_status: (props) => (
    <SelectInput
      name="project_status"
      label="Project Status"
      options={PROJECT_STATUS_TAGS}
      onChange={props.onChange}
      isRequired={true}
      error={props?.error}
      selectedValue={props?.formData?.project_status}
    />
  ),
  possession_by: (props) => {
    const { onChange, formData, name, error } = props;
    const handleChange = (e) => {
      const { name: selectedName, value } = e.target;
      onChange({
        target: {
          name,
          value: {
            ...formData.possession_by,
            [selectedName]: value,
          },
        },
      });
    };
    return (
      <>
        <Flex justify="space-between" mb={4}>
          <Box flex="1" mr={formData.ad_type === "sell" ? 2 : 0}>
            <SelectInput
              name="month"
              label="Possession Period Month"
              options={MONTHS_TAGS}
              onChange={(e) => handleChange(e)}
              error={error?.month}
              selectedValue={formData?.possession_by?.month}
              isRequired={true}
            />
          </Box>
          <Box flex="1" ml={2}>
            <ValidatedInput
              name="year"
              label="Possession Period Year"
              placeholder="Enter possession period year"
              type="number"
              onChange={(e) => handleChange(e)}
              error={error?.year}
              value={formData?.possession_by?.year}
              isRequired={true}
            />
          </Box>
        </Flex>
        {typeof error === "string" && <ErrorMessage error={error} />}
      </>
    );
  },
  launch_date: (props) => {
    const { onChange, formData, name, error } = props;
    const handleChange = (e) => {
      const { name: selectedName, value } = e.target;
      onChange({
        target: {
          name,
          value: {
            ...formData.launch_date,
            [selectedName]: value,
          },
        },
      });
    };
    return (
      <>
        <Flex justify="space-between" mb={4}>
          <Box flex="1" mr={formData.ad_type === "sell" ? 2 : 0}>
            <SelectInput
              name="month"
              label="Launch Month"
              options={MONTHS_TAGS}
              onChange={(e) => handleChange(e)}
              isRequired={true}
              error={error?.month}
              placeholder="Select Launch Month"
              selectedValue={formData?.launch_date?.month}
            />
          </Box>
          <Box flex="1" ml={2}>
            <ValidatedInput
              name="year"
              label="Launch Year"
              placeholder="Enter launch year"
              type="number"
              onChange={(e) => handleChange(e)}
              isRequired={true}
              error={error?.year}
              value={formData?.launch_date?.year}
            />
          </Box>
        </Flex>
        {typeof error === "string" && <ErrorMessage error={error} />}
      </>
    );
  },
  icon_and_brochure: (props) => {
    const { onChange, formData, error } = props;
    const selectedIcon = Array.isArray(formData?.icon) ? formData.icon : formData?.icon && formData.icon.trim() !== '' ? [formData.icon] : undefined;
    const selectedBrochure = Array.isArray(formData?.brochure) ? formData.brochure : formData?.brochure && formData.brochure.trim() !== '' ? [formData.brochure] : undefined;
    const handleChange = (e) => {
      const { name: selectedName, value } = e.target;
      onChange({
        target: {
          name: selectedName,
          value: value,
        },
      });
    };
    return (
      <Flex justify="space-between" mb={4}>
        <Box flex="1" mr={formData.ad_type === "sell" ? 2 : 0}>
          <Image
            onChange={(e) => handleChange(e)}
            name="icon"
            collectionType={formData.type}
            label="Project Icon"
            maxLenght="1"
            error={error?.icon}
            value={selectedIcon}
          />
        </Box>
        <Box flex="1" ml={2}>
          <Image
            onChange={(e) => handleChange(e)}
            name="brochure"
            collectionType={formData.type}
            label="Project Brochure"
            accept="application/pdf"
            maxLenght="1"
            error={error?.brochure}
            value={selectedBrochure}
          />
        </Box>
      </Flex>
    );
  },
  area: (props) => {
    const { onChange, formData, name: selectedName, error } = props;
    const handleChange = (e) => {
      const { name, value } = e.target;
      const updatedValue = {
        ...formData.area,
        [name]: value,
      };
      onChange({
        target: {
          name: selectedName,
          value: updatedValue,
        },
      });
    };
    return (
      <>
        <Flex>
          <div style={{ flex: 3 }}>
            <ValidatedInput
              name="size"
              placeholder="Enter Build up area"
              type="number"
              onChange={(e) => handleChange(e)}
              label="Project Area"
              isRequired={true}
              error={error?.size}
              value={formData?.area?.size}
            />
          </div>
          <div style={{ flex: 2 }}>
            <SelectInput
              name="unit"
              label="Unit"
              options={PROJECT_AREA_UNIT_TAGS}
              selectedValue={formData?.area?.unit}
              onChange={(e) => handleChange(e)}
              error={error?.unit}
              isRequired={true}
            />
          </div>
        </Flex>
        {typeof error === "string" && <ErrorMessage error={error} />}
      </>
    );
  },
  units_and_towers: (props) => {
    const { onChange, error, formData } = props;
    const handleChange = (e) => {
      const { name, value } = e.target;
      onChange({
        target: {
          name,
          value,
        },
      });
    };
    return (
      <Flex justify="space-between" mb={4}>
        <Box flex="1" mr={2}>
          <ValidatedInput
            name="units"
            label="Total Available Units"
            placeholder="Select units"
            type="text"
            isRequired={true}
            onChange={(e) => handleChange(e)}
            error={error?.units}
            value={formData?.units}
          />
        </Box>
        <Box flex="1" ml={2}>
          <ValidatedInput
            name="towers"
            label="Total Towers"
            placeholder="Enter Towers"
            type="text"
            onChange={(e) => handleChange(e)}
            error={error?.towers}
            value={formData?.towers}
          />
        </Box>
      </Flex>
    );
  },
  amenities: (props) => (
    <MultiSelect
      name="amenities"
      label="Amenities"
      options={PROJECT_AMENITIES_TAGS}
      displayValue="name"
      value={props.value}
      onChange={props.onChange}
      isRequired={true}
      error={props?.error}
    />
  ),
  photos: (props) => {
    const { formData, onChange, error } = props;
    const handleChange = (e) => {
      const { name, value } = e.target;
      onChange({
        target: {
          name,
          value: value,
        },
      });
    };
    return (
      <>
        <Image
          onChange={(e) => handleChange(e)}
          name="photos"
          collectionType={formData.type}
          error={error}
          label="Upload Project Photos"
          value={formData?._id ? formData?.photos : []}
        />
      </>
    );
  },
  address: (props) => {
    const { onChange, error, formData } = props;
    const selectedValue = formData?.address && formData?.address?.city;
    const handleChange = (e) => {
      const { value: selectedValue } = e.target;
      const updatedValue = {
        city: selectedValue.name
      };
      onChange({
        target: {
          name: "address",
          value: updatedValue,
        },
      });
    };
    return (
      <>
        <CitySelectInput
          name="city"
          label="Location"
          onChange={(e) => handleChange(e)}
          isRequired={true}
          error={error?.city}
          selectedValue={selectedValue}
          result_type={LOCATION_TYPE.CITY}
        />
        {typeof error === "string" && <ErrorMessage error={error} />}
      </>
    );
  },
  locality: (props) => {
    const { onChange, formData, error } = props;
    const topCities = getDataFromLocalStorage('topCities');
    const selectedValue = topCities?.find((city) => city.name === formData?.address?.city);
    const handleChange = (e) => {
      const { name, value: selectedValue } = e.target;
      if (name === "locality") {
        const data = JSON.parse(selectedValue);
        onChange({
          target: {
            name: "address",
            value: data,
          },
        });
      } else {
        onChange({
          target: {
            name: "address",
            value: {
              ...formData.address,
              [name]: selectedValue,
            },
          },
        });
      }
    };
    return (
      <>
        <Flex gap={4}>
          <div stle={{ flex: 2 }}>
            <AutoSuggestComponent
              name="locality"
              selectedValue={formData?.address?.label}
              placeholder="Search by locality, area"
              onChange={(e) => handleChange(e)}
              label="Locality (Area or Locality Address)"
              isRequired={true}
              selectedCity={formData?.address?.city ? formData.address?.city : selectedValue?.city}
              error={error}
              location={formData.address}
              result_type={LOCATION_TYPE.LOCALITY}
            />
          </div>
          <div style={{ flex: 2 }}>
            <ValidatedInput
              name="building"
              placeholder="Enter society or area"
              type="text"
              onChange={(e) => handleChange(e)}
              label="Building (Appartment or Area)"
              value={formData?.address?.building}
            />
          </div>
        </Flex>
      </>
    );
  },
  RERA_ID: (props) => {
    const { formData, onChange, name, error } = props;
    const handleChange = (e) => {
      const { value } = e.target;
      onChange({
        target: {
          name,
          value: value,
        },
      });
    };
    return (
      <VStack spacing={4} align="stretch">
        <FormLabel htmlFor={name}>
          RERA ID
          <Box as="span" color="red.500" ml={1}>
            *
          </Box>
        </FormLabel>
        <DynamicInputField
          formData={{ [name]: formData[name] }}
          onChange={handleChange}
          name={name}
          fields={[
            {
              name: "id",
              placeholder: "Enter RERA ID",
              type: "text",
              isRequired: true,
              label: "Id",
            },
            {
              name: "status",
              placeholder: "Select Status",
              type: "select",
              isRequired: true,
              label: "Status",
              option: RERA_STATUS_TAGS,
            },
            {
              name: "phase",
              placeholder: "Enter RERA Phase",
              type: "text",
              isRequired: true,
              label: "Phase",
            },
          ]}
          showAddIcon={true}
          showDeleteIcon={true}
        />
        {typeof error === "string" && <ErrorMessage error={error} />}
      </VStack>
    );
  },
  additional_charges: (props) => {
    const { formData, onChange, name, error } = props;
    const handleChange = (e) => {
      const { value } = e.target;
      onChange({
        target: {
          name,
          value: value,
        },
      });
    };
    return (
      <VStack spacing={4} align="stretch">
        <FormLabel htmlFor={name}>Additional Charges</FormLabel>
        <DynamicInputField
          formData={{ [name]: formData[name] }}
          onChange={handleChange}
          name={name}
          fields={[
            {
              name: "key",
              placeholder: "Enter additional charges",
              type: "text",
              label: "key",
            },
            {
              name: "value",
              placeholder: "Enter value",
              type: "text",
              label: "Value",
              isRequired: true,
            },
          ]}
          showAddIcon={true}
          showDeleteIcon={true}
        />
        {typeof error === "string" && <ErrorMessage error={error} />}
      </VStack>
    );
  },
  loan_offers: LoanOffers,
  bathrooms_and_bhk_furnish_type: (props) => {
    const { formData, onChange, name } = props;
    const fields = [
      {
        name: "bathrooms",
        type: "text",
        label: "Bathrooms",
        placeholder: "Enter no. of bathrooms",
      },
      {
        name: "bhk",
        type: "select",
        label: "BHK",
        placeholder: "Select bhk type",
        option: BHK,
      },
      {
        name: "furnish_type",
        type: "select",
        label: "Furnish Type",
        placeholder: "Select Furnish Type",
        option: FURNISH_TYPE,
      },
    ];
    const handleChange = (e) => {
      const { value } = e.target;
      onChange({
        target: {
          name: "floor_plan_and_pricing",
          value: value,
        },
      });
    };
    return (
      <VStack spacing={4} align="stretch">
        <FormLabel htmlFor={name}>Floor Plan And Pricing</FormLabel>
        <DynamicInputField
          formData={formData}
          onChange={handleChange}
          name="floor_plan_and_pricing"
          fields={fields}
          options={{
            furnish_type: FURNISH_TYPE,
            bhk: BHK,
          }}
          showAddIcon={true}
          showDeleteIcon={true}
        />
      </VStack>
    );
  },
  area_and_available_units: (props) => {
    const { formData, onChange } = props;
    const fields = [
      {
        name: "area",
        type: "object",
        label: "Area",
        schema: {
          size: {
            type: "text",
            label: "Area Size",
            placeholder: "Enter Size",
            isRequired: true,
          },
          unit: {
            type: "select",
            label: "Area Unit",
            placeholder: "Enter Unit",
            option: AREA_UNIT,
            isRequired: true,
          },
        },
      },
      {
        name: "available_units",
        type: "number",
        label: "Available Units",
        placeholder: "Enter Units",
        isRequired: true,
        valueInNumber: true,
      },
    ];
    const handleChange = (e) => {
      const { value } = e.target;
      console.log("value", value);
      onChange({
        target: {
          name: "floor_plan_and_pricing",
          value: value,
        },
      });
    };
    return (
      <VStack spacing={4} align="stretch">
        <DynamicInputField
          formData={formData}
          onChange={handleChange}
          name="floor_plan_and_pricing"
          fields={fields}
        />
      </VStack>
    );
  },
  rent_price_and_selling_price: (props) => {
    const { formData, onChange } = props;
    const fields = [
      {
        name: "rent_price",
        type: "number",
        label: "Rent Price",
        placeholder: "Enter Rent Price",
        valueInNumber: true,
      },
      {
        name: "selling_price",
        type: "number",
        label: "Selling Price",
        placeholder: "Enter Selling Price",
        valueInNumber: true,
      },
    ];
    const handleChange = (e) => {
      const { value } = e.target;
      onChange({
        target: {
          name: "floor_plan_and_pricing",
          value: value,
        },
      });
    };
    return (
      <VStack spacing={4} align="stretch">
        <DynamicInputField
          formData={formData}
          onChange={handleChange}
          name="floor_plan_and_pricing"
          fields={fields}
        />
      </VStack>
    );
  },
  image_and_property_type: (props) => {
    const { formData, onChange } = props;
    const PROJECT_PROPERTIES_ARRAY = getTagsForSelectedTypes(formData.type);
    const fields = [
      {
        name: "image",
        type: "image",
        label: "Floor Plan Image",
        placeholder: "Enter Image",
        maxLenght: "1",
      },
      {
        name: "property_type",
        type: "select",
        label: "Property Type",
        placeholder: "Select Property Type",
        option: PROJECT_PROPERTIES_ARRAY,
        isRequired: true,
      },
    ];
    const handleChange = (e) => {
      const { value } = e.target;
      onChange({
        target: {
          name: "floor_plan_and_pricing",
          value: value,
        },
      });
    };
    return (
      <VStack spacing={4} align="stretch">
        <DynamicInputField
          formData={formData}
          onChange={handleChange}
          name="floor_plan_and_pricing"
          fields={fields}
        />
      </VStack>
    );
  },
  meta_title: (props) => {
    const { onChange, formData, error } = props;
    const handleChange = (e) => {
      const { name, value } = e.target;
      onChange({ target: { name, value } });
    };
    const handleGenerateMetaTitle = () => {
      const { name = "", address = {}, property_type = [] } = formData;
      const generatedMetaTitle = createMetaTitle(name, address, property_type);
      onChange({ target: { name: "meta_title", value: generatedMetaTitle } });
    };
    return (
      <Flex justify="space-between" mb={4}>
        <Box flex="1" mr={2}>
          <ValidatedInput
            name="meta_title"
            label="Meta Title"
            placeholder="Project name"
            type="text"
            onChange={(e) => handleChange(e)}
            error={error}
            value={formData?.meta_title}
            styles={{ height: "60px" }}
          />
          <Button
            colorScheme="blue"
            size="sm"
            fontSize="sm"
            width="auto"
            px={4}
            onClick={handleGenerateMetaTitle}
          >
            Generate Title
          </Button>
        </Box>
      </Flex>
    );
  },
  meta_description: (props) => {
    const { onChange, formData, error } = props;
    const handleChange = (e) => {
      const { name, value } = e.target;
      onChange({ target: { name, value } });
    };
    const handleGenerateMetaTitle = () => {
      const generatedMetaDescription = createMetaDescription(formData);
      onChange({
          target: {
              name: "meta_description",
              value: generatedMetaDescription
          },
      });
    };
    return (
      <Flex justify="space-between" mb={4}>
        <Box flex="1" mr={2}>
          <ValidatedInput
            name="meta_description"
            label="Meta Description"
            placeholder="Enter about"
            type="text"
            onChange={(e) => handleChange(e)}
            error={error}
            value={formData?.meta_description}
            styles={{ height: "60px" }}
          />
          <Button
            colorScheme="blue"
            size="sm"
            fontSize="sm"
            width="auto"
            px={4}
            onClick={handleGenerateMetaTitle}
          >
            Generate Description
          </Button>
        </Box>
      </Flex>
    );
  },
};

export default projectsForm;

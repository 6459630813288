import React, { useEffect, useState } from 'react';
import { Box, FormControl, FormLabel, Input, Flex } from '@chakra-ui/react';
import Multiselect from 'multiselect-react-dropdown';
import ErrorMessage from './ErrorMessage';
import { SmallCloseIcon } from '@chakra-ui/icons'; 

const MultiSelect = ({
  name,
  onChange,
  label,
  options,
  isRequired,
  displayValue,
  value,
  error,
  fetchSuggestions
}) => {
  const [selectedItems, setSelectedItems] = useState([]);

  useEffect(() => {
    if(!selectedItems?.length){
      const initialSelectedItems = options.filter(option => value?.includes(option.id)).map(option => ({ ...option, inputValue: '' }));
      setSelectedItems(initialSelectedItems);
    }
  }, [value, options]);

  /**DO not remove selected list. props is passing here**/ 
  const handleSelect = (selectedList, selectedItem) => {
    if (!selectedItems.some(item => item.id === selectedItem.id)) {
      const updatedItems = [...selectedItems, { ...selectedItem, inputValue: '' }];
      setSelectedItems(updatedItems);
      const updatedValue = updatedItems.map(item => item.id);
      const customEvent = {
        target:{
          name,
          value: updatedValue
        }
      }
    onChange(customEvent);
    };
  };

  /**DO not remove selected list. props is passing here**/
  const handleRemove = (selectedList, removedItem) => {
    const updatedItems = selectedItems.filter(item => item.id !== removedItem.id);
    setSelectedItems(updatedItems);
    const updatedValue = updatedItems.map(item => item.id);
    const customEvent = {
      target: {
        name,
        value: updatedValue
      }
    };
    onChange(customEvent);
  };

  const handleInputChange = (id, newValue) => {
    const updatedItems = selectedItems.map(item =>
      item.id === id ? { ...item, inputValue: newValue } : item
    );
    setSelectedItems(updatedItems);
  };

  return (
    <FormControl>
      <FormLabel htmlFor={name}>
        {label}
        {isRequired && (
          <Box as="span" color="red.500" ml={1}>
            *
          </Box>
        )}
      </FormLabel>
      <Multiselect
        options={options}
        displayValue={displayValue}
        onSelect={handleSelect}
        onRemove={handleRemove}
        selectedValues={selectedItems}
        customCloseIcon={<span style={{ cursor: 'pointer' }}><SmallCloseIcon/></span>}
        optionRenderer={({ option }) => (
          <Flex align="center">
            <Box mr={2}>{option[displayValue]}</Box>
            {selectedItems.some(item => item.id === option.id) && (
              <Input
                size="sm"
                placeholder="Enter quantity"
                value={selectedItems.find(item => item.id === option.id)?.inputValue || ''}
                onChange={(e) => handleInputChange(option.id, e.target.value)}
              />
            )}
          </Flex>
        )}
        onSearch={(searchValue) => {
          if (fetchSuggestions && searchValue) {
            fetchSuggestions(searchValue);
          }
        }}
      />
      <ErrorMessage error={error}/>
    </FormControl>
  );
};

export default MultiSelect;

import React from "react";

import {
  Modal,
  ModalOverlay,
  ModalContent,
  Spinner,
  Center,
  Flex,
} from "@chakra-ui/react";

export const BasicLoaderComponent = () => {
  return (
    <Flex height="70vh" alignItems="center" justifyContent="center">
      <Spinner
        thickness="4px"
        speed="0.65s"
        emptyColor="gray.200"
        color="blue.500"
        size="xl"
      />
    </Flex>
  );
};

export const OverlayLoaderComponent = ({ isOpen }) => {
  return (
    <Modal isOpen={isOpen} onClose={() => {}} isCentered>
      <ModalOverlay bg="blackAlpha.600" backdropFilter="blur(1px)" />
      <ModalContent
        bg="transparent"
        boxShadow="none"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Center>
          <Spinner size="lg" color="#22acee" thickness="6px" />
        </Center>
      </ModalContent>
    </Modal>
  );
};

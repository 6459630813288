import { toast } from 'react-hot-toast'; // Adjust import based on your toast library
import { CheckIcon, NotAllowedIcon } from '@chakra-ui/icons';

const CustomToast = ({ message, icon, customStyle = {}, success }) => {
  const defaultStyle = {
    backgroundColor: '#00000060',

    maxWidth: '450px',

    height: 'auto',

    display: 'flex',
    alignItems: 'center',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    borderRadius: '8px',
    ...customStyle,
  };

  const toastContainerStyle = {
    pointerEvents: 'auto',
    display: 'flex',
    border: '1px solid rgba(0, 0, 0, 0.05)', // ring-1, ring-black, ring-opacity-5
    paddingTop: '0.75rem', // py-3
    paddingBottom: '0.75rem', // py-3
    paddingLeft: '1.25rem', // px-5
    paddingRight: '1.25rem', // px-5
  };

  return (
    <div style={{ ...defaultStyle, toastContainerStyle }}>
      {icon && <div className="flex-shrink-0 pt-0.5">{icon}</div>}
      {success !== undefined && (success ? <CheckIcon /> : <NotAllowedIcon /> )}
      <div className="ml-3 flex-1 ">
        <p className="text-sm text-white font-medium whitespace-nowrap">
          {message}
        </p>
      </div>
    </div>
  );
};

export const showToast = ({ message, icon, customStyle = {}, success }) => {
  toast.custom(() => (
    <CustomToast message={message} icon={icon} customStyle={customStyle} success={success}/>
  ));
};

import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  FormControl,
  FormLabel,
  Input,
  List,
  ListItem,
} from "@chakra-ui/react";
import ErrorMessage from "../ErrorMessage";
import { getDataFromLocalStorage } from "utils/common";
import { STATIC_CONTENT_DATA_API } from "constants/endpoints";
import useApi from "hooks/useApi";
import debounce from "lodash.debounce";
import { GET_AUTO_COMPLETE } from "constants/endpoints";
import { LOCATION_TYPE } from "constants/dbEnums";
import { getTopCities } from "utils/common";

const CitySelectInput = ({
  name,
  onChange,
  label,
  isRequired,
  selectedValue = "",
  error,
  disabled,
  result_type = LOCATION_TYPE.CITY
}) => {
  const [options, setOptions] = useState([]);
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [searchText, setSearchText] = useState(selectedValue);
  const [showOptions, setShowOptions] = useState(false);
  const { apiCall } = useApi();

  useEffect(() => {
    setSearchText(selectedValue);
  }, [selectedValue]);

  useEffect(() => {
    const fetchCities = async () => {
      try {
        const cities = await getTopCities();
        setOptions(cities);
        setFilteredOptions(cities);
      } catch (error) {
        console.error("Error fetching cities:", error);
        setOptions([]);
      }
    };

    fetchCities();
  }, [getTopCities]);

  const searchCities = useCallback(
    debounce(async (query) => {
      try {
        if (query.trim() && query?.length >=3 ) {
          const url = `${GET_AUTO_COMPLETE}?q=${query}&result_type=${result_type}`;
          const response = await apiCall.get(url);
          const data = response?.data || [];
          setFilteredOptions(data);
        }
      } catch (error) {
        console.error("Error fetching cities:", error);
      }
    }, 500),
    [apiCall]
  );

  const handleSearchChange = async (e) => {
    const value = e.target.value;
    setSearchText(value);
    setShowOptions(true);
    if (value) {
      const filtered = options.filter((city) =>
        city.title.toLowerCase().includes(value.toLowerCase())
      );
      setFilteredOptions(filtered);
    } else {
      setFilteredOptions(options);
    }
  };

  useEffect(() => {
    if (typeof searchText === 'string' && searchText?.trim() && filteredOptions.length === 0) {
      searchCities(searchText);
    }
    return () => searchCities.cancel();
  }, [searchText, filteredOptions]);

  const handleSelectOption = (selectedOption) => {
    const updatedOption = {
      ...selectedOption,
      name: selectedOption.title,
    };
    setSearchText(updatedOption.name);
    setShowOptions(false);
    const customEvent = {
      target: {
        name,
        value: updatedOption,
      },
    };
    onChange(customEvent);
  };

  const handleMouseDown = (e) => {
    e.preventDefault();
  };

  return (
    <FormControl>
      <FormLabel htmlFor={name}>
        {label}
        {isRequired && (
          <Box as="span" color="red.500" ml={1}>
            *
          </Box>
        )}
      </FormLabel>
      <Input
        id={name}
        name={name}
        value={searchText}
        onChange={handleSearchChange}
        placeholder="Search or select a city"
        isDisabled={disabled}
        onFocus={() => setShowOptions(true)}
        onBlur={() => setTimeout(() => setShowOptions(false), 200)}
      />
      {showOptions && (
        <Box
          borderWidth="1px"
          borderRadius="md"
          mt="2"
          bg="white"
          maxH="200px"
          overflowY="auto"
          boxShadow="md"
          position="absolute"
          zIndex="10"
          width="100%"
        >
          <List spacing={0}>
            {filteredOptions.length > 0 ? (
              filteredOptions.map((option) => (
                <ListItem
                  key={option.id}
                  padding="8px"
                  _hover={{ background: "gray.100", cursor: "pointer" }}
                  onMouseDown={handleMouseDown}
                  onClick={() => handleSelectOption(option)}
                >
                  {option.title}
                </ListItem>
              ))
            ) : (
              <ListItem padding="8px" color="gray.500">
                No cities available
              </ListItem>
            )}
          </List>
        </Box>
      )}
      <ErrorMessage error={error} />
    </FormControl>
  );
};

export default CitySelectInput;

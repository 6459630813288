import { Button, Flex, Text } from '@chakra-ui/react';
import StatusBadge from 'components/badge/StatusBadge';
import TooltipModal from 'components/modal/TooltipModal';
import CustomTooltip from 'components/tooltip/CustomTooltip';

export const propertiesColumn = [
  {
    Header: 'SID',
    accessor: 'sid',
  },
  {
    Header: 'SLUG',
    accessor: 'slug_url',
    Cell: ({ value }) => (
      <a
        _hover={{ textDecoration: 'underline' }}
        href={`${process.env.REACT_APP_HEXAHOME_BASE_URL}/property-details/${value}`}
        target="_blank"
        rel="noreferrer"
        cursor="pointer"
        whiteSpace={'nowrap'}
        style={{ fontSize: '11px', fontWeight: 600, textAlign: 'left' }}
      >
        {value}
      </a>
    ),
  },
  {
    Header: 'PROPERTY STATUS',
    accessor: 'property_status',
    Cell: ({ value }) => (
      <Flex align="center" justify={'center'}>
        <Text>
          <StatusBadge status={value} />
        </Text>
      </Flex>
    ),
  },



  {
    Header: 'STATS',
    accessor: 'user_id',
    Cell: (props) => {

      const { _id } = props?.row.original;

      return (
        <a
          _hover={{ textDecoration: 'underline' }}
          href={`/#/admin/users/${props?.value}?adId=${_id}`}
          target="_blank"
          rel="noreferrer"
          cursor="pointer"
          whiteSpace={'nowrap'}
          style={{ fontSize: '11px', fontWeight: 600, textAlign: 'left' }}
        >
          <Button colorScheme="purple" size="xs">
            view stats
          </Button>
        </a>
      );
    },
  },
  {
    Header: 'LISTING SCORE',
    accessor: 'listing_score',
  },
  {
    Header: 'META TITLE',
    accessor: 'meta_title',
  },
  {
    Header: 'META DESCRIPTION',
    accessor: 'meta_description',
    Cell: ({ value }) => (
      <Flex align="center" justify="center">
        <Text cursor="pointer" whiteSpace={'nowrap'}>
          {value.slice(0, 10) + '...'}
        </Text>

        <TooltipModal dataLabel={value} title="Ads Meta Description" />
      </Flex>
    ),
  },

  {
    Header: 'LOCATION',
    accessor: 'location',
    Cell: ({ value }) => (
     
      <Text
        cursor="pointer"
        whiteSpace={'nowrap'}
        style={{ fontSize: '11px', fontWeight: 600, textAlign: 'left' }}
      >
        {value}
      </Text>

    ),
  },

  

  {
    Header: 'TRANSACTION TYPE',
    accessor: 'collection_type',
  },
  {
    Header: 'AD TYPE',
    accessor: 'ad_type',
  },
  {
    Header: 'PROPERTY TYPE',
    accessor: 'property_type',
  },
  {
    Header: 'POSTED ON',
    accessor: 'created_at',
  },

  {
    Header: 'EXPIRES ON',
    accessor: 'expires_on',
  },

  {
    Header: 'OWNER EMAIL',
    accessor: 'owner_email',
    Cell: ({ value }) => (
      <Flex align="center" justify={'center'}>
        <Text
          
          cursor="pointer"
          whiteSpace={'nowrap'}
        >
          {value !== '-' ? value.slice(0, 10) + '...' : value}
        </Text>
        {value !== '-' ? <CustomTooltip dataLabel={value} /> : null}
      </Flex>
    ),
  },
  {
    Header: 'OWNER',
    accessor: 'owner_phone',
  },
  
];

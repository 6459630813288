import React from "react";
import {
  Box,
  Flex,
  IconButton,
  Input,
  VStack,
  FormLabel,
  Select,
} from "@chakra-ui/react";
import { AddIcon, DeleteIcon } from "@chakra-ui/icons";
import Image from "../addForm/imageUpload/index";

const getDefaultItem = (fields) => {
  const defaultItem = {};
  fields.forEach((field) => {
    if (field.type === "object" && field.schema) {
      defaultItem[field.name] = getDefaultItem(
        Object.keys(field.schema).map((key) => ({
          name: key,
          ...field.schema[key],
        }))
      );
    } else {
      defaultItem[field.name] = "";
    }
  });
  return defaultItem;
};

const DynamicInputField = ({
  formData,
  onChange,
  name,
  fields,
  showAddIcon = false,
  showDeleteIcon = false
}) => {
  const items = formData[name] || [getDefaultItem(fields)];

  const handleChange = (index, fieldName, value) => {
    const updatedItems = items.map((item, i) => {
      if (i === index) {
        if (fieldName.includes(".")) {
          const [parentField, childField] = fieldName.split(".");
          return {
            ...item,
            [parentField]: {
              ...item[parentField],
              [childField]: value,
            },
          };
        } else {
          return { ...item, [fieldName]: value };
        }
      }
      return item;
    });
    onChange({
      target: {
        name,
        value: updatedItems,
      },
    });
  };

  const handleAdd = () => {
    onChange({
      target: {
        name,
        value: [...items, getDefaultItem(fields)],
      },
    });
  };

  const handleRemove = (index) => {
    if (items.length > 1) {
      const updatedItems = items.filter((_, i) => i !== index);
      onChange({
        target: {
          name,
          value: updatedItems,
        },
      });
    }
  };

  return (
    <VStack spacing={4} align="start">
      {items.map((item, index) => (
        <Flex key={index} justify="space-between" mb={4} align="center" gap={4}>
          {fields.map((field) => (
            <Box flex="1" ml={index > 0 ? 2 : 0} key={field.name}>
              <FormLabel htmlFor={field.name}>
                {field.type !== "object" &&
                  field.type !== "image" &&
                  field.label}
                {field.isRequired && field.type !== "object" && (
                  <Box as="span" color="red.500" ml={1}>
                    *
                  </Box>
                )}
              </FormLabel>
              {field.type === "select" ? (
                <Select
                  name={field.name}
                  value={item[field.name] || ""}
                  onChange={(e) =>
                    handleChange(index, field.name, e.target.value)
                  }
                  placeholder={field.placeholder}
                >
                  {Array.isArray(field.option)
                    ? field.option.map((option, index) => (
                        <option key={index} value={option.id}>
                          {option.name}
                        </option>
                      ))
                    : Object.entries(field.option || {}).map(([key, value]) => (
                        <option key={key} value={key}>
                          {value}
                        </option>
                      ))}
                </Select>
              ) : field.type === "object" && field.schema ? (
                <Flex direction="row" wrap="wrap" gap={4}>
                  {Object.keys(field.schema).map((subFieldName) => (
                    <Box key={subFieldName} mb={2} flex="1">
                      <FormLabel htmlFor={`${field.name}.${subFieldName}`}>
                        {field.schema[subFieldName].label}
                        {field.schema[subFieldName].isRequired && (
                          <Box as="span" color="red.500" ml={1}>
                            *
                          </Box>
                        )}
                      </FormLabel>
                      {field.schema[subFieldName].type === "select" ? (
                        <Select
                          name={`${field.name}.${subFieldName}`}
                          value={
                            item[field.name]
                              ? item[field.name][subFieldName]
                              : ""
                          }
                          onChange={(e) =>
                            handleChange(
                              index,
                              `${field.name}.${subFieldName}`,
                              e.target.value
                            )
                          }
                          placeholder={field.schema[subFieldName].placeholder}
                        >
                          {Object.entries(
                            field.schema[subFieldName].option
                          ).map(([key, value]) => (
                            <option key={key} value={key}>
                              {value}
                            </option>
                          ))}
                        </Select>
                      ) : (
                        <Input
                          name={`${field.name}.${subFieldName}`}
                          placeholder={field.schema[subFieldName].placeholder}
                          type={field.schema[subFieldName].type || "text"}
                          value={
                            item[field.name]
                              ? item[field.name][subFieldName]
                              : ""
                          }
                          onChange={(e) => {
                            const value = e.target.value;
                            /* convert value to number if valueInNumber is true */
                            const finalValue = field.schema[subFieldName]
                              .valueInNumber
                              ? Number(value)
                              : value;
                            handleChange(
                              index,
                              `${field.name}.${subFieldName}`,
                              finalValue
                            );
                          }}
                          isRequired={
                            field.schema[subFieldName].isRequired || false
                          }
                        />
                      )}
                    </Box>
                  ))}
                </Flex>
              ) : field.type === "image" ? (
                <Image
                  onChange={(e) =>
                    handleChange(index, field.name, e.target.value)
                  }
                  name={field.name}
                  value={Array.isArray(item[field.name]) ? item[field.name] : item[field.name] && item[field.name].trim() !== '' ? [item[field.name]] : undefined}
                  label={field.label}
                  maxLenght={field.maxLenght}
                />
              ) : (
                <Input
                  name={field.name}
                  placeholder={field.placeholder}
                  type={field.type || "text"}
                  value={item[field.name] || ""}
                  onChange={(e) => {
                    const value = e.target.value;
                    /* convert value to number if valueInNumber is true */
                    const finalValue = field.valueInNumber
                      ? Number(value)
                      : value;
                    handleChange(index, field.name, finalValue);
                  }}
                  isRequired={field.isRequired || false}
                />
              )}
            </Box>
          ))}
          {showDeleteIcon && items.length > 1 && (
            <IconButton
              icon={<DeleteIcon />}
              colorScheme="red"
              aria-label="Delete Item"
              onClick={() => handleRemove(index)}
              size="xs"
              isDisabled={items.length === 1}
            />
          )}
        </Flex>
      ))}
      {showAddIcon && (
        <Flex justify="space-between" w="full">
          <IconButton
            icon={<AddIcon />}
            colorScheme="blue"
            aria-label="Add Item"
            onClick={handleAdd}
            size="xs"
          />
        </Flex>
      )}
    </VStack>
  );
};

export default DynamicInputField;
